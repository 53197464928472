const French = {
  __BUTTON_TEXT__: "TOUS LES TEXTES DES BOUTONS",
  back_to_Login: "Retour connexion",
  register: "S'INSCRIRE",
  privacy: "Intimité",
  confirm: "CONFIRMER",
  login: "CONNEXION",
  resetPassword: "Réinitialiser le mot de passe",
  enter_new_password: "Entrez un nouveau mot de passe",
  confirm_your_password: "Confirmez votre mot de passe",
  passwords_do_not_match: "Les mots de passe ne correspondent pas",
  forgot_password: "Mot de passe oublié",
  submit: "NOUS FAIRE PARVENIR",
  submitSmall: "Soumettre",
  setup: "METTRE EN PLACE",
  skip: "SAUTER",
  connect: "Relier",
  disconnect: "Déconnecter",
  forget: "Oublier",
  continue: "Continuez",
  edit_profile: "Editer le profil",
  resetAll: "Effacer tout",
  begin: "COMMENCER",
  close: "PROCHE",
  deviceSetup: "Configuration de l'appareil",
  __TEXT__: "TOUT LE TEXTE",
  create_Account: "Créer un compte",
  by_clicking_confirm_you_are_confirming_that_you_are_currently_21_years_of_age_or_older:
    'En cliquant sur "CONFIRMER". Vous confirmez que vous avez actuellement 21 ans ou plus',
  welcome_to: "Bienvenue à ",
  daVinci: "Da Vinci",
  daVinci_welcome_info_msg:
    "DaVinci enrichit votre expérience de vaporisation à feuilles mobiles grâce à une conception réfléchie, une ingénierie méticuleuse et une technologie innovante",
  daVinci_welcome_functionality_msg:
    "Cette application vous permet de vous connecter à votre IQ ou IQ2 via votre appareil mobile et de personnaliser les paramètres de votre vaporisateur",
  daVinci_welcome_phone_icon_msg:
    "Allumez et contrôlez votre appareil DaVinci depuis n'importe quel écran",
  daVinci_welcome_leaf_icon_msg:
    "Visitez la section aromathérapie pour en savoir plus sur les différents bienfaits des herbes et les températures idéales pour chacune",
  daVinci_welcome_graph_icon_msg:
    "Utilisez la technologie Smart Path pour personnaliser différentes températures de variations et de saveurs uniques",
  daVinci_welcome_shop_icon_msg:
    "Achetez tous les produits et accessoires DaVinci ici",
  welcome_msg: "Merci d'utiliser l'application en ligne DaVinci",
  pairing_msg:
    "Si vous avez votre appareil DaVinci avec vous, cliquez ci-dessous pour démarrer le processus de couplage",
  name_your_device: "Nommez votre appareil",
  setup_device: "APPAREIL DE CONFIGURATION",
  rename_device: "Renommer l'appareil",
  edit_name: "Modifier le nom",
  home: "DOMICILE",
  dashboard: "TABLEAU DE BORD",
  setting: "RÉGLAGES",
  my_account: "MON COMPTE",
  my_device: "MES APPAREILS",
  smart_paths: "CHEMINS INTELLIGENTS",
  smart_path: "Chemin intelligent",
  info_support: "INFORMATIONS & SOUTIEN",
  davinci_study: "L'étude de Davinci",
  shop: "MAGASIN",
  logout: "SE DÉCONNECTER",
  welcome: "Accueillir",
  everything_else: "TOUT LE RESTE",
  accessories_for_all_devices: "ACCESSOIRES POUR TOUS LES APPAREILS",
  upload_profile_picture: "Télécharger une photo de profil",
  change_password: "Changer le mot de passe",
  no_device: "Aucun dispositif",
  no_devices_found: "AUCUN PÉRIPHÉRIQUE TROUVÉ",
  no_devices_found_short: "Aucun périphérique trouvé",
  no_devices_found_msg:
    "Associez un appareil DaVinci pour commencer à personnaliser votre expérience",
  model: "Modèle",
  name: "Nom",
  address: "Adresse",
  serial_number: "Numéro de série",
  firmware_version: "Version du firmware",
  total_usage: "Total Usage",
  average_session: "Session moyenne",
  average_temperature: "Température moyenne",
  favorite_path: "Chemin favori",
  total_THC_consumed: "THC total consommé",
  total_CBD_consumed: "CBD total consommé",
  total_draws: "Total des tirages",
  disconnected: "Débranché",
  connected: "Lié",
  check_for_firmware_update: "VÉRIFIER LA MISE À JOUR DU FIRMWARE",
  end: "Fin",
  session_completed: "Séance terminée",
  time_expired: "Le temps est écoulé",
  strain: "Souche",
  start_time: "Heure de début",
  end_time: "Heure de fin",
  THC_consumed: "THC consommé",
  CBD_consumed: "CBD consommé",
  rate_your_session: "Évaluez votre session",
  would_you_like_to_save_your_session:
    "Souhaitez-vous enregistrer votre session ?",
  yes: "oui",
  no: "non",
  Yes: "Oui",
  No: "Non",
  star: "Étoiles",
  stop_time: "Temps d'arrêt",
  avg_temp: "Moy. Temp",
  strainType: "Type de souche",
  ovenContent: "Contenu du four",
  rating: "Évaluation",
  amountLoaded: "Montant chargé",
  min: "min",
  noSmartPath: "Pas de chemin intelligent",
  extract: "Extrait",
  grainOfRice: "Grain de riz",
  dryHerb: "Herbe sèche",
  full: "Plein",
  daVinci_dosage_pod_or_large_spacer_inserted:
    "DaVinci Dosage Pod ou grande entretoise insérée",
  or_small_daVinci_spacer_inserted: "ou petite entretoise DaVinci insérée",
  emptyFields: "Champs vides",
  your_have_blank_fields_here_this_will_prevent_your_IQ2_from_tracking_and_displaying_your_session_information_accurately:
    "Vous avez des champs vides ici, cela empêchera votre IQ2 de suivre et d'afficher avec précision les informations de votre session",
  would_you_like_to_proceed_anyways: "Souhaitez-vous continuer quand même ?",
  click_on_the_plus_icon_to_begin_the_paring_process:
    "Cliquez sur l'icône plus pour commencer le processus d'appariement",
  __PLACEHOLDER__: "TOUS PLACEHOLDER",
  email: "E-mail",
  email_address: "Adresse e-mail",
  first_name: "Prénom",
  last_name: "Nom de famille",
  save: "ENREGISTRER",
  password: "Mot de passe",
  new_password: "nouveau mot de passe",
  confirm_password: "Confirmez le mot de passe",
  device_name: "Nom de l'appareil",
  shop_now: "ACHETEZ MAINTENANT",
  davinci_study_msg:
    "En savoir plus sur tout ce qui concerne la vaporisation et le cannabis. Vous voulez savoir comment le contrôle de la température, l'effet entourage ou les terpènes affectent votre séance ? Plongez dans l'étude lors de votre prochaine session et développez votre esprit.",
  learn_more: "Apprendre encore plus",
  home_slider_text: "VAPORISATEUR DAVINCI IQ2",
  lost_connection: "Connexion perdue",
  daVinci_vaporizer_has_lost_the_connection_to_the_device:
    "DaVinci Vaporizer a perdu la connexion à l'appareil.",
  device_state: "État de l'appareil",
  n_a: "N / A",
  current_temperature: "Température actuelle",
  target_temperature: "Température cible",
  select_smart_path: "SÉLECTIONNER LE CHEMIN INTELLIGENT",
  session: "Session",
  history: "Histoire",
  time: "Temps",
  temperature: "Température",
  no_data_available: "Pas de données disponibles",
  no_smart_path: "Pas de chemin intelligent",
  vibration_alert: "ALERTES VIBRANTES",
  stealth_mode: "MODE FURTIF",
  temperature_unit: "UNITÉ DE TEMPÉRATURE",
  off: "à l'arrêt",
  on: "sur",
  target: "CIBLE",
  temp: "TEMP",
  ovenSize: "Taille du four",
  timeRemaining: "Temps restant",
  targetCBD: "CBD cible",
  consumedCBD: "CBD consommé",
  targetTHC: "THC cible",
  consumedTHC: "THC consommé",
  sessionSetup: "Configuration de session",
  please_enter_your_session_information:
    "Veuillez entrer vos informations de session",
  strainName: "Nom de la souche",
  THC: "% de THC",
  CBD: "CBD%",
  select: "Sélectionner",
  newBowl: "Nouveau bol",
  secondUse: "2ème utilisation",
  thirdUse: "3ème utilisation",
  indica: "indique",
  sativa: "sativa",
  indicaDominantHybrid: "Hybride à dominante Indica",
  sativaDominantHybrid: "Hybride à dominance Sativa",
  "Smart Path 1": "CHEMIN INTELLIGENT 1",
  "Smart Path 2": "CHEMIN INTELLIGENT 2",
  "Smart Path 3": "CHEMIN INTELLIGENT 3",
  "Smart Path 4": "CHEMIN INTELLIGENT 4",

  __TOAST_MESSAGES__: "TOAST_MESSAGES",
  form_fields_are_required: "Les champs du formulaire sont obligatoires",
  login_success: "Connexion réussie",
  enter_login_email_to_recover_your_password:
    "Entrez l'email de connexion pour récupérer votre mot de passe",
  success: "Succès",
  email_is_required: "L'e-mail est requis",
  device_name_is_required: "Le nom de l'appareil est requis",
  device_connected: "Appareil connecté",
  device_can_not_connected: "L'appareil ne peut pas être connecté",
  please_enter_a_password: "Veuillez entrer un mot de passe",
  successfully_reset_changes: "Réinitialisation des modifications réussie",
  new_password_and_confirm_password_do_not_match:
    "Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas",
  password_Changed_Successfully: "Le mot de passe a été changé avec succès",
  profileUpdateSuccessfully: "Mise à jour du profil réussie",
  successfully_saved_changes: "Modifications enregistrées avec succès",
  successfully_reset_smartpath: "smartpath a bien été réinitialisé",
  device_is_not_connected: "L'appareil n'est pas connecté",
  device_not_connected: "Appareil non connecté",
  successfullyForgetDevice: "Oublier avec succès l'appareil",
  successfullyEditDeviceName: "Modifier avec succès le nom de l'appareil",
  device_disconnected: "Appareil déconnecté",
  signupSuccess: "Succès de l'inscription",

  NewBowl: "NouveauBol",
  Second_Use: "Second_Use",
  Third_Use: "Troisième_utilisation",
  UNKNOWN: "INCONNUE",
  INDICA: "INDIQUE",
  SATIVA: "SATIVA",
  INDICA_HYBRID: "INDICA_HYBRID",
  SATIVA_HYBRID: "SATIVA_HYBRID",
  Off: "À l'arrêt",
  On: "Sur",
  Charging: "Mise en charge",
  "SmartPath Mode": "Mode SmartPath",
  "Precise Mode": "Mode précis",
  "Selecting SmartPath": "Sélection de SmartPath",
  "Temperature Show SP": "Affichage de la température SP",
  "Boost Mode": "Mode Turbo",
  "StandBy Mode": "Mode veille",
  noDeviceSelected: "Aucun appareil sélectionné",
  createNew: "Créer un nouveau",
  region: "Région",
  device: "Dispositif",
  currentFirmware: "Micrologiciel actuel",
  availableFirmware: "Micrologiciel disponible",
  check: "Vérifier",
  enterNewStrain: "Entrez une nouvelle souche",
  date: "Date",
};

export default French;
