const DEVICE_STATE_LABEL = {
  0: "Off",
  1: "Charging",
  2: "On",
  3: "SmartPath Mode",
  6: "Precise Mode",
  4: "Selecting SmartPath",
  5: "Temperature Show SP",
  7: "Boost Mode",
  8: "StandBy Mode",
};

export default DEVICE_STATE_LABEL;
