import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import SetupSlider2 from "../../components/SetupSlider2/SetupSlider2";
import "./SetupScreen2.scss";
class SetupScreen2 extends Component {
  state = {};
  render() {
    const { isAuthenticated } = this.props;

    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="banner-primary setup-screen">
        <h2 className="primary-heading">Setup</h2>
        <p>Getting Started</p>
        <SetupSlider2 />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.userAuth.isAuthenticated,
});

export default connect(mapStateToProps)(SetupScreen2);
