const DEVICE_STATE = {
  OFF: 0,
  CHARGE: 1,
  ON: 2,
  SMART_PATH: 3,
  SELECTED_SMART_PATH: 4,
  TEMP_SHOW_SMART_PATH: 5,
  PRECISION_TEMP: 6,
  BOOST_MODE: 7,
  STAND_BY_MODE: 8,
};

export default DEVICE_STATE;
