import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { encryptTransform } from "redux-persist-transform-encrypt";

import rootReducer from "./reducers";
import Config from "../Config";
import { SIGN_OUT } from "../store/actions";

const encryptor = encryptTransform({
  secretKey: Config.PERSIST_SECRET_KEY,
  onError: (error) => {},
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userAuth", "deviceId"],
  stateReconciler: autoMergeLevel2,
  transforms: [encryptor],
};

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleWares = [thunk];
const enhancer = composeEnhancers(applyMiddleware(...middleWares));

const store = createStore(persistedReducer, enhancer);
let persistor = persistStore(store);

const listener = (e) => {
  if (
    store.getState().userAuth.isAuthenticated &&
    (!localStorage.hasOwnProperty("isAuthenticated") ||
      !localStorage.isAuthenticated)
  ) {
    store.dispatch({
      type: SIGN_OUT,
    });
    let ageConfirmation = localStorage.ageConfirmation;
    let sessionData = localStorage.sessionData;
    localStorage.clear();
    if (ageConfirmation) {
      localStorage.ageConfirmation = ageConfirmation;
    }
    if (sessionData) {
      localStorage.sessionData = sessionData;
    }
  }
};

window.addEventListener("storage", listener);

export { store, persistor };
