import React from "react";
import "./Header.scss";
export default (Component) => {
  class Header extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <div className="main-content banner-primary">
          <Component {...this.props} />
        </div>
      );
    }
  }
  return Header;
};
