const SESSION_OVEN_SIZE = {
  SELECT: 0,
  DRY_HERB_100_FULL: 8,
  DRY_HERB_80_FULL: 7,
  DRY_HERB_60_FULL: 6,
  DRY_HERB_40_FULL: 5,
  EXTRACT_100: 4,
  EXTRACT_75: 3,
  EXTRACT_50: 2,
  EXTRACT_25: 1,
};

export default SESSION_OVEN_SIZE;
