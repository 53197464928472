/* 
   define all your routes here and it will run automatically
 */

import React from 'react';
import { Route, BrowserRouter as Router, Switch, useParams } from 'react-router-dom';
import { Home, Login, SignUp, AgeVerification, Welcome, SetupScreen, SetupScreen2 } from '../screens/index'
import Dashboard from '../screens/Dashboard/Dashboard';
import UpdateFirmware from '../screens/UpdateFirmware/UpdateFirmware';
import ResetPassword from '../screens/ResetPassword';
import ForgotPasswordScreen from '../screens/ForgotPassword';



const index = () => {
    return (
        <Router basename={"/"}>
            <Switch>
                <Route exact path="/login" name="login" component={Login} />
                <Route exact path="/signup" name="signup" component={SignUp} />
                <Route exact path="/reset-password" name="reset-password" component={ResetPassword} />
                <Route exact path="/forgot-password" name="forgot-password" component={ForgotPasswordScreen} />
                <Route exact path="/welcome" name="welcome" component={Welcome} />
                <Route exact path="/setup" name="setup" component={SetupScreen} />
                <Route exact path="/check-firmware-update" name="update-firmware" component={UpdateFirmware} />
                <Route exact path="/setup-2" name="setup2" component={SetupScreen2} />
                <Route exact path="/index.html" name="age-verification" component={AgeVerification} />
                <Route exact path="/" name="age-verification" component={AgeVerification} />
                <Route path="/" name="dashboard" component={Dashboard} />

            </Switch>
        </Router>
    );
}

export default index;