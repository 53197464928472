import { debounce, throttle } from "lodash";
import { toast } from "react-toastify";
import { BatteryLevel } from "../constants";
import { store } from "../store";
import {
  CLEAR_DEVICE_STATE,
  DEVICE_CONNECTED,
  DEVICE_DISCONNECTED,
  SET_CURRENT_DEVICE_STATISTIC,
  SET_CURRENT_STATE_DEVICE,
  SET_DEVICE_INFORMARION,
  SET_INITIAL_SMART_PATH_DATA,
  SET_SESSION_DATA,
  SET_SMARTH_PATH_TITLE,
} from "../store/actions";
import { ConvertToHexa, ConvertToTwoDecimalPlaces } from "./helpers";
// var PangCGattKnownUUID = {
//     DEVICE_INFORMATION_SERVICE: "0000180a-0000-1000-8000-00805f9b34fb",
//     MANUFACTURER_NAME_CHAR: "00002a29-0000-1000-8000-00805f9b34fb",
//     MODEL_NUMBER_CHAR: "00002a24-0000-1000-8000-00805f9b34fb",
//     SERIAL_NUMBER_CHAR: "00002a24-0000-1000-8000-00805f9b34fb",
//     FIRMWARE_REVISION_CHAR: "00002a26-0000-1000-8000-00805f9b34fb",
//     SOFTWARE_REVISION_CHAR: "00002a28-0000-1000-8000-00805f9b34fb",
//     SYSTEM_ID_CHAR: "00002a23-0000-1000-8000-00805f9b34fb",
//     GENERIC_ACCESS_SERVICE: "00001800-0000-1000-8000-00805f9b34fb",
//     DEVICE_NAME_CHAR: "00002a00-0000-1000-8000-00805f9b34fb",
//     PANG_C_SERVICE: "edfec62e-9900-b00b-1e50-005041934743",
//     CTRL_POINT_CHAR: "01000000-0000-0000-0000-0050414e4743",
//     CURRENT_STATE_CHAR: "02000000-0000-0000-0000-0050414e4743",
//     SMART_PATHS_CHAR: "09000000-0000-0000-0000-0050414e4743",
//     UPDATE_FW_CHAR: "19890902-0000-0000-0000-0050414e4743",
//     STATISTICS_CHAR: "0d000000-0000-0000-0000-0050414e4743",
//     SESSION_DATA_CHAR: "05000000-0000-0000-0000-0050414e4743"
// }

// var isknwonUUID = function (uuid) {
//     for (var prop in PangCGattKnownUUID) {
//         if (PangCGattKnownUUID.hasOwnProperty(prop)) {
//             if (PangCGattKnownUUID[prop] === uuid)
//                 return true;
//         }
//     }
//     return false;
// }

// var WritableCharacteristics = {
//     "01000000-0000-0000-0000-0050414e4743": ServiceConstants.CTRL_POINT_CHAR,
//     "05000000-0000-0000-0000-0050414e4743": ServiceConstants.SESSION_DATA_CHAR,
//     "09000000-0000-0000-0000-0050414e4743": ServiceConstants.SMART_PATHS_CHAR,
//     "19890902-0000-0000-0000-0050414e4743": ServiceConstants.UPDATE_FW_CHAR
// }

// var ReadableCharacteristics = {
//     "02000000-0000-0000-0000-0050414e4743": ServiceConstants.CURRENT_STATE_CHAR,
//     "05000000-0000-0000-0000-0050414e4743": ServiceConstants.SESSION_DATA_CHAR,
//     "06000000-0000-0000-0000-0050414e4743": "UNKNOWN",
//     "07000000-0000-0000-0000-0050414e4743": "UNKNOWN",
//     "09000000-0000-0000-0000-0050414e4743": ServiceConstants.SMART_PATHS_CHAR,
//     "0d000000-0000-0000-0000-0050414e4743": ServiceConstants.STATISTICS_CHAR,
//     "19890902-0000-0000-0000-0050414e4743": ServiceConstants.UPDATE_FW_CHAR,
//     "00002a23-0000-1000-8000-00805f9b34fb": ServiceConstants.SYSTEM_ID_CHAR,
//     "00002a24-0000-1000-8000-00805f9b34fb": ServiceConstants.MODEL_NUMBER_CHAR,
//     "00002a26-0000-1000-8000-00805f9b34fb": ServiceConstants.SOFTWARE_REVISION_CHAR,
//     "00002a28-0000-1000-8000-00805f9b34fb": ServiceConstants.FIRMWARE_REVISION_CHAR,
//     "00002a29-0000-1000-8000-00805f9b34fb": ServiceConstants.MANUFACTURER_NAME_CHAR,
//     "00002a50-0000-1000-8000-00805f9b34fb": "UNKNOWN",
//     "00002a05-0000-1000-8000-00805f9b34fb": "UNKNOWN",
//     "00002a00-0000-1000-8000-00805f9b34fb": ServiceConstants.DEVICE_NAME_CHAR,
//     "00002a01-0000-1000-8000-00805f9b34fb": "UNKNOWN"
// }

// var NotifableCharacteristics = {
//     "02000000-0000-0000-0000-0050414e4743": ServiceConstants.CURRENT_STATE_CHAR,
//     "05000000-0000-0000-0000-0050414e4743": ServiceConstants.SESSION_DATA_CHAR,
//     "06000000-0000-0000-0000-0050414e4743": "UNKNOWN",
//     "07000000-0000-0000-0000-0050414e4743": "UNKNOWN",
// }

// var IndicatableCharacteristics = {
//     "00002a05-0000-1000-8000-00805f9b34fb": "UNKNOWN"
// }

var ServiceConstants = {
  DEVICE_INFORMATION_SERVICE: 1,
  MANUFACTURER_NAME_CHAR: 2,
  MODEL_NUMBER_CHAR: 3,
  SERIAL_NUMBER_CHAR: 4,
  SOFTWARE_REVISION_CHAR: 5,
  FIRMWARE_REVISION_CHAR: 6,
  SYSTEM_ID_CHAR: 7,
  GENERIC_ACCESS_SERVICE: 8,
  DEVICE_NAME_CHAR: 9,
  PANG_C_SERVICE: 10,
  CTRL_POINT_CHAR: 11,
  CURRENT_STATE_CHAR: 12,
  SMART_PATHS_CHAR: 13,
  UPDATE_FW_CHAR: 14,
  STATISTICS_CHAR: 15,
  SESSION_DATA_CHAR: 16,
  CLIENT_CHARACTERISTIC_CONFIG: 17,
};
var ServiceLabelConstants = {
  [ServiceConstants.DEVICE_INFORMATION_SERVICE]: "Device Information Service",
  [ServiceConstants.MANUFACTURER_NAME_CHAR]: "Manufacturer Name Characteristic",
  [ServiceConstants.MODEL_NUMBER_CHAR]: "Model Number Characteristic",
  [ServiceConstants.SERIAL_NUMBER_CHAR]: "Serial Number Characteristic",
  [ServiceConstants.SOFTWARE_REVISION_CHAR]: "Firmware Revision Characteristic",
  [ServiceConstants.FIRMWARE_REVISION_CHAR]: "Software Revision Characteristic",
  [ServiceConstants.SYSTEM_ID_CHAR]: "System ID Characteristic",
  [ServiceConstants.GENERIC_ACCESS_SERVICE]: "Generic Access Service",
  [ServiceConstants.DEVICE_NAME_CHAR]: "Device Name Characteristic",
  [ServiceConstants.PANG_C_SERVICE]: "Pang C Service",
  [ServiceConstants.CTRL_POINT_CHAR]: "Control Point Characteristic",
  [ServiceConstants.CURRENT_STATE_CHAR]: "Current State Characteristic",
  [ServiceConstants.SMART_PATHS_CHAR]: "Smart Paths Characteristic",
  [ServiceConstants.STATISTICS_CHAR]: "Statistics Characteristic",
  [ServiceConstants.UPDATE_FW_CHAR]: "Firmware Update Characteristic",
  [ServiceConstants.SESSION_DATA_CHAR]: "Session Data Characteristic",
  [ServiceConstants.CLIENT_CHARACTERISTIC_CONFIG]:
    "Client Characteristic Service",
};
var Constants = {
  [ServiceConstants.DEVICE_INFORMATION_SERVICE]:
    "0000180a-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.MANUFACTURER_NAME_CHAR]:
    "00002a29-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.MODEL_NUMBER_CHAR]: "00002a24-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.SERIAL_NUMBER_CHAR]: "00002a24-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.SOFTWARE_REVISION_CHAR]:
    "00002a26-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.FIRMWARE_REVISION_CHAR]:
    "00002a28-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.SYSTEM_ID_CHAR]: "00002a23-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.GENERIC_ACCESS_SERVICE]:
    "00001800-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.DEVICE_NAME_CHAR]: "00002a00-0000-1000-8000-00805f9b34fb",
  [ServiceConstants.PANG_C_SERVICE]: "edfec62e-9900-b00b-1e50-005041934743",
  [ServiceConstants.CTRL_POINT_CHAR]: "01000000-0000-0000-0000-0050414e4743",
  [ServiceConstants.CURRENT_STATE_CHAR]: "02000000-0000-0000-0000-0050414e4743",
  [ServiceConstants.SMART_PATHS_CHAR]: "09000000-0000-0000-0000-0050414e4743",
  [ServiceConstants.UPDATE_FW_CHAR]: "19890902-0000-0000-0000-0050414e4743",
  [ServiceConstants.STATISTICS_CHAR]: "0d000000-0000-0000-0000-0050414e4743",
  [ServiceConstants.SESSION_DATA_CHAR]: "05000000-0000-0000-0000-0050414e4743",
  [ServiceConstants.CLIENT_CHARACTERISTIC_CONFIG]:
    "00002902-0000-1000-8000-00805f9b34fb",
};

var DeviceStateConstants = {
  OFF: 0,
  CHARGE: 1,
  ON: 2,
  SMART_PATH: 3,
  SELECTED_SMART_PATH: 4,
  TEMP_SHOW_SMART_PATH: 5,
  PRECISION_TEMP: 6,
  BOOST_MODE: 7,
  STAND_BY_MODE: 8,
};

var DeviceStateConstantLabel = {
  [DeviceStateConstants.OFF]: 0,
  [DeviceStateConstants.CHARGE]: 1,
  [DeviceStateConstants.ON]: 2,
  [DeviceStateConstants.SMART_PATH]: 3,
  [DeviceStateConstants.SELECTED_SMART_PATH]: 4,
  [DeviceStateConstants.TEMP_SHOW_SMART_PATH]: 5,
  [DeviceStateConstants.PRECISION_TEMP]: 6,
  [DeviceStateConstants.BOOST_MODE]: 7,
  [DeviceStateConstants.STAND_BY_MODE]: 8,
};

var SessionDataConstants = {
  PROPERTY_SESSION_TYPE: 0,
  PROPERTY_SESSION_THC_AMT: 1,
  PROPERTY_SESSION_CBD_AMT: 2,
  PROPERTY_SESSION_OVEN_SIZE: 3,
  PROPERTY_SESSION_PUFF_COUNT: 4,
  PROPERTY_LAST_PUFF_DURATION: 5,
  PROPERTY_LAST_PUFF_START_TIME: 6,
  PROPERTY_LAST_PUFF_CBD: 7,
  PROPERTY_SESSION_TOT_THC: 8,
  PROPERTY_SESSION_TOT_CBD: 9,
};

var SessionDataConstantLabel = {
  PROPERTY_SESSION_TYPE: 0,
  PROPERTY_SESSION_THC_AMT: 1,
  PROPERTY_SESSION_CBD_AMT: 2,
  PROPERTY_SESSION_OVEN_SIZE: 3,
  PROPERTY_SESSION_PUFF_COUNT: 4,
  PROPERTY_LAST_PUFF_DURATION: 5,
  PROPERTY_LAST_PUFF_START_TIME: 6,
  PROPERTY_LAST_PUFF_CBD: 7,
  PROPERTY_SESSION_TOT_THC: 8,
  PROPERTY_SESSION_TOT_CBD: 9,
};

function getCurrenState(stateDataView) {
  var state = DeviceStateConstantLabel[stateDataView.getUint8(0)];
  var currentTemp =
    stateDataView.getUint8(1) | (stateDataView.getUint8(2) << 8);
  var targetTemp = stateDataView.getUint8(3) | (stateDataView.getUint8(4) << 8);

  var time =
    (stateDataView.getUint8(11) << 24) |
    (stateDataView.getUint8(10) << 16) |
    (stateDataView.getUint8(9) << 8) |
    stateDataView.getUint8(8);

  var smartPathIndex = stateDataView.getUint8(7);
  var temperatureFlag = stateDataView.getUint8(13);
  var batteryLevel = stateDataView.getUint8(12);
  var displayModeCelcius = (stateDataView.getUint8(13) & 0x02) == 0x02;
  var isCharging = (stateDataView.getUint8(13) & 0x08) == 0x08;
  var isVibrationEnabled = (stateDataView.getUint8(13) & 0x10) == 0x10;
  var isStealthModelEnabled = (stateDataView.getUint8(13) & 0x20) == 0x20;
  var isTargetTempReached = (stateDataView.getUint8(13) & 0x04) == 0x04;
  var batteryPercentage = BatteryLevel.NONE;
  switch (batteryLevel) {
    case 0:
      batteryPercentage = BatteryLevel.NONE;
      break;
    case 1:
      batteryPercentage = BatteryLevel.TWENTY_PERCENT;
      break;
    case 2:
      batteryPercentage = BatteryLevel.FORTY_PERCENT;
      break;
    case 3:
      batteryPercentage = BatteryLevel.SIXTY_PERCENT;
      break;
    case 4:
      batteryPercentage = BatteryLevel.EIGHTY_PERCENT;
      break;
    case 5:
      batteryPercentage = BatteryLevel.FULLY_CHARGED;
      break;
    default:
      batteryPercentage = BatteryLevel.UNKNOWN;
  }
  return {
    state,
    currentTemp,
    targetTemp,
    time,
    smartPathIndex,
    temperatureFlag,
    displayModeCelcius,
    isCharging,
    isVibrationEnabled,
    isStealthModelEnabled,
    isTargetTempReached,
    batteryPercentage,
  };
}

function getSessionStatistics(sessionStat) {
  var sessionInProgress = sessionStat.getUint8(15) << 8;
  var sessionType = sessionStat.getUint8(0);
  var sessionTHC_AMT = sessionStat.getUint8(1);
  var sessionCBD_AMT = sessionStat.getUint8(2);
  var sessionOVEN_SIZE = sessionStat.getUint8(3);
  var puffSample =
    (sessionStat.getUint8(5) << 8) | (sessionStat.getUint8(4) << 8);
  var sessionTotalPuffCount = sessionStat.getUint8(16);
  if (sessionTotalPuffCount > 0) {
    var newPuffDuration = sessionStat.getUint8(6) / 10 + 1;
    var lastPuffThcConsumed = ConvertToTwoDecimalPlaces(
      ((sessionStat.getUint8(8) << 8) | sessionStat.getUint8(7)) / 10
    );
    var lastPuffCBdConsumed = ConvertToTwoDecimalPlaces(
      ((sessionStat.getUint8(10) << 8) | sessionStat.getUint8(9)) / 10
    );
    var lastPuffTotalThcConsumed = ConvertToTwoDecimalPlaces(
      ((sessionStat.getUint8(12) << 8) | sessionStat.getUint8(11)) / 10
    );
    var lastPuffTotalCBdConsumed = ConvertToTwoDecimalPlaces(
      ((sessionStat.getUint8(14) << 8) | sessionStat.getUint8(13)) / 10
    );
  }

  return {
    sessionType,
    sessionCBD_AMT,
    sessionInProgress,
    sessionOVEN_SIZE,
    sessionTHC_AMT,
    sessionTotalPuffCount,
    puffSample,
    newPuffDuration,
    lastPuffCBdConsumed,
    lastPuffThcConsumed,
    lastPuffTotalCBdConsumed,
    lastPuffTotalThcConsumed,
  };
}

function getSmartPathData(smartPathData) {
  let smartPathList = [];
  let byteCounter = 0;
  for (let i = 0; i < 4; i++) {
    let metaData = smartPathData.getInt16(0 + byteCounter, true);
    let duration = smartPathData.getInt16(2 + byteCounter, true);
    let points = [];
    for (let j = 4; j < 24; j = j + 2) {
      let time = smartPathData.getUint8(j + byteCounter);
      let temp = smartPathData.getUint8(j + 1 + byteCounter);
      points.push({
        temp,
        time,
      });
    }
    let numberOfPoints = smartPathData.getUint8(24 + byteCounter);
    smartPathList.push({ metaData, duration, points, numberOfPoints });
    byteCounter = byteCounter + 26;
  }
  return smartPathList;
}

function getDeviceStatistics(deviceStat) {
  var totalTime =
    deviceStat.getUint8(0) |
    (deviceStat.getUint8(1) << 8) |
    (deviceStat.getUint8(2) << 16) |
    (deviceStat.getUint8(3) << 24);

  totalTime = (totalTime || 0) / 3600;
  var sessionTimeAccumulated =
    deviceStat.getUint8(4) | (deviceStat.getUint8(5) << 8);
  var startSessionTimeCount = deviceStat.getUint8(6);
  var averageSession = 0;
  if (startSessionTimeCount !== 0) {
    averageSession = sessionTimeAccumulated / startSessionTimeCount;
  }
  averageSession = (averageSession || 0) / 60;
  var startPrecisionTemperature =
    deviceStat.getUint8(7) | (deviceStat.getUint8(8) << 8);
  var precisionTemperatureCount = deviceStat.getUint8(9);
  var averageTemp = 0;
  if (precisionTemperatureCount !== 0) {
    averageTemp = startPrecisionTemperature / precisionTemperatureCount;
  }
  var maxCount = 0;
  var favoritePath = 1;

  var favoritePath1Count =
    deviceStat.getUint8(10) | (deviceStat.getUint8(11) << 8);
  if (favoritePath1Count > maxCount) {
    maxCount = favoritePath1Count;
    favoritePath = 1;
  }
  var favoritePath2Count =
    deviceStat.getUint8(12) | (deviceStat.getUint8(13) << 8);
  if (favoritePath2Count > maxCount) {
    maxCount = favoritePath2Count;
    favoritePath = 2;
  }
  var favoritePath3Count =
    deviceStat.getUint8(14) | (deviceStat.getUint8(15) << 8);
  if (favoritePath3Count > maxCount) {
    maxCount = favoritePath3Count;
    favoritePath = 3;
  }
  var favoritePath4Count =
    deviceStat.getUint8(16) | (deviceStat.getUint8(17) << 8);
  if (favoritePath4Count > maxCount) {
    maxCount = favoritePath4Count;
    favoritePath = 4;
  }

  var totalPuffCountHistory =
    deviceStat.getUint8(18) | (deviceStat.getUint8(19) << 8);

  return {
    totalTime: ConvertToTwoDecimalPlaces(totalTime),
    totalPuffCountHistory,
    averageTemp: ConvertToTwoDecimalPlaces(averageTemp),
    averageSession: ConvertToTwoDecimalPlaces(averageSession),
    favoritePath,
    sessionTimeAccumulated,
    startPrecisionTemperature,
    startSessionTimeCount,
    precisionTemperatureCount,
    favoritePath1Count,
    favoritePath2Count,
    favoritePath3Count,
    favoritePath4Count,
  };
}

function getServiceConstant(uuid) {
  for (var key in Object.keys(Constants)) {
    if (uuid == Constants[key]) {
      return key;
    }
  }
  return null;
}
function getLabel(uuid) {
  var key = getServiceConstant(uuid);
  if (key) {
    return ServiceLabelConstants[key];
  }
  return null;
}

function dtViewToStr(dataView) {
  var str = "";
  for (var i = 0; i < dataView.buffer.byteLength; i++) {
    str += String.fromCharCode(dataView.getUint8(i));
  }
  return str;
}

export async function ToggleVibrationAlert(value) {
  try {
    if (await DeviceHelper.getControlPointCharacteristic()) {
      let characteristic = await DeviceHelper.getControlPointCharacteristic();
      await characteristic.writeValue(new Int8Array([0x90, value]));
    }
  } catch (e) {
    // toast.error("Device is not connected");
  }
}

export async function ToggleStealthMode(value) {
  try {
    if (await DeviceHelper.getControlPointCharacteristic()) {
      let characteristic = await DeviceHelper.getControlPointCharacteristic();
      await characteristic.writeValue(new Int8Array([0x60, value]));
    }
  } catch (e) {
    // toast.error("Device is not connected");
  }
}

export async function StartSmartPathMode(value) {
  try {
    if (await DeviceHelper.getControlPointCharacteristic()) {
      let characteristic = await DeviceHelper.getControlPointCharacteristic();
      await SwitchState(0x02);
      await characteristic.writeValue(
        new Int8Array([0x80, ConvertToHexa(value)])
      );
    }
  } catch (e) {
    // toast.error("Device is not connected");
  }
}

export async function SetTemperatureUnit(value) {
  try {
    if (await DeviceHelper.getControlPointCharacteristic()) {
      let characteristic = await DeviceHelper.getControlPointCharacteristic();
      await characteristic.writeValue(new Int8Array([0xf0, value]));
    }
  } catch (e) {
    // toast.error("Device is not connected");
  }
}

export async function SetSession(value) {
  try {
    if (await DeviceHelper.getSessionDataCharacteristic()) {
      let characteristic = await DeviceHelper.getSessionDataCharacteristic();
      let response = await characteristic.writeValue(new Int8Array(value));
      return response;
    }
  } catch (e) {
    console.log(e);
    // toast.error("Device is not connected");
  }
}

export async function WriteSmartPaths(smartPathData) {
  try {
    if (await DeviceHelper.getSmartPathCharacteristic()) {
      let characteristic = await DeviceHelper.getSmartPathCharacteristic();
      let encodedData = DeviceHelper.encodeSmartPathData(smartPathData);
      let response = await characteristic.writeValue(encodedData);
      return response;
    }
  } catch (e) {
    // toast.error("Device is not connected");
  }
}

export async function SwitchState(value) {
  try {
    if (await DeviceHelper.getControlPointCharacteristic()) {
      let characteristic = await DeviceHelper.getControlPointCharacteristic();
      await characteristic.writeValue(new Int8Array([0x30, value]));
    }
  } catch (e) {
    // toast.error("Device is not connected");
  }
}

export async function WriteControlKeyPressed(command, value) {
  try {
    if (await DeviceHelper.getControlPointCharacteristic()) {
      let characteristic = await DeviceHelper.getControlPointCharacteristic();
      await characteristic.writeValue(new Int8Array([command, value]));
    }
  } catch (e) {
    // toast.error("Device is not connected");
  }
}

async function fetchDeviceInformationThroughCharacteristics(characteristic) {
  let value = await characteristic.readValue();
  return {
    uuid: characteristic.uuid,
    value: dtViewToStr(value),
  };
}

export class DeviceHelper {
  static deviceConnection = null;
  static gattInstance = null;
  static controlPointCharacteristic = false;
  static firmwareUpdateCharacteristic = false;
  static currentDeviceStateCharacteristic = false;
  static sessionDataCharacteristic = false;
  static smartPathCharacteristic = false;
  static deviceInformationService = false;
  static deviceStatisticsCharacteristic = false;
  static currentDataIntervalId = null;
  static sessionDataIntervalId = null;
  static smarthPathIntervalId = null;
  static firmwareUpdating = false;
  constructor() { }

  static async getInstance() {
    if (DeviceHelper.deviceConnection === null) {
      await new DeviceHelper().ConnectDevice();
    }
    return DeviceHelper.deviceConnection;
  }

  static async EstablishDeviceConnection() {
    await DeviceHelper.disconnect();
    await DeviceHelper.getInstance();
    await DeviceHelper.getCurrentDeviceState();
    await DeviceHelper.getSessionData();
    await DeviceHelper.getDeviceInformation();
    await DeviceHelper.getCurrentDeviceStatistics();
    await SwitchState(0x02);
    setTimeout(async () => {
      await DeviceHelper.getSmartPathList();
    }, 2000);
    store.dispatch({
      type: DEVICE_CONNECTED,
    });
  }

  static async updateFirmware(payload, statusCallback) {
    if (typeof statusCallback !== "function") {
      statusCallback = false;
    }
    if (DeviceHelper.firmwareUpdating) {
      throw "Already updating";
    }
    DeviceHelper.firmwareUpdating = true;
    if (DeviceHelper.smarthPathIntervalId) {
      clearInterval(DeviceHelper.smarthPathIntervalId);
    }

    if (DeviceHelper.sessionDataIntervalId) {
      clearInterval(DeviceHelper.sessionDataIntervalId);
    }

    if (DeviceHelper.currentDataIntervalId) {
      clearInterval(DeviceHelper.currentDataIntervalId);
    }

    await SwitchState(0x01);

    DeviceHelper.sessionDataCharacteristic &&
      (await DeviceHelper.sessionDataCharacteristic.stopNotifications());

    DeviceHelper.currentDeviceStateCharacteristic &&
      (await DeviceHelper.currentDeviceStateCharacteristic.stopNotifications());

    let lastSuccessPercent;
    try {
      for (let i = 0; i < payload.length; i++) {
        lastSuccessPercent = (i * 100) / payload.length;
        statusCallback && statusCallback("uploading", lastSuccessPercent);
        await (
          await DeviceHelper.getFirmwareUpdateCharacteristic()
        ).writeValue(payload[i]);
      }
      DeviceHelper.firmwareUpdating = false;
    } catch (e) {
      statusCallback && statusCallback("failed", lastSuccessPercent);
      DeviceHelper.firmwareUpdating = false;
      throw e;
    }
  }

  static async disconnect() {
    if (DeviceHelper.gattInstance !== null) {
      await DeviceHelper.gattInstance.disconnect();
      DeviceHelper.gattInstance = null;
      DeviceHelper.deviceConnection = null;
      DeviceHelper.controlPointCharacteristic = false;
      DeviceHelper.currentDeviceStateCharacteristic = false;
      DeviceHelper.sessionDataCharacteristic = false;
      DeviceHelper.smartPathCharacteristic = false;
      DeviceHelper.deviceInformationService = false;
      store.dispatch({
        type: CLEAR_DEVICE_STATE,
      });
    }
    return true;
  }

  static async getControlPointCharacteristic() {
    if (DeviceHelper.deviceConnection === null) {
      // await new DeviceHelper().ConnectDevice();
      toast.error("Device is not connected");
    }
    return DeviceHelper.controlPointCharacteristic;
  }

  static async getCurrentStateCharacteristic() {
    if (DeviceHelper.deviceConnection === null) {
      //   await new DeviceHelper().ConnectDevice();
      toast.error("Device is not connected");
    }
    return DeviceHelper.currentDeviceStateCharacteristic;
  }

  static async getFirmwareUpdateCharacteristic() {
    if (DeviceHelper.deviceConnection === null) {
      //   await new DeviceHelper().ConnectDevice();
      toast.error("Device is not connected");
    }
    return DeviceHelper.firmwareUpdateCharacteristic;
  }

  static async getSmartPathCharacteristic() {
    if (DeviceHelper.deviceConnection === null) {
      // await new DeviceHelper().ConnectDevice();
      toast.error("Device is not connected");
    }
    return DeviceHelper.smartPathCharacteristic;
  }

  static async getStatisticCharacteristic() {
    if (DeviceHelper.deviceConnection === null) {
      // await new DeviceHelper().ConnectDevice();
      toast.error("Device is not connected");
    }
    return DeviceHelper.deviceStatisticsCharacteristic;
  }

  static async getSessionDataCharacteristic() {
    if (DeviceHelper.deviceConnection === null) {
      //  await new DeviceHelper().ConnectDevice();
      toast.error("Device is not connected");
    }
    return DeviceHelper.sessionDataCharacteristic;
  }

  static async getDeviceInformationService() {
    if (DeviceHelper.deviceConnection === null) {
      toast.error("Device is not connected");
    }
    return DeviceHelper.deviceInformationService;
  }

  static async getCurrentDeviceState() {
    try {
      if (DeviceHelper.currentDeviceStateCharacteristic) {
        let value =
          await DeviceHelper.currentDeviceStateCharacteristic.readValue();
        let currentState = await getCurrenState(value);
        store.dispatch({
          type: SET_CURRENT_STATE_DEVICE,
          payload: currentState,
        });
        return currentState;
      }
    } catch (e) {
      console.log(e);
    }
  }

  static async getCurrentDeviceStatistics() {
    try {
      if (DeviceHelper.deviceStatisticsCharacteristic) {
        let value =
          await DeviceHelper.deviceStatisticsCharacteristic.readValue();
        let currentStatistics = await getDeviceStatistics(value);
        store.dispatch({
          type: SET_CURRENT_DEVICE_STATISTIC,
          payload: currentStatistics,
        });
        return currentStatistics;
      }
    } catch (e) {
      console.log(e);
    }
  }

  static async getSmartPathList() {
    try {
      if (DeviceHelper.smartPathCharacteristic) {
        let value = await DeviceHelper.smartPathCharacteristic.readValue();
        let smartPathData = await getSmartPathData(value);
        if (DeviceHelper.smarthPathIntervalId) {
          clearInterval(DeviceHelper.smarthPathIntervalId);
        }
        smartPathData = smartPathData.map((obj) => {
          return {
            ...obj,
            points: obj.points.map((point) => {
              return {
                temp: point.temp,
                time: point.time * (obj.duration / 255),
              };
            }),
          };
        });
        if (!localStorage.smartPathData) {
          let obj = {
            0: "Smart Path 1",
            1: "Smart Path 2",
            2: "Smart Path 3",
            3: "Smart Path 4",
          };
          localStorage.setItem("smartPathData", JSON.stringify(obj));
          store.dispatch({
            type: SET_SMARTH_PATH_TITLE,
            payload: obj,
          });
        } else {
          let smarthPathData = JSON.parse(
            localStorage.getItem("smartPathData") || "{}"
          );
          store.dispatch({
            type: SET_SMARTH_PATH_TITLE,
            payload: smarthPathData,
          });
        }
        store.dispatch({
          type: SET_INITIAL_SMART_PATH_DATA,
          payload: smartPathData,
        });
        return smartPathData;
      }
    } catch (e) {
      DeviceHelper.smarthPathIntervalId = setInterval(async () => {
        await DeviceHelper.getSmartPathList();
      }, 2000);
      console.log(e);
    }
  }

  static async getSessionData() {
    try {
      if (DeviceHelper.sessionDataCharacteristic) {
        let value = await DeviceHelper.sessionDataCharacteristic.readValue();
        let sessionData = await getSessionStatistics(value);
        store.dispatch({
          type: SET_SESSION_DATA,
          payload: sessionData,
        });
        return sessionData;
      }
    } catch (e) {
      console.log(e);
    }
  }

  static async getDeviceInformation() {
    let deviceInformation = {};
    try {
      if (DeviceHelper.deviceInformationService) {
        var characteristic =
          await DeviceHelper.deviceInformationService.getCharacteristic(
            Constants[ServiceConstants.SYSTEM_ID_CHAR]
          );
        if (
          characteristic &&
          characteristic.uuid === Constants[ServiceConstants.SYSTEM_ID_CHAR]
        ) {
          let value = await characteristic.readValue();
          deviceInformation["systemInformation"] = dtViewToStr(value);
        }
        var characteristic =
          await DeviceHelper.deviceInformationService.getCharacteristic(
            Constants[ServiceConstants.MODEL_NUMBER_CHAR]
          );
        if (
          characteristic &&
          characteristic.uuid === Constants[ServiceConstants.MODEL_NUMBER_CHAR]
        ) {
          let value = await characteristic.readValue();
          deviceInformation["model"] = dtViewToStr(value);
        }
        var characteristic =
          await DeviceHelper.deviceInformationService.getCharacteristic(
            Constants[ServiceConstants.FIRMWARE_REVISION_CHAR]
          );
        if (
          characteristic &&
          characteristic.uuid ===
          Constants[ServiceConstants.FIRMWARE_REVISION_CHAR]
        ) {
          let value = await characteristic.readValue();
          deviceInformation["firmwareVersion"] = dtViewToStr(value);
        }
        var characteristic =
          await DeviceHelper.deviceInformationService.getCharacteristic(
            Constants[ServiceConstants.SOFTWARE_REVISION_CHAR]
          );
        if (
          characteristic &&
          characteristic.uuid ===
          Constants[ServiceConstants.SOFTWARE_REVISION_CHAR]
        ) {
          let value = await characteristic.readValue();
          deviceInformation["softwareRevision"] = dtViewToStr(value);
        }
        var characteristic =
          await DeviceHelper.deviceInformationService.getCharacteristic(
            Constants[ServiceConstants.MANUFACTURER_NAME_CHAR]
          );
        if (
          characteristic &&
          characteristic.uuid ===
          Constants[ServiceConstants.MANUFACTURER_NAME_CHAR]
        ) {
          let value = await characteristic.readValue();
          deviceInformation["manufacturer"] = dtViewToStr(value);
        }
        var characteristic =
          await DeviceHelper.deviceInformationService.getCharacteristic(
            Constants[ServiceConstants.SERIAL_NUMBER_CHAR]
          );
        if (
          characteristic &&
          characteristic.uuid === Constants[ServiceConstants.SERIAL_NUMBER_CHAR]
        ) {
          let value = await characteristic.readValue();
          deviceInformation["serialNumber"] = dtViewToStr(value);
        }

        store.dispatch({
          type: SET_DEVICE_INFORMARION,
          payload: deviceInformation,
        });

        return deviceInformation;
      }
    } catch (e) {
      console.log("device informartion", e);
    }
  }

  static encodeSmartPathData(smartPathData) {
    let byteCounter = 0;
    const buffer = new ArrayBuffer(104);
    const view = new DataView(buffer);
    for (let i = 0; i < smartPathData.length; i++) {
      view.setInt16(0 + byteCounter, smartPathData[i].metaData, true);
      view.setInt16(2 + byteCounter, smartPathData[i].duration, true);
      let pointCounter = 0;
      for (let j = 4; j < 10; j = j + 2) {
        view.setUint8(
          j + byteCounter,
          smartPathData[i].points[pointCounter].time
        );
        view.setUint8(
          j + 1 + byteCounter,
          smartPathData[i].points[pointCounter].temp
        );
        pointCounter++;
      }
      view.setUint8(24 + byteCounter, smartPathData[i].numberOfPoints);
      byteCounter = byteCounter + 26;
    }
    return view;
  }

  static encodeSessionDataToByteArray(sessionData) {
    let byteArray = new Array();

    byteArray.push(ConvertToHexa(sessionData.sessionType - 1) || 0x00);
    byteArray.push(ConvertToHexa(sessionData.thcPercent.split(".")[0] || 0x00));
    byteArray.push(ConvertToHexa(sessionData.cbdPercent.split(".")[0] || 0x00));
    byteArray.push(ConvertToHexa(sessionData.ovenSizeDeviceIndex) || 0x00);

    console.log(sessionData);

    //4,5,6,7,8,9,10 puff
    byteArray.push(0x00);
    byteArray.push(0x00);
    byteArray.push(0x00);
    byteArray.push(0x00);
    byteArray.push(0x00);
    byteArray.push(0x00);
    byteArray.push(0x00);

    //11,12, total THC
    byteArray.push(0x00);
    byteArray.push(0x00);

    //13,14 total cbd
    byteArray.push(0x00);
    byteArray.push(0x00);

    //15 session flag, 1 = start session
    byteArray.push(0x01);

    //16 puffs number
    byteArray.push(0x00);

    //17 THC fraction part
    byteArray.push(ConvertToHexa(sessionData.thcPercent.split(".")[1] || 0x00));

    //18 CBD Fraction part
    byteArray.push(ConvertToHexa(sessionData.cbdPercent.split(".")[1] || 0x00));

    // 19,20 reserve
    byteArray.push(0x00);
    return byteArray;
  }

  async ParseDeviceMetaData(service) {
    if (
      service.uuid === Constants[ServiceConstants.DEVICE_INFORMATION_SERVICE]
    ) {
      DeviceHelper.deviceInformationService = service;
    }
    if (service.uuid === "edfec62e-9900-b00b-1e50-0050414e4743") {
      try {
        var characteristic = await service.getCharacteristic(
          Constants[ServiceConstants.CTRL_POINT_CHAR]
        );
        if (
          characteristic &&
          characteristic.uuid === Constants[ServiceConstants.CTRL_POINT_CHAR]
        ) {
          try {
            DeviceHelper.controlPointCharacteristic = characteristic;
          } catch (e) {
            console.log("error", e);
          }
        }
      } catch (e) {
        console.log("error", e);
      }

      try {
        var characteristic = await service.getCharacteristic(
          Constants[ServiceConstants.SESSION_DATA_CHAR]
        );
        if (
          characteristic &&
          characteristic.uuid === Constants[ServiceConstants.SESSION_DATA_CHAR]
        ) {
          try {
            DeviceHelper.sessionDataCharacteristic = characteristic;
            DeviceHelper.sessionDataIntervalId = setInterval(
              handleDuplicateSessionStateNotifications,
              2000
            );
            DeviceHelper.sessionDataCharacteristic
              .startNotifications()
              .then((_) => {
                DeviceHelper.sessionDataCharacteristic.addEventListener(
                  "characteristicvaluechanged",
                  debounce(handleSessionStateNotifications, 5000)
                );
              })
              .catch((e) => {
                console.log("sessionNOTIF", e);
              });
          } catch (e) {
            console.log("error", e);
          }
        }
      } catch (e) {
        console.log("error", e);
      }
      try {
        var characteristic = await service.getCharacteristic(
          Constants[ServiceConstants.CURRENT_STATE_CHAR]
        );

        if (
          characteristic &&
          characteristic.uuid === Constants[ServiceConstants.CURRENT_STATE_CHAR]
        ) {
          try {
            DeviceHelper.currentDeviceStateCharacteristic = characteristic;
            DeviceHelper.currentDataIntervalId = setInterval(
              handleDuplicateCurrenStateNotifications,
              1000
            );
            DeviceHelper.currentDeviceStateCharacteristic
              .startNotifications()
              .then((_) => {
                DeviceHelper.currentDeviceStateCharacteristic.addEventListener(
                  "characteristicvaluechanged",
                  throttle(handleCurrenStateNotifications, 1000)
                );
              })
              .catch((e) => {
                console.log("currentNOTIF", e);
              });
          } catch (e) {
            console.log("error", e);
          }
        }
      } catch (e) {
        console.log("error", e);
      }

      try {
        var characteristic = await service.getCharacteristic(
          Constants[ServiceConstants.SMART_PATHS_CHAR]
        );
        if (
          characteristic &&
          characteristic.uuid === Constants[ServiceConstants.SMART_PATHS_CHAR]
        ) {
          try {
            DeviceHelper.smartPathCharacteristic = characteristic;
          } catch (e) {
            console.log("error", e);
          }
        }
      } catch (e) {
        console.log("error", e);
      }

      try {
        var characteristic = await service.getCharacteristic(
          Constants[ServiceConstants.STATISTICS_CHAR]
        );
        if (
          characteristic &&
          characteristic.uuid === Constants[ServiceConstants.STATISTICS_CHAR]
        ) {
          try {
            DeviceHelper.deviceStatisticsCharacteristic = characteristic;
          } catch (e) {
            console.log("error", e);
          }
        }
      } catch (e) {
        console.log("error", e);
      }
    }

    if (service.uuid === "0000180a-0000-1000-8000-00805f9b34fb") {
    }

    try {
      var characteristic = await service.getCharacteristic(
        Constants[ServiceConstants.UPDATE_FW_CHAR]
      );
      if (
        characteristic &&
        characteristic.uuid === Constants[ServiceConstants.UPDATE_FW_CHAR]
      ) {
        try {
          DeviceHelper.firmwareUpdateCharacteristic = characteristic;
        } catch (e) {
          console.log("error", e);
        }
      }
    } catch (e) {
      console.log("error", e);
    }

    return true;
  }

  async ConnectDevice() {
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [
          {
            services: ["edfec62e-9900-b00b-1e50-005041934743"],
          },
        ],
        optionalServices: [
          "edfec62e-9900-b00b-1e50-0050414e4743",
          "0000180a-0000-1000-8000-00805f9b34fb",
          "00001801-0000-1000-8000-00805f9b34fb",
          "00001800-0000-1000-8000-00805f9b34fb",
        ],
      });

      device.addEventListener("gattserverdisconnected", onDisconnected);
      DeviceHelper.gattInstance = device.gatt;

      const server = await device.gatt.connect();
      DeviceHelper.deviceConnection = server;

      const services = await server.getPrimaryServices(); // Get device info
      const promises = services.map(async (service) => {
        return this.ParseDeviceMetaData(service);
      });

      await Promise.all(promises);
      return Promise.resolve();
    } catch (e) {
      console.error("Error connecting to the device:", e);
      return Promise.reject(e);
    }
  }
}

function handleDuplicateCurrenStateNotifications() {
  try {
    if (DeviceHelper.currentDeviceStateCharacteristic) {
      console.log("current interval");
      DeviceHelper.getCurrentDeviceState();
    }
  } catch (e) {
    console.log(e);
  }
}

function handleDuplicateSessionStateNotifications() {
  try {
    if (DeviceHelper.sessionDataCharacteristic) {
      console.log("session interval");
      DeviceHelper.getSessionData();
    }
  } catch (e) {
    console.log(e);
  }
}

function handleCurrenStateNotifications(event) {
  try {
    if (event.target && event.target.uuid) {
      if (
        event.target.uuid === Constants[ServiceConstants.CURRENT_STATE_CHAR]
      ) {
        if (DeviceHelper.currentDataIntervalId) {
          clearInterval(DeviceHelper.currentDataIntervalId);
          DeviceHelper.currentDataIntervalId = null;
        }
        DeviceHelper.currentDeviceStateCharacteristic = event.target;
        DeviceHelper.getCurrentDeviceState();
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function handleSessionStateNotifications(event) {
  try {
    if (event.target && event.target.uuid) {
      if (event.target.uuid === Constants[ServiceConstants.SESSION_DATA_CHAR]) {
        if (DeviceHelper.sessionDataIntervalId) {
          clearInterval(DeviceHelper.sessionDataIntervalId);
          DeviceHelper.sessionDataIntervalId = null;
        }
        DeviceHelper.sessionDataCharacteristic = event.target;
        DeviceHelper.getSessionData();
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function onDisconnected() {
  DeviceHelper.gattInstance = null;
  DeviceHelper.deviceConnection = null;
  DeviceHelper.controlPointCharacteristic = false;
  DeviceHelper.currentDeviceStateCharacteristic = false;
  DeviceHelper.sessionDataCharacteristic = false;
  DeviceHelper.smartPathCharacteristic = false;
  DeviceHelper.deviceInformationService = false;
  store.dispatch({
    type: CLEAR_DEVICE_STATE,
  });
  store.dispatch({
    type: DEVICE_DISCONNECTED,
  });
}
