const SERVER_CODES = {
  EMAIL_UNMATCHED: 50,
  EXISTING: 20,
  MIGRATING: 30,
  INVALID_CODE: 500,
  SUCCESS: 200,
  FORBIDDEN: 403,
  UNAUTHORIZED: 401,
  CONNECTION_TIMED_OUT: 599,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  BAD_REQUEST: 400,
  NO_CONTENT: 204,
};

export default SERVER_CODES;
