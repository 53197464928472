import { t } from "i18next";
import $ from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { toast } from "react-toastify";
import { store } from "../../store/index";

import { faClock, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import PathGraph from "../../components/PathGraph/PathGraph";
import { WriteSmartPaths } from "../../helpers/device";
import { CToF } from "../../helpers/helpers";
import { withHeader } from "../../hoc";
import { SET_SMART_PATH_DATA } from "../../store/actions";
import "./SmartPath.scss";
class SmartPath extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathStats: [],
      showDetaiModal: false,
      smartPathTitle: "",
      smartPath: {},
      constraint: {
        minTime: 0,
        minTemp: 0,
        maxTemp: props?.currentDeviceState?.displayModeCelcius ? 221 : 450,
        maxTime: 8,
      },
    };
  }

  componentDidMount() {
    let {
      currentDeviceSmartPathList,
      currentDeviceState,
      currentDeviceSmarthPathTitle,
    } = this.props;
    if (currentDeviceSmartPathList && currentDeviceSmartPathList.length) {
      this.setState({
        pathStats: currentDeviceSmartPathList.map((obj, index) => {
          let timeStats = "";

          let minTemp = parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.points[0].temp || 0
              : CToF(obj.points[0].temp || 0)
          );
          let maxTemp = parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.points[2].temp || 0
              : CToF(obj.points[2].temp || 0)
          );
          if (obj.numberOfPoints && obj.numberOfPoints > 0) {
            timeStats = `${minTemp}˚ - ${maxTemp}˚`;
          }

          return {
            obj,
            title:
              currentDeviceSmarthPathTitle[index] ||
              `${t("smart_path")} ${index + 1}`,
            pathIndex: index,
            time: obj.duration / 60,
            stats: timeStats,
            minTemp: minTemp,
            maxTemp: maxTemp,
          };
        }),
      });
    }
  }

  componentDidUpdate(prevProps) {
    let {
      currentDeviceSmartPathList,
      currentDeviceState,
      currentDeviceSmarthPathTitle,
    } = this.props;
    if (
      currentDeviceSmartPathList &&
      currentDeviceSmartPathList.length &&
      (prevProps.currentDeviceSmartPathList !== currentDeviceSmartPathList ||
        prevProps.currentDeviceSmarthPathTitle !== currentDeviceSmarthPathTitle)
    ) {
      this.setState({
        pathStats: currentDeviceSmartPathList.map((obj, index) => {
          let timeStats = "";

          let minTemp = parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.points[0].temp || 0
              : CToF(obj.points[0].temp || 0)
          );
          let maxTemp = parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.points[2].temp || 0
              : CToF(obj.points[2].temp || 0)
          );
          if (obj.numberOfPoints && obj.numberOfPoints > 0) {
            timeStats = `${minTemp}˚ - ${maxTemp}˚`;
          }

          return {
            obj,
            title:
              currentDeviceSmarthPathTitle[index] ||
              `${t("smart_path")} ${index + 1}`,
            pathIndex: index,
            time: obj.duration / 60,
            stats: timeStats,
            minTemp: minTemp,
            maxTemp: maxTemp,
          };
        }),
      });
    }
  }

  renderSmartPathDetail = (obj, index) => {
    $("body").addClass("fixed-mod-mob");
    let { currentDeviceSmartPathList, currentDeviceState } = this.props;
    let { constraint } = this.state;
    let smartPathConstraint = { ...constraint };
    if (index == 1) {
      let minTemp = parseInt(
        currentDeviceState.displayModeCelcius
          ? currentDeviceSmartPathList[0].points[0].temp || 0
          : CToF(currentDeviceSmartPathList[0].points[0].temp || 0)
      );
      smartPathConstraint.minTemp = minTemp + 0.5;
    }

    if (index == 2) {
      let minTemp = parseInt(
        currentDeviceState.displayModeCelcius
          ? currentDeviceSmartPathList[1].points[0].temp || 0
          : CToF(currentDeviceSmartPathList[1].points[0].temp || 0)
      );
      smartPathConstraint.minTemp = minTemp + 0.6;
    }

    if (index == 3) {
      let minTemp = parseInt(
        currentDeviceState.displayModeCelcius
          ? currentDeviceSmartPathList[2].points[0].temp || 0
          : CToF(currentDeviceSmartPathList[2].points[0].temp || 0)
      );
      smartPathConstraint.minTemp = minTemp + 0.6;
    }

    if (index == 0) {
      smartPathConstraint.minTemp = 0;
    }

    if (
      !currentDeviceState.displayModeCelcius &&
      smartPathConstraint.minTemp <= 280
    ) {
      smartPathConstraint.minTemp = 280;
    }
    this.setState({
      showDetaiModal: true,
      smartPathTitle: obj.title,
      smartPath: obj,
      constraint: smartPathConstraint,
    });
  };

  handleClose = () => {
    this.setState({
      showDetaiModal: false,
    });
  };

  resetAllSmartPaths = async () => {
    try {
      let { originalDeviceSmartPathList } = this.props;
      store.dispatch({
        type: SET_SMART_PATH_DATA,
        payload: originalDeviceSmartPathList,
      });
      await WriteSmartPaths(originalDeviceSmartPathList);
      toast.success(t("successfully_reset_changes"));
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <>
        <h2 className="primary-heading text-center text-md-left mb-0">
          {t("smart_paths")}
        </h2>
        <div className="smart-path__stats">
          {this.state.pathStats.length === 0 && (
            <p className="m-0 pl-md-3 pl-0 text-center">
              {this.props.t("noSmartPath")}
            </p>
          )}
          <div className="row m-0">
            {this.state.pathStats.map((obj, index) => (
              <div className="col-12 col-md-6 pt-md-4 pt-2">
                <div
                  className="smart-path__box cursor-hand"
                  onClick={() => this.renderSmartPathDetail(obj, index)}
                  data-toggle="modal"
                  data-target="#pathgraph"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>{t(obj.title)}</h3>

                    {/* <button
                      type="button"
                      className="smart-btn"
                      onClick={() => this.renderSmartPathDetail(obj, index)}
                      data-toggle="modal"
                      data-target="#pathgraph"
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button> */}
                  </div>
                  <div className="d-flex pt-md-5 pb-md-2">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon className="smart-icon" icon={faClock} />
                      <p className="m-0 pl-3">
                        {Number(obj.time).toFixed(2)} {t("min")}
                      </p>
                    </div>

                    <div className="d-flex align-items-center pl-5">
                      <FontAwesomeIcon
                        className="smart-icon"
                        icon={faSyncAlt}
                      />
                      <p className="m-0 pl-3">{obj.stats}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {!!this.state.pathStats.length && (
              <div
                style={{ textAlign: "center", width: "100%", marginTop: 20 }}
              >
                <button
                  type="button"
                  className="btn btn-primary ml-3 align-items-center"
                  onClick={this.resetAllSmartPaths}
                >
                  {t("resetAll")}
                </button>
              </div>
            )}
          </div>
        </div>
        {this.state.showDetaiModal && (
          <PathGraph
            name="pathgraph"
            title={this.state.smartPathTitle}
            smartPath={this.state.smartPath}
            onClose={this.handleClose}
            constraint={this.state.constraint}
            history={this.props.history}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentDeviceSmartPathList: state.device.currentDeviceSmartPathList,
  currentDeviceState: state.device.currentDeviceState,
  currentDeviceSmarthPathTitle: state.device.currentDeviceSmarthPathTitle,
  originalDeviceSmartPathList: state.device.originalDeviceSmartPathList,
});

// export default connect(mapStateToProps, null)(withHeader(SmartPath));
const enhance = compose(
  withTranslation(),
  withHeader,
  connect(mapStateToProps, null)
);
export default enhance(SmartPath);
