const Spanish = {
  __BUTTON_TEXT__: "TODO EL TEXTO DEL BOTÓN",
  back_to_Login: "Atrás para iniciar sesión",
  register: "REGISTRARSE",
  privacy: "Privacidad",
  confirm: "CONFIRMAR",
  login: "ACCESO",
  resetPassword: "Restablecer la contraseña",
  enter_new_password: "Introduzca una nueva contraseña",
  confirm_your_password: "Confirme su contraseña",
  passwords_do_not_match: "Las contraseñas no coinciden",
  forgot_password: "Has olvidado tu contraseña",
  submit: "ENVIAR",
  submitSmall: "Enviar",
  setup: "CONFIGURACIÓN",
  skip: "SALTAR",
  connect: "Conectar",
  disconnect: "Desconectar",
  forget: "Olvidar",
  continue: "Seguir",
  edit_profile: "Editar perfil",
  resetAll: "Resetear todo",
  begin: "EMPEZAR",
  close: "CERCA",
  deviceSetup: "Configuración de dispositivo",
  __TEXT__: "TODO EL TEXTO",
  create_Account: "Crear una cuenta",
  by_clicking_confirm_you_are_confirming_that_you_are_currently_21_years_of_age_or_older:
    'Al hacer clic en "CONFIRMAR". Usted está confirmando que actualmente tiene 21 años de edad o más',
  welcome_to: "Bienvenido a ",
  daVinci: "DaVinci",
  daVinci_welcome_info_msg:
    "DaVinci enriquece su experiencia de vaporización de hojas sueltas a través de un diseño cuidadoso, una ingeniería meticulosa y una tecnología innovadora",
  daVinci_welcome_functionality_msg:
    "Esta aplicación le permite conectarse a su IQ o IQ2 a través de su dispositivo móvil y personalizar la configuración de su vaporizador",
  daVinci_welcome_phone_icon_msg:
    "Enciende y controla tu dispositivo DaVinci a través de cualquier pantalla",
  daVinci_welcome_leaf_icon_msg:
    "Visite la sección de aromaterapia para obtener más información sobre los diferentes beneficios de las hierbas y las temperaturas ideales para cada una.",
  daVinci_welcome_graph_icon_msg:
    "Use la tecnología Smart Path para personalizar diferentes temperaturas de variaciones y sabores únicos",
  daVinci_welcome_shop_icon_msg:
    "Compre todos los productos y accesorios de DaVinci desde aquí",
  welcome_msg: "Gracias por usar la aplicación en línea DaVinci",
  pairing_msg:
    "Si tiene su dispositivo DaVinci con usted, haga clic a continuación para iniciar el proceso de emparejamiento",
  name_your_device: "Asigne un nombre a su dispositivo",
  setup_device: "DISPOSITIVO DE CONFIGURACIÓN",
  rename_device: "Renombrar dispositivo",
  edit_name: "Editar nombre",
  home: "HOGAR",
  dashboard: "TABLERO",
  setting: "AJUSTES",
  my_account: "MI CUENTA",
  my_device: "MIS DISPOSITIVOS",
  smart_paths: "CAMINOS INTELIGENTES",
  smart_path: "Camino inteligente",
  info_support: "INFORMACIÓN Y SOPORTE",
  davinci_study: "estudio de davinci",
  shop: "TIENDA",
  logout: "CERRAR SESIÓN",
  welcome: "Bienvenidos",
  everything_else: "TODO LO DEMAS",
  accessories_for_all_devices: "ACCESORIOS PARA TODOS LOS DISPOSITIVOS",
  upload_profile_picture: "Subir foto de perfil",
  change_password: "Cambia la contraseña",
  no_device: "No device",
  no_devices_found: "NO SE ENCONTRARON DISPOSITIVOS",
  no_devices_found_short: "No se encontraron dispositivos",
  no_devices_found_msg:
    "Empareje un dispositivo DaVinci para comenzar a personalizar su experiencia",
  model: "Modelo",
  name: "Nombre",
  address: "Dirección",
  serial_number: "Número de serie",
  firmware_version: "Versión de firmware",
  total_usage: "Uso total",
  average_session: "Sesión promedio",
  average_temperature: "Temperatura media",
  favorite_path: "Camino favorita",
  total_THC_consumed: "THC total consumido",
  total_CBD_consumed: "CBD total consumido",
  total_draws: "Sorteos totales",
  disconnected: "Desconectado",
  connected: "Conectado",
  check_for_firmware_update: "COMPRUEBE LA ACTUALIZACIÓN DEL FIRMWARE",
  end: "Final",
  session_completed: "Sesión completada",
  time_expired: "Tiempo expirado",
  strain: "Presion",
  start_time: "Hora de inicio",
  end_time: "Hora de finalización",
  THC_consumed: "THC consumido",
  CBD_consumed: "CBD consumido",
  rate_your_session: "Califica tu sesión",
  would_you_like_to_save_your_session: "¿Quieres guardar tu sesión?",
  yes: "sí",
  no: "no",
  Yes: "Sí",
  No: "No",
  star: "Estrellas",
  stop_time: "Para el tiempo",
  avg_temp: "Promedio Temperatura",
  strainType: "Tipo de cepa",
  ovenContent: "Contenido del horno",
  rating: "Clasificación",
  amountLoaded: "Cantidad cargada",
  min: "min",
  noSmartPath: "Sin Camino Inteligente",
  extract: "Extracto",
  grainOfRice: "Grano de arroz",
  dryHerb: "hierba seca",
  full: "Completo",
  daVinci_dosage_pod_or_large_spacer_inserted:
    "DaVinci Dosage Pod o espaciador grande insertado",
  or_small_daVinci_spacer_inserted: "o pequeño espaciador DaVinci insertado",
  emptyFields: "Campos vacíos",
  your_have_blank_fields_here_this_will_prevent_your_IQ2_from_tracking_and_displaying_your_session_information_accurately:
    "Tiene campos en blanco aquí, esto evitará que su IQ2 rastree y muestre la información de su sesión con precisión",
  would_you_like_to_proceed_anyways: "¿Le gustaría continuar de todos modos?",
  click_on_the_plus_icon_to_begin_the_paring_process:
    "Haga clic en el ícono más para comenzar el proceso de emparejamiento",
  __PLACEHOLDER__: "TODO MARCADOR DE POSICIÓN",
  email: "Correo electrónico",
  email_address: "Dirección de correo electrónico",
  first_name: "Primer nombre",
  last_name: "Apellido",
  save: "AHORRAR",
  password: "Clave",
  new_password: "Nueva contraseña",
  confirm_password: "Confirmar contraseña",
  device_name: "Nombre del dispositivo",
  shop_now: "COMPRA AHORA",
  davinci_study_msg:
    "Aprende sobre todo lo relacionado con la vaporización y el cannabis. ¿Quieres saber cómo afecta a tu sesión el control de la temperatura, el efecto séquito o los terpenos? Sumérgete en el estudio durante tu próxima sesión y expande tu mente.",
  learn_more: "Aprende más",
  home_slider_text: "VAPORIZADOR DAVINCI IQ2",
  lost_connection: "Conexión perdida",
  daVinci_vaporizer_has_lost_the_connection_to_the_device:
    "El vaporizador DaVinci ha perdido la conexión con el dispositivo.",
  device_state: "Estado del dispositivo",
  n_a: "N / A",
  current_temperature: "Temperatura actual",
  target_temperature: "Temperatura objetivo",
  select_smart_path: "SELECCIONE LA  CAMINO INTELIGENTE",
  session: "Sesión",
  history: "Historia",
  time: "Tiempo",
  temperature: "La temperatura",
  no_data_available: "Datos no disponibles",
  no_smart_path: "Sin Camino Inteligente",
  vibration_alert: "ALERTAS DE VIBRACIÓN",
  stealth_mode: "MODO SIGILO",
  temperature_unit: "UNIDAD DE TEMPERATURA",
  off: "apagado",
  on: "en",
  target: "OBJETIVO",
  temp: "TEMPERATURA",
  ovenSize: "Tamaño del horno",
  timeRemaining: "Tiempo restante",
  targetCBD: "CDB objetivo",
  consumedCBD: "CBD consumido",
  targetTHC: "THC objetivo",
  consumedTHC: "THC consumido",
  sessionSetup: "Configuración de sesión",
  please_enter_your_session_information:
    "Por favor ingrese la información de su sesión",
  strainName: "Nombre de la cepa",
  THC: "THC%",
  CBD: "CDB%",
  select: "Seleccione",
  newBowl: "Tazón nuevo",
  secondUse: "2do uso",
  thirdUse: "3er uso",
  indica: "Indica",
  sativa: "sativa",
  indicaDominantHybrid: "Híbrido Indica Dominante",
  sativaDominantHybrid: "Híbrido Sativa Dominante",
  "Smart Path 1": "CAMINO INTELIGENTE 1",
  "Smart Path 2": "CAMINO INTELIGENTE 2",
  "Smart Path 3": "CAMINO INTELIGENTE 3",
  "Smart Path 4": "CAMINO INTELIGENTE 4",

  __TOAST_MESSAGES__: "TOAST_MENSAJES",
  form_fields_are_required: "Los campos del formulario son obligatorios",
  login_success: "Inicio de sesión exitoso",
  enter_login_email_to_recover_your_password:
    "Ingrese el correo electrónico de inicio de sesión para recuperar su contraseña",
  success: "Éxito",
  email_is_required: "correo electronico es requerido",
  device_name_is_required: "El nombre del dispositivo es obligatorio",
  device_connected: "Dispositivo conectado",
  device_can_not_connected: "El dispositivo no se puede conectar",
  please_enter_a_password: "Porfavor ingrese una contraseña",
  successfully_reset_changes: "Restablecer cambios con éxito",
  new_password_and_confirm_password_do_not_match:
    "La nueva contraseña y la confirmación de la contraseña no coinciden",
  password_Changed_Successfully: "Contraseña cambiada con éxito",
  profileUpdateSuccessfully: "Actualización de perfil con éxito",
  successfully_saved_changes: "Cambios guardados correctamente",
  successfully_reset_smartpath: "Restablecer con éxito la Camino inteligente",
  device_is_not_connected: "El dispositivo no está conectado",
  device_not_connected: "Dispositivo no conectado",
  successfullyForgetDevice: "Olvidar correctamente el dispositivo",
  successfullyEditDeviceName: "Editar con éxito el nombre del dispositivo",
  device_disconnected: "Dispositivo desconectado",
  signupSuccess: "Registro exitoso",

  NewBowl: "NuevoCuenco",
  Second_Use: "Segundo uso",
  Third_Use: "Tercer_Uso",
  UNKNOWN: "DESCONOCIDO",
  INDICA: "INDICA",
  SATIVA: "SATIVA",
  INDICA_HYBRID: "INDICA_HÍBRIDO",
  SATIVA_HYBRID: "SATIVA_HÍBRIDO",
  Off: "Apagado",
  On: "En",
  Charging: "cargando",
  "SmartPath Mode": "Modo Camino Inteligente",
  "Precise Mode": "Modo preciso",
  "Selecting SmartPath": "Selección de Camino Inteligente",
  "Temperature Show SP": "Espectáculo de temperatura SP",
  "Boost Mode": "Modo de impulso",
  "StandBy Mode": "Modo de espera",
  noDeviceSelected: "Ningún dispositivo seleccionado",
  createNew: "Crear nuevo",
  region: "Región",
  device: "Dispositivo",
  currentFirmware: "Firmware actual",
  availableFirmware: "Firmware disponible",
  check: "Controlar",
  enterNewStrain: "Introducir nueva cepa",
  date: "Fecha",
};
export default Spanish;
