import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./Study.scss";
import BlinkingDotText from "../../components/BlinkingDotText";
import { useSelector } from "react-redux";
import { getHost } from "../../helpers/hostHelper";

const Study = () => {
  const [isRedirected, setIsRedirected] = useState(false)

  const { region } = useSelector(state => state.userAuth?.user)
  const host = getHost(region)

  useEffect(() => {
    if (isRedirected) return
    setTimeout(() => {
      window.open(`${host}/pages/app-davincis-study/`, "_blank")
      setIsRedirected(true)
    }, 1000)
  }, [])




  return (
    <div className="main-content banner-primary">
      <div
        className="info"
        style={{
          backgroundColor: "transparent",
          textAlign: "center",
          display: "flex",
          height: "calc(100vh - 120px)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BlinkingDotText
          text={"Redirecting to our store"}
          isRedirected={isRedirected}
          renderPostRedirection={() => (
            <p style={{ color: "#fff" }}>
              {" "}
              <a
                href={`${host}/pages/app-davincis-study/`}
                target="_blank"
                style={{ color: "#fff" }}
              >
                Click here
              </a>{" "}
              if you are not redirected.
            </p>
          )}
        />
      </div>
    </div >
  )
}

export default Study