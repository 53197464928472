import { t } from "i18next";
import React, { useState } from "react";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./InputField.scss";

const InputField = (props) => {
  const containerStyles = props.containerStyles || {};
  const [isReveal, setIsReveal] = useState(false);
  return (
    <>
      <div className="input-feild box-shadow" style={{ ...containerStyles }}>
        <div className="input-text">
          <input
            placeholder={t(props.placeholder)}
            name={props.name}
            type={isReveal ? "text" : props.type}
            value={props.value}
            onChange={props.onChange}
            autoComplete="off"
            max={props.max}
            onKeyDown={props.onKeyPress}
            min={props.min}
            onFocus={props.onFocus}
            style={props.style}
            maxLength={props.maxlength}
          />
        </div>
        {props.secure ? (
          <button
            className="eyeprop border-0 bg-transparent"
            onClick={() => {
              setIsReveal(!isReveal);
            }}
          >
            {isReveal ? (
              <FontAwesomeIcon icon={faEye} className="icon" />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} className="icon" />
            )}
          </button>
        ) : null}
      </div>
    </>
  );
};

export default InputField;
