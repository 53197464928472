import { t } from "i18next";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SessionOvenContent, StrainType } from "../../constants";
import { CToF, getKeyByValue, getSessionData } from "../../helpers/helpers";
import "./History.scss";
class History extends Component {
  state = {};
  render() {
    const { currentUser, currentDeviceState } = this.props;
    const sessionData = getSessionData();
    const sessionDataKeys = Object.keys(sessionData);
    sessionDataKeys.map((key) => {
      sessionData[key] = sessionData[key].filter((item) => {
        return item.user_id && item.user_id === currentUser.id;
      });
    });
    const dataExist = sessionDataKeys.some((key) => {
      return sessionData[key].length;
    });

    return (
      <>
        {(!sessionDataKeys.length || !dataExist) && (
          <p
            style={{
              marginTop: "10%",
              color: "white",
              textAlign: "center",
            }}
          >
            {t("No Data Available")}
          </p>
        )}
        {dataExist &&
          sessionDataKeys.map((sessionDataKey) => {
            return (
              <>
                <h4 className="title-heading pb-2 pt-4">
                  {moment(sessionDataKey).format("MMMM YYYY")}
                </h4>
                <div className="row m-0 history pt-2">
                  {sessionData[sessionDataKey].map((item) => (
                    <div className="history-boxes p-0 p-md-3 mb-4 ">
                      <div className="history__box">
                        <h3 className="pb-3">{item.strainName}</h3>
                        <div className="d-flex justify-content-between">
                          <div className="history__box-left pr-md-4 pr-2">
                            <div className="d-flex justify-content-between">
                              <p>{t("date")}</p>

                              <h4>{item.date || "N/A"}</h4>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("start_time")}</p>

                              <h4>{item.startTime}</h4>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("stop_time")}</p>

                              <h4>{item.endTime}</h4>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("avg_temp")}</p>

                              <h4>
                                {currentDeviceState.displayModeCelcius
                                  ? item.averageTemp
                                  : CToF(item.averageTemp) || 0}{" "}
                                {currentDeviceState.displayModeCelcius
                                  ? "˚C"
                                  : "˚F"}
                              </h4>
                            </div>
                          </div>
                          <div className="history__box-left">
                            <div className="d-flex justify-content-between">
                              <p>{t("THC_consumed")}</p>

                              <h4>{item.totalTHCConsumed} mg</h4>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("CBD_consumed")}</p>

                              <h4>{item.totalCBDConsumed} mg</h4>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("strainType")}</p>

                              <h4>
                                {" "}
                                {(item.strainType !== undefined &&
                                  t(
                                    getKeyByValue(StrainType, item.strainType)
                                  )) ||
                                  "N/A"}
                              </h4>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("ovenContent")}</p>

                              <h4>
                                {" "}
                                {(item.amountLoaded !== undefined &&
                                  t(
                                    getKeyByValue(
                                      SessionOvenContent,
                                      item.amountLoaded
                                    )
                                  )) ||
                                  "N/A"}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <p>{t("rating")}</p>

                          {/* <h4>
                            {(item.rating !== undefined &&
                              getKeyByValue(SessionRating, item.rating)) ||
                              "Good"}
                          </h4> */}
                          <div className="cst-star-rating ml-3">
                            <span
                              style={{ width: `${item.rating * 20}%` }}
                            ></span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <p
                            style={{
                              width: "-webkit-fill-available",
                              maxWidth: "fit-content",
                            }}
                          >
                            {t("amountLoaded")}
                          </p>
                          <h4 style={{ marginLeft: 10, marginTop: 2 }}>
                            {" "}
                            {item.ovenSize ? item.ovenSize : "N/A"}
                            {/* .substring(0, 52) */}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            );
          })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.userAuth.user,
  currentDeviceState: state.device.currentDeviceState,
});

// export default connect(mapStateToProps, null)(History);
export default connect(mapStateToProps, null)(withTranslation()(History));
