import { t } from "i18next";
import $ from "jquery";
import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { store } from "../../store/index";
// import Chart from "react-apexcharts";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { pathgraph } from "../../assets/images/index";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { WriteSmartPaths } from "../../helpers/device";
import {
  closeModal,
  ConvertToTwoDecimalPlaces,
  CToF,
  FToC,
  RoundOff,
} from "../../helpers/helpers";
import {
  SET_SMARTH_PATH_INDEX,
  SET_SMARTH_PATH_TITLE,
  SET_SMART_PATH_DATA,
} from "../../store/actions";
import InputField from "../InputField/InputField";
import "./PathGraph.scss";
require("highcharts/modules/draggable-points.src")(Highcharts);

class PathGraph extends Component {
  constructor(props) {
    super(props);
    this.afterChartCreated = this.afterChartCreated.bind(this);
    this.state = {
      barWidth: 0.6,
      selectedMarkerIndex: -1,
      maxTime: 0,
      maxTemp: 450,
      isSaved: false,
      isEditName: false,
      minTemp: props.constraint.minTemp || 0,
      minTime: 0,
      currentTime: 0,
      currentTemp: 0,
      smartPathPayload: [],
      smartPathData: [],
      isDragging: false,
      name: props.title,
      canDrag: true,
      optionsMixedChart: {
        credits: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
          animation: false,
        },
        colors: ["#E58260"],
        width: "800px",
        chart: {
          id: "basic-bar",
          backgroundColor: "#322c3a",
          toolbar: {
            show: false,
          },
          height: "70%",
        },
        xAxis: {
          categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8"],
          type: "numeric",
          title: {
            text: "Time",
          },
          labels: {
            style: {
              color: "#E58260",
              fontSize: "18px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          bar: {
            dragDrop: {
              draggableX: true,
              draggableY: true,
              dragMinY: this.props.constraint.minTemp || 0,
              dragMaxY: this.props.currentDeviceState.displayModeCelcius
                ? 221
                : 450,
              dragMinX: 0,
              dragMaxX: 8,
              dragSensitivity: 0,
            },
            point: {
              events: {
                drag: (e) => {
                  this.onDrag(e);
                },
                drop: (e) => {
                  this.onDrop(e);
                },
                click: (e) => {
                  this.onClick(e);
                },
              },
            },
          },
        },
        yAxis: {
          tickAmount: 6,
          min: 200,
          max: 450,
          ceil: 450,
          floor: 200,
          title: {
            text: "Temperature",
          },
          labels: {
            style: {
              color: "#E58260",
              fontSize: "18px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
        series: [
          {
            name: "smart-path",
            label: {
              enabled: false,
            },
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: false,
              },
            },
            type: "line",
            data: [
              { x: 8.0, y: 0 },
              { x: 7.0, y: 0 },
              { x: 6.0, y: 0 },
              { x: 5.0, y: 0 },
              { x: 4.0, y: 0 },
              { x: 3.0, y: 0 },
              { x: 2.0, y: 0 },
              { x: 1.0, y: 0 },
              { x: 0.0, y: 0 },
            ],
          },
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 300,
              },
              chartOptions: {
                chart: {
                  height: "100%",
                  width: "50%",
                },
                yAxis: {
                  tickAmount: 6,
                  min: 0,
                  max: 450,
                  title: {
                    enabled: false,
                  },
                  labels: {
                    style: {
                      color: "#E58260",
                      fontSize: "10px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                    },
                  },
                },
                xAxis: {
                  categories: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                  min: 0,
                  max: 8,
                  type: "numeric",
                  startOnTick: false,
                  endOnTick: false,
                  title: {
                    enabled: false,
                  },
                  labels: {
                    style: {
                      color: "#E58260",
                      fontSize: "10px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    };
  }
  componentDidMount() {
    console.log("PATH GRAPH DATA", this.state.optionsMixedChart)
    try {
      let { currentDeviceSmartPathList, smartPath, currentDeviceState } =
        this.props;
      if (
        currentDeviceSmartPathList &&
        currentDeviceSmartPathList[smartPath.pathIndex] &&
        currentDeviceSmartPathList[smartPath.pathIndex].points
      ) {
        let data = currentDeviceSmartPathList[smartPath.pathIndex].points.map(
          (obj) => {
            return {
              x: parseFloat((obj.time / 60).toFixed(1)),
              y: RoundOff(
                parseFloat(
                  (currentDeviceState.displayModeCelcius
                    ? obj.temp || 0
                    : parseFloat(CToF(obj.temp || 0))
                  ).toFixed(1)
                )
              ),
            };
          }
        );
        data = data.filter((obj, i) => {
          return i < 3;
        });

        let min = 0;
        let max = 0;
        if (currentDeviceState.displayModeCelcius) {
          this.setState({
            maxTemp: 221,
          });
          min = 121;
          max = 221;
        } else {
          this.setState({
            maxTemp: 450,
          });
          min = 250;
          max = 430;
        }
        this.setState({
          smartPathData: [...data],
          optionsMixedChart: {
            ...this.state.optionsMixedChart,
            series: [
              {
                data: data,
                type: "line",
                name: "smarth-path",
                label: {
                  enabled: false,
                },
                marker: {
                  enabled: true,
                  symbol: "circle",
                  radius: 8,
                  fillColor: "#E58260",
                },
                states: {
                  hover: {
                    enabled: false,
                  },
                },
                dragDrop: {
                  draggableX: false,
                  draggableY: false,
                },
              },
              {
                data: data,
                type: "bar",
                name: "bar-path",
                label: {
                  enabled: false,
                },
                marker: {
                  enabled: false,
                },
                states: {
                  hover: {
                    enabled: false,
                  },
                },
                dragDrop: {
                  draggableX: true,
                  draggableY: true,
                },
                color: "#32393f",
                borderRadius: 13,
              },
              {
                name: "initial-path",
                color: "#FFFFFF",
                label: {
                  enabled: false,
                },
                marker: {
                  enabled: false,
                },
                dragDrop: {
                  draggableX: false,
                  draggableY: false,
                },
                states: {
                  hover: {
                    enabled: false,
                  },
                },
                type: "line",
                data: [
                  { x: 8.0, y: 0, color: "#FFFFFF" },
                  { x: 7.0, y: 0, color: "#FFFFFF" },
                  { x: 6.0, y: 0, color: "#FFFFFF" },
                  { x: 5.0, y: 0, color: "#FFFFFF" },
                  { x: 4.0, y: 0, color: "#FFFFFF" },
                  { x: 3.0, y: 0, color: "#FFFFFF" },
                  { x: 2.0, y: 0, color: "#FFFFFF" },
                  { x: 1.0, y: 0, color: "#FFFFFF" },
                  { x: 0.0, y: 0, color: "#FFFFFF" },
                ],
              },
            ],
            yAxis: {
              ...this.state.optionsMixedChart.yAxis,
              min: min,
              floor: min,
              max: max,
              ceil: max,
            },
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    $("body").removeClass("fixed-mod-mob");
  }

  saveChanges = async () => {
    // console.log("click");
    let {
      currentDeviceSmartPathList,
      smartPath,
      currentDeviceState,
      // onClose
    } = this.props;

    if (this.state.isEditName) {
      this.saveName();
    }

    let { optionsMixedChart } = this.state;
    let smartPathPayload = [];

    if (
      currentDeviceSmartPathList &&
      currentDeviceSmartPathList[smartPath.pathIndex] &&
      optionsMixedChart.series &&
      optionsMixedChart.series[0] &&
      optionsMixedChart.series[0].data
    ) {
      let data = optionsMixedChart.series[0].data.map((obj) => {
        let time = obj.x;
        if (time <= 0) {
          time = 0;
        }
        if (time > currentDeviceSmartPathList[smartPath.pathIndex].duration) {
          time = currentDeviceSmartPathList[smartPath.pathIndex].duration;
        }
        if (time > 0) {
          time = Math.round(
            (time /
              (currentDeviceSmartPathList[smartPath.pathIndex].duration /
                255)) *
            60
          );
        }

        return {
          time: time,
          temp: parseInt(
            currentDeviceState.displayModeCelcius ? obj.y : FToC(obj.y)
          ),
        };
      });

      data = data.filter((obj, i) => {
        return i < 3;
      });

      let displayData = optionsMixedChart.series[0].data.map((obj) => {
        let time = obj.x;
        if (time <= 0) {
          time = 0;
        }
        if (time > currentDeviceSmartPathList[smartPath.pathIndex].duration) {
          time = currentDeviceSmartPathList[smartPath.pathIndex].duration;
        }
        if (time > 0) {
          time = Math.round(time * 60);
        }

        let temp = parseInt(
          currentDeviceState.displayModeCelcius ? obj.y : FToC(obj.y)
        )
        if (temp <= 100) {
          temp = 105
        }

        return {
          time: time,
          temp: temp,
        };
      });

      displayData = displayData.filter((obj, i) => {
        return i < 3;
      });
      console.log(displayData, data);
      let displaySmarthPayload = cloneDeep(currentDeviceSmartPathList);

      smartPathPayload = currentDeviceSmartPathList;
      smartPathPayload[smartPath.pathIndex].points = data;
      smartPathPayload.map((obj, index) => {
        if (index !== smartPath.pathIndex) {
          obj.points.map((point) => {
            let time = point.time;
            if (time <= 0) {
              time = 0;
            }
            if (time > obj.duration) {
              time = obj.duration;
            }
            if (time > 0) {
              time = Math.round(time / (obj.duration / 255));
            }
            return (point.time = time);
          });
        }
        return index;
      });
      console.log(smartPathPayload);
      await WriteSmartPaths(smartPathPayload);

      displaySmarthPayload[smartPath.pathIndex].points = displayData;
      store.dispatch({
        type: SET_SMART_PATH_DATA,
        payload: displaySmarthPayload,
      });
      this.setState({ isSaved: true });
      // closeModal(this.props.name);
      // onClose();
      toast.success(t("successfully_saved_changes"));
    }
  };

  afterChartCreated(chart) {
    this.internalChart = chart;
    this.forceUpdate();
  }

  resetChanges = () => {
    try {
      let { optionsMixedChart } = this.state;
      let {
        currentDeviceSmartPathList,
        smartPath,
        currentDeviceState,
        onClose,
      } = this.props;
      if (
        currentDeviceSmartPathList &&
        currentDeviceSmartPathList[smartPath.pathIndex] &&
        currentDeviceSmartPathList[smartPath.pathIndex].points
      ) {
        let data = currentDeviceSmartPathList[smartPath.pathIndex].points.map(
          (obj) => {
            return {
              x: parseFloat((obj.time / 60).toFixed(1)),
              y: RoundOff(
                parseFloat(
                  (currentDeviceState.displayModeCelcius
                    ? obj.temp || 0
                    : parseFloat(CToF(obj.temp || 0))
                  ).toFixed(1)
                )
              ),
            };
          }
        );
        data = data.filter((obj, i) => {
          return i < 3;
        });

        this.setState({
          maxTime: 8,
          maxTemp: currentDeviceState.displayModeCelcius ? 221 : 450,
          minTemp: this.props.constraint.minTemp || 0,
          minTime: 0,
          currentTime: 0,
          currentTemp: 0,
          optionsMixedChart: {
            ...optionsMixedChart,
            series: [
              {
                ...optionsMixedChart.series[0],
                data: [...data],
              },
              {
                ...optionsMixedChart.series[1],
                data: [...data],
              },
              {
                name: "initial-path",
                color: "#FFFFFF",
                label: {
                  enabled: false,
                },
                marker: {
                  enabled: false,
                },
                // dragDrop: {
                //   draggableX: false,
                //   draggableY: false,
                // },
                states: {
                  hover: {
                    enabled: false,
                  },
                },
                type: "line",
                data: [
                  { x: 8.0, y: 0, color: "#FFFFFF" },
                  { x: 7.0, y: 0, color: "#FFFFFF" },
                  { x: 6.0, y: 0, color: "#FFFFFF" },
                  { x: 5.0, y: 0, color: "#FFFFFF" },
                  { x: 4.0, y: 0, color: "#FFFFFF" },
                  { x: 3.0, y: 0, color: "#FFFFFF" },
                  { x: 2.0, y: 0, color: "#FFFFFF" },
                  { x: 1.0, y: 0, color: "#FFFFFF" },
                  { x: 0.0, y: 0, color: "#FFFFFF" },
                ],
              },
            ],
          },
        });
      }
      closeModal(this.props.name);
      onClose();
      toast.success(t("successfully_reset_smartpath"));
    } catch (e) {
      console.log(e);
    }
  };

  handleChangeTemp = (e) => {
    try {
      let {
        selectedMarkerIndex,
        optionsMixedChart,
        minTemp,
        maxTemp,
        isDragging,
      } = this.state;
      if (
        // e.target.value > maxTemp ||
        // e.target.value < minTemp ||
        e.target.value > 450 ||
        e.target.value < 105 ||
        isDragging ||
        minTemp === undefined ||
        maxTemp === undefined
      ) {
        return;
      }
      if (
        e.target.value >= 0 &&
        selectedMarkerIndex !== -1 &&
        optionsMixedChart.series[0] &&
        optionsMixedChart.series[0].data
      ) {
        let markers = [...optionsMixedChart.series[0].data];
        if (
          markers[selectedMarkerIndex] &&
          markers[selectedMarkerIndex].y >= 0
        ) {
          markers[selectedMarkerIndex] = {
            ...markers[selectedMarkerIndex],
            y: RoundOff(parseFloat(e.target.value)),
          };

          switch (selectedMarkerIndex) {
            case 0:
              if (markers[1].y < e.target.value) {
                markers[1].y = RoundOff(parseFloat(e.target.value));
              }
              if (markers[2].y < e.target.value) {
                markers[2].y = RoundOff(parseFloat(e.target.value));
              }
              break;
            case 1:
              if (markers[2].y < e.target.value) {
                markers[2].y = RoundOff(parseFloat(e.target.value));
              }
              if (markers[0].y > e.target.value) {
                markers[0].y = RoundOff(parseFloat(e.target.value));
              }
              break;
            case 2:
              if (markers[0].y > e.target.value) {
                markers[0].y = RoundOff(parseFloat(e.target.value));
              }
              if (markers[1].y > e.target.value) {
                markers[1].y = RoundOff(parseFloat(e.target.value));
              }
              break;
            default:
          }
          this.setState({
            currentTemp: RoundOff(parseFloat(e.target.value)),
            optionsMixedChart: {
              ...optionsMixedChart,
              series: [
                {
                  ...optionsMixedChart.series[0],
                  data: [...markers],
                },
                {
                  ...optionsMixedChart.series[1],
                  data: [...markers],
                },
                {
                  name: "initial-path",
                  color: "#FFFFFF",
                  label: {
                    enabled: false,
                  },
                  marker: {
                    enabled: false,
                  },
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                  // dragDrop: {
                  //   draggableX: false,
                  //   draggableY: false,
                  // },
                  type: "line",
                  data: [
                    { x: 8.0, y: 0, color: "#FFFFFF" },
                    { x: 7.0, y: 0, color: "#FFFFFF" },
                    { x: 6.0, y: 0, color: "#FFFFFF" },
                    { x: 5.0, y: 0, color: "#FFFFFF" },
                    { x: 4.0, y: 0, color: "#FFFFFF" },
                    { x: 3.0, y: 0, color: "#FFFFFF" },
                    { x: 2.0, y: 0, color: "#FFFFFF" },
                    { x: 1.0, y: 0, color: "#FFFFFF" },
                    { x: 0.0, y: 0, color: "#FFFFFF" },
                  ],
                },
              ],
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleChangeTime = (e) => {
    try {
      let {
        selectedMarkerIndex,
        optionsMixedChart,
        maxTime,
        minTime,
        isDragging,
      } = this.state;

      if (
        e.target.value > maxTime ||
        e.target.value < minTime ||
        isDragging ||
        minTime === undefined ||
        maxTime === undefined
      ) {
        return;
      }

      if (
        e.target.value >= 0 &&
        selectedMarkerIndex !== -1 &&
        optionsMixedChart.series[0] &&
        optionsMixedChart.series[0].data
      ) {
        let markers = [...optionsMixedChart.series[0].data];
        if (
          markers[selectedMarkerIndex] &&
          markers[selectedMarkerIndex].x >= 0
        ) {
          markers[selectedMarkerIndex] = {
            ...markers[selectedMarkerIndex],
            x: parseFloat(e.target.value),
          };

          this.setState({
            currentTime: ConvertToTwoDecimalPlaces(parseFloat(e.target.value)),
            optionsMixedChart: {
              ...optionsMixedChart,
              series: [
                {
                  ...optionsMixedChart.series[0],
                  data: [...markers],
                },
                {
                  ...optionsMixedChart.series[1],
                  data: [...markers],
                },
                {
                  name: "initial-path",
                  type: "line",
                  color: "#FFFFFF",
                  label: {
                    enabled: false,
                  },
                  marker: {
                    enabled: false,
                  },
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                  // dragDrop: {
                  //   draggableX: false,
                  //   draggableY: false,
                  // },
                  data: [
                    { x: 8.0, y: 0, color: "#FFFFFF" },
                    { x: 7.0, y: 0, color: "#FFFFFF" },
                    { x: 6.0, y: 0, color: "#FFFFFF" },
                    { x: 5.0, y: 0, color: "#FFFFFF" },
                    { x: 4.0, y: 0, color: "#FFFFFF" },
                    { x: 3.0, y: 0, color: "#FFFFFF" },
                    { x: 2.0, y: 0, color: "#FFFFFF" },
                    { x: 1.0, y: 0, color: "#FFFFFF" },
                    { x: 0.0, y: 0, color: "#FFFFFF" },
                  ],
                },
              ],
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  closeHandler = () => {
    let { onClose, currentDeviceState } = this.props;
    this.setState({
      selectedMarkerIndex: -1,
      maxTime: 8,
      maxTemp: currentDeviceState.displayModeCelcius ? 221 : 450,
      minTemp: this.props.constraint.minTemp || 0,
      minTime: 0,
      currentTime: 0,
      currentTemp: 0,
      isEditName: false,
      isSaved: false,
      smartPathPayload: [],
      smartPathData: [],
    });
    onClose();
    closeModal(this.props.name);
  };

  startSession = () => {
    let { onClose, currentDeviceState } = this.props;
    this.setState({
      selectedMarkerIndex: -1,
      maxTime: 8,
      maxTemp: currentDeviceState.displayModeCelcius ? 221 : 450,
      minTemp: this.props.constraint.minTemp || 0,
      minTime: 0,
      currentTime: 0,
      currentTemp: 0,
      isEditName: false,
      isSaved: false,
      smartPathPayload: [],
      smartPathData: [],
    });
    onClose();
    closeModal(this.props.name);
    store.dispatch({
      type: SET_SMARTH_PATH_INDEX,
      payload: this.props.smartPath.pathIndex,
    });
    this.props.history.replace("/session");
  };

  editName = () => {
    this.setState({ isEditName: true });
  };

  saveName = () => {
    let smartPathObj = JSON.parse(
      localStorage.getItem("smartPathData") || "{}"
    );
    smartPathObj[this.props.smartPath.pathIndex] = this.state.name;
    localStorage.setItem("smartPathData", JSON.stringify(smartPathObj));
    store.dispatch({
      type: SET_SMARTH_PATH_TITLE,
      payload: smartPathObj,
    });
    this.setState({ isEditName: false });
  };

  handleSmarthPathNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  updateChart = (newX, newY, markers, UpdatedData) => {
    this.setState({
      currentTime: newX ? newX : this.state.currentTime,
      currentTemp: newY ? newY : this.state.currentTemp,
      minTime: UpdatedData.minTime,
      maxTime: UpdatedData.maxTime,
      optionsMixedChart: {
        ...this.state.optionsMixedChart,
        plotOptions: {
          ...this.state.optionsMixedChart.plotOptions,
          bar: {
            ...this.state.optionsMixedChart.plotOptions.bar,
            dragDrop: { ...UpdatedData.dragDrop },
          },
        },
        series: [
          {
            ...this.state.optionsMixedChart.series[0],
            data: [...markers],
          },
          {
            ...this.state.optionsMixedChart.series[1],
            data: [...markers],
          },
          {
            name: "initial-path",
            type: "line",
            color: "#FFFFFF",
            data: [
              { x: 8.0, y: 0, color: "#FFFFFF" },
              { x: 7.0, y: 0, color: "#FFFFFF" },
              { x: 6.0, y: 0, color: "#FFFFFF" },
              { x: 5.0, y: 0, color: "#FFFFFF" },
              { x: 4.0, y: 0, color: "#FFFFFF" },
              { x: 3.0, y: 0, color: "#FFFFFF" },
              { x: 2.0, y: 0, color: "#FFFFFF" },
              { x: 1.0, y: 0, color: "#FFFFFF" },
              { x: 0.0, y: 0, color: "#FFFFFF" },
            ],
          },
        ],
      },
    });
  };

  onDrag = (e) => {
    try {
      let UpdatedData = {};
      let oldX, oldY;
      let newX = ConvertToTwoDecimalPlaces(e.newPoint.x);
      let newY = RoundOff(e.newPoint.y);
      let markers = this.state?.optionsMixedChart?.series[0]?.data || [];
      let markerIndex = -1;
      if (markerIndex === -1) {
        let oldPoints = e.origin.points;
        if (!Object.keys(oldPoints).length) {
          return false;
        }
        let key = Object.keys(oldPoints)[0];
        oldX = oldPoints[key].point.x;
        oldY = oldPoints[key].point.y;
        markerIndex = markers.findIndex(
          (obj) =>
            RoundOff(obj.x) === RoundOff(oldX) &&
            RoundOff(obj.y) === RoundOff(oldY)
        );
      }
      if (markerIndex !== -1) {
        this.setState({
          selectedMarkerIndex: markerIndex,
          isDragging: true,
        });
        switch (markerIndex) {
          case 0:
            UpdatedData = this.dragMarkerA(markers, markerIndex, newY);
            break;
          case 1:
            UpdatedData = this.dragMarkerB(markers, markerIndex, newY);
            break;
          case 2:
            UpdatedData = this.dragMarkerC(markers, markerIndex, newY);
            break;
          default:
            break;
        }
        markers.forEach((marker) => {
          marker["color"] = "#322c3a";
        });
        markers[markerIndex]["color"] = "#a3a3a3";
        this.updateChart(newX, newY, markers, UpdatedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  onDrop = (e) => {
    try {
      this.setState({ isDragging: false, canDrag: false });
      let newX = ConvertToTwoDecimalPlaces(e.newPoint.x);
      let newY = RoundOff(e.newPoint.y);
      let UpdatedData = {};
      let { selectedMarkerIndex } = this.state;
      let markerIndex = selectedMarkerIndex;
      switch (selectedMarkerIndex) {
        case 0:
          UpdatedData = this.dropMarkerA(markerIndex);
          break;
        case 1:
          UpdatedData = this.dropMarkerB(markerIndex);
          break;

        case 2:
          UpdatedData = this.dropMarkerC(markerIndex);
          break;
        default:
          break;
      }
      let { optionsMixedChart } = this.state;
      if (
        selectedMarkerIndex !== -1 &&
        optionsMixedChart.series[0] &&
        optionsMixedChart.series[0].data
      ) {
        let markers = optionsMixedChart.series[0].data;
        if (markers[selectedMarkerIndex]) {
          if (newX) {
            markers[selectedMarkerIndex].x = newX;
          }
          if (newY) {
            markers[selectedMarkerIndex].y = newY;
          }
          markers.forEach((marker) => {
            marker["color"] = "#322c3a";
          });
          markers[markerIndex]["color"] = "#a3a3a3";
          this.updateChart(newX, newY, markers, UpdatedData);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  onClick = (e) => {
    try {
      e.stopPropagation();
      if (e.point.x >= 0 && e.point.y >= 0) {
        let markers = this.state?.optionsMixedChart?.series[0]?.data || [];
        let markerIndex = -1;
        markerIndex = markers.findIndex(
          (obj) => obj.x === e.point.x && obj.y === e.point.y
        );
        let UpdatedData = {};
        if (markerIndex !== -1) {
          this.setState({
            canDrag: false,
            selectedMarkerIndex: markerIndex,
            currentTemp: RoundOff(
              this.state.optionsMixedChart.series[0].data[markerIndex].y
            ),
            currentTime: ConvertToTwoDecimalPlaces(
              this.state.optionsMixedChart.series[0].data[markerIndex].x
            ),
          });
          switch (markerIndex) {
            case 0:
              UpdatedData = this.onClickMarkerA(markerIndex);
              break;
            case 1:
              UpdatedData = this.onClickMarkerB(markerIndex);
              break;
            case 2:
              UpdatedData = this.onClickMarkerC(markerIndex);
              break;
            default:
              break;
          }
          markers.forEach((marker) => {
            marker["color"] = "#322c3a";
          });
          markers[markerIndex]["color"] = "#a3a3a3";
          let { optionsMixedChart } = this.state;
          this.setState({
            minTime: UpdatedData.minTime,
            maxTime: UpdatedData.maxTime,
            optionsMixedChart: {
              ...optionsMixedChart,
              plotOptions: {
                ...this.state.optionsMixedChart.plotOptions,
                bar: {
                  ...this.state.optionsMixedChart.plotOptions.bar,
                  dragDrop: { ...UpdatedData.dragDrop },
                },
              },
              series: [
                {
                  ...this.state.optionsMixedChart.series[0],
                  data: [...markers],
                },
                {
                  ...this.state.optionsMixedChart.series[1],
                  data: [...markers],
                },
                {
                  name: "initial-path",
                  type: "line",
                  color: "#FFFFFF",
                  data: [
                    { x: 8.0, y: 0, color: "#FFFFFF" },
                    { x: 7.0, y: 0, color: "#FFFFFF" },
                    { x: 6.0, y: 0, color: "#FFFFFF" },
                    { x: 5.0, y: 0, color: "#FFFFFF" },
                    { x: 4.0, y: 0, color: "#FFFFFF" },
                    { x: 3.0, y: 0, color: "#FFFFFF" },
                    { x: 2.0, y: 0, color: "#FFFFFF" },
                    { x: 1.0, y: 0, color: "#FFFFFF" },
                    { x: 0.0, y: 0, color: "#FFFFFF" },
                  ],
                },
              ],
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  dragMarkerA = (markers, markerIndex, newY) => {
    if (markers[1].y < newY) {
      markers[1].y = newY;
    }
    if (markers[2].y < newY) {
      markers[2].y = newY;
    }
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: 0,
        dragMaxX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
          this.state.barWidth
        ),
      },
      minTime: 0,
      maxTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
        this.state.barWidth
      ),
    };
  };

  dragMarkerB = (markers, markerIndex, newY) => {
    if (markers[2].y < newY) {
      markers[2].y = newY;
    }
    if (markers[0].y > newY) {
      markers[0].y = newY;
    }
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
          this.state.barWidth
        ),
        dragMaxX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
          this.state.barWidth
        ),
      },
      minTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
        this.state.barWidth
      ),
      maxTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
        this.state.barWidth
      ),
    };
  };

  dragMarkerC = (markers, markerIndex, newY) => {
    if (markers[0].y > newY) {
      markers[0].y = newY;
    }
    if (markers[1].y > newY) {
      markers[1].y = newY;
    }
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
          this.state.barWidth
        ),
        dragMaxX: 8,
      },
      minTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
        this.state.barWidth
      ),
      maxTime: 8,
    };
  };

  onClickMarkerA = (markerIndex) => {
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: 0,
        dragMaxX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
          this.state.barWidth
        ),
      },
      minTime: 0,
      maxTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
        this.state.barWidth
      ),
    };
  };

  onClickMarkerB = (markerIndex) => {
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
          this.state.barWidth
        ),
        dragMaxX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
          this.state.barWidth
        ),
      },
      minTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
        this.state.barWidth
      ),
      maxTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
        this.state.barWidth
      ),
    };
  };

  onClickMarkerC = (markerIndex) => {
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
          this.state.barWidth
        ),
        dragMaxX: 8,
      },
      minTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
        this.state.barWidth
      ),
      maxTime: 8,
    };
  };

  dropMarkerA = (markerIndex) => {
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: 0,
        dragMaxX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
          this.state.barWidth
        ),
      },
      minTime: 0,
      maxTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
        this.state.barWidth
      ),
    };
  };

  dropMarkerB = (markerIndex) => {
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
          this.state.barWidth
        ),
        dragMaxX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
          this.state.barWidth
        ),
      },
      minTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
        this.state.barWidth
      ),
      maxTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex + 1].x -
        this.state.barWidth
      ),
    };
  };

  dropMarkerC = (markerIndex) => {
    return {
      dragDrop: {
        ...this.state.optionsMixedChart.plotOptions.bar.dragDrop,
        draggableX: true,
        draggableY: true,
        dragMinX: ConvertToTwoDecimalPlaces(
          this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
          this.state.barWidth
        ),
        dragMaxX: 8,
      },
      minTime: ConvertToTwoDecimalPlaces(
        this.state.optionsMixedChart.series[0].data[markerIndex - 1].x +
        this.state.barWidth
      ),
      maxTime: 8,
    };
  };

  render() {
    let { smartPath } = this.props;
    return (
      <>
        <div
          className="path-box modal fade"
          id={this.props.name}
          tabIndex="-1"
          role="dialog"
          aria-labelledby={`${this.props.name}Label`}
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content fancy-scroll">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  onClick={this.closeHandler}
                >
                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={faAngleLeft}
                    className="social-icon"
                  />
                </button>
                <div className="form-group d-flex path-box__title align-items-center justify-content-center pt-md-5 pt-3">
                  {!this.state.isEditName ? (
                    <h3 className="primary-heading cursor-hand">
                      {this.state.name}
                    </h3>
                  ) : (
                    <InputField
                      value={this.state.name}
                      containerStyles={{ width: "50%" }}
                      onChange={this.handleSmarthPathNameChange}
                      maxLength={30}
                    />
                  )}
                  {/* <FontAwesomeIcon
                    aria-hidden="true"
                    icon={faPencilAlt}
                    className="edit-btn ml-4"
                  /> */}
                </div>
                <div className="path-box__info">
                  <HighchartsReact

                    highcharts={Highcharts}
                    callback={this.afterChartCreated}
                    options={this.state.optionsMixedChart}
                  />
                  <div id="chart">
                    {/* <Chart
                      options={this.state.options}
                      highcharts={Highcharts}
                    /> */}
                    {/* <Chart
                      options={this.state.optionsMixedChart}
                      series={this.state.seriesMixedChart}
                      type="line"
                      height={350}
                    /> */}
                  </div>
                  <div className="d-flex flex-column pt-4">
                    <div className="d-flex justify-content-between pb-2">
                      <p className="m-0">
                        Temp:{" "}
                        <span>
                          {/* {this.state.selectedMarkerIndex !== -1
                            ? this.state.currentTemp
                            : RoundOff(smartPath.minTemp)} */}105
                          ˚
                        </span>
                      </p>
                      <p className="m-0">
                        <span>
                          {/* {this.state.selectedMarkerIndex !== -1
                            ? this.state.maxTemp
                            : RoundOff(smartPath.maxTemp)} */}450
                          ˚
                        </span>
                      </p>
                    </div>
                    <div className="path-slidecontainer">
                      <input
                        type="range"
                        min={"105"}
                        max={"450"}
                        value={this.state.currentTemp}
                        className="path-slider"
                        onChange={this.handleChangeTemp}
                        id="myRange"
                        step="0.1"
                      ></input>
                    </div>
                    {/* <progress
                      className="w-100"
                      id="file"
                      value={this.state.currentTemp}
                      max={this.state.maxTemp}
                      min={this.state.minTemp}
                    
                    >
                      {" "}
                    </progress> */}
                  </div>
                  <div className="d-flex flex-column pt-4">
                    <div className="d-flex justify-content-between pb-2">
                      <p className="m-0">
                        Time: <span>{this.state.currentTime || "00:00"}</span>
                      </p>
                      <p className="m-0">
                        <span>
                          {this.state.maxTime !== undefined
                            ? this.state.maxTime
                            : smartPath.time}
                          min
                        </span>
                      </p>
                    </div>
                    <div className="path-slidecontainer">
                      <input
                        type="range"
                        min={this.state.minTime}
                        max={this.state.maxTime}
                        value={this.state.currentTime}
                        className="path-slider"
                        onChange={this.handleChangeTime}
                        id="myRange"
                        step="0.1"
                      // disabled={}
                      ></input>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center pt-5 smart-path-pop-btns-wrap">
                    {!this.state.isSaved && (
                      <>
                        <button
                          // data-dismiss="modal"
                          // aria-label="Close"
                          className="btn btn-dark"
                          onClick={this.saveChanges}
                        >
                          Save
                        </button>

                        <button
                          // data-dismiss="modal"
                          // aria-label="Close"
                          className="btn btn-primary ml-2"
                          onClick={this.resetChanges}
                        >
                          reset
                        </button>
                      </>
                    )}

                    {this.state.isSaved && (
                      <>
                        <button
                          // data-dismiss="modal"
                          // aria-label="Close"
                          className="btn btn-dark"
                          onClick={this.startSession}
                        >
                          Start
                        </button>

                        <button
                          // data-dismiss="modal"
                          // aria-label="Close"
                          className="btn btn-primary ml-3"
                          onClick={this.closeHandler}
                        >
                          Close
                        </button>
                      </>
                    )}

                    <button
                      // data-dismiss="modal"
                      // aria-label="Close"
                      className="btn btn-primary ml-2"
                      onClick={
                        this.state.isEditName ? this.saveName : this.editName
                      }
                    >
                      {this.state.isEditName ? "Save Name" : "Edit Name"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentDeviceSmartPathList: state.device.currentDeviceSmartPathList,
  currentDeviceState: state.device.currentDeviceState,
});

export default connect(mapStateToProps, null)(PathGraph);
