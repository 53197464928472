import {
  faAngleDown,
  faAngleUp,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { t } from "i18next";
import $ from "jquery";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  DeviceState,
  DeviceStateLabel,
  SessionovenSize,
  SessionOvenSizeLabel,
  SessionOvenSizePercentage,
  SessionRating,
  SessionStatus,
} from "../../constants";
import DEVICE_STATE from "../../constants/DeviceState";
import {
  DeviceHelper,
  SetSession,
  StartSmartPathMode,
  SwitchState,
  WriteControlKeyPressed,
} from "../../helpers/device";
import {
  ConvertToOneDecimalPlaces,
  CToF,
  FToC,
  getKeyByValue,
  getPlaceholderGraphData,
  getSessionData,
  getTimeFormatString,
  getXAxisCategories,
  setSessionData,
  truncateArray,
} from "../../helpers/helpers";
import {
  CLEAR_SESSION_DATA,
  SET_SESSION_BEGIN,
  SET_SMARTH_PATH_INDEX,
} from "../../store/actions";
import { store } from "../../store/index";
import SessionSetup from "../SessionSetup/SessionSetup";
import SessionStats from "../SessionStats/SessionStats";
import SmartPathList from "../SmartPathList/SmartPathList";
import "./SessionScreen.scss";
import { makeInitalGraphData } from "../../helpers/graphHelper";

class SessionScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeElapse: "08:00",
      duplicatetimeElapse: "08:00",
      lng: "en",
      timeIntervalId: null,
      timerStartTime: null,
      statIntervalId: null,

      pause: false,
      isIQ2: this.isIQ2(),

      smartPathModalId: "",

      graphData: getPlaceholderGraphData(),
      smartPathData: [],
      strainName: "",
      strainType: 0,
      amountLoaded: 0,
      sessionType: 0,
      totalTHCConsumed: 0,
      totalCBDConsumed: 0,
      showPuffStat: false,
      targetTHC: 0,
      THC: "0.0",
      targetCBD: 0,
      withOutDosage: false,
      CBD: "0.0",
      ovenSize: "",
      ovenSizeDeviceIndex: 0,
      smartPathIndex: -1,
      startTime: "00:00",
      endTime: "00:00",
      status: SessionStatus.InActive,
      rating: SessionRating.Good,
      showSmartPathList: false,
      showIQSmartPathList: false,
      puffCountUpdate: false,
      showLastDraw: false,
      puffStateDataView: null,
      optionsMixedChart: {
        title: {
          text: undefined,
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
          animation: false,
        },
        colors: ["#FFF", "#E58260"],
        chart: {
          id: "basic-bar",
          backgroundColor: "#322c3a",
          toolbar: {
            show: false,
          },
          height: "50%",
        },
        xAxis: {
          categories: getXAxisCategories(),
          type: "numeric",
          title: {
            text: props.t("time"),
          },
          labels: {
            style: {
              color: "#E58260",
              fontSize: "18px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
        yAxis: {
          tickAmount: 6,
          min: 200,
          max: 450,
          title: {
            text: props.t("temperature"),
          },
          labels: {
            style: {
              color: "#E58260",
              fontSize: "18px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (e) => {
                  try {
                    e.stopPropagation();
                    if (e.point.x >= 0 && e.point.y >= 0) {
                      let markers =
                        this.state?.optionsMixedChart?.series[0]?.data || [];
                      let markerIndex = -1;
                      markerIndex = markers.findIndex((obj) => {
                        return (
                          ((ConvertToOneDecimalPlaces(obj.x) ===
                            ConvertToOneDecimalPlaces(e.point.x) &&
                            ConvertToOneDecimalPlaces(obj.y) ===
                            ConvertToOneDecimalPlaces(e.point.y)) ||
                            (ConvertToOneDecimalPlaces(obj.x) ===
                              ConvertToOneDecimalPlaces(e.point.x) &&
                              ConvertToOneDecimalPlaces(e.point.y - 5) <
                              ConvertToOneDecimalPlaces(obj.y) <
                              ConvertToOneDecimalPlaces(e.point.y + 5))) &&
                          obj.marker &&
                          obj.marker.enabled &&
                          obj.newPuffDuration !== undefined &&
                          obj.lastPuffCBdConsumed !== undefined &&
                          obj.lastPuffThcConsumed !== undefined
                        );
                      });
                      if (markerIndex !== -1) {
                        this.setState(
                          {
                            showPuffStat: true,
                            puffStateDataView: {
                              lastPuffCBdConsumed:
                                markers[markerIndex].lastPuffCBdConsumed || 0,
                              lastPuffThcConsumed:
                                markers[markerIndex].lastPuffThcConsumed || 0,
                              newPuffDuration:
                                markers[markerIndex].newPuffDuration || 0,
                            },
                          },
                          () => {
                            if (this.state.statIntervalId) {
                              clearInterval(this.state.statIntervalId);
                            }
                            let intervalId = setTimeout(() => {
                              this.setState({ showPuffStat: false });
                            }, 5000);
                            this.setState({ statIntervalId: intervalId });
                          }
                        );
                      }
                    }
                  } catch (e) {
                    console.log(e);
                  }
                },
              },
            },
          },
        },
        series: [
          {
            type: "line",
            label: {
              enabled: false,
            },
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: false,
              },
            },
            data: getPlaceholderGraphData(),
          },
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                chart: {
                  height: "100%",
                },
                yAxis: {
                  tickAmount: 6,
                  min: 200,
                  max: 450,
                  title: {
                    enabled: false,
                  },
                  labels: {
                    style: {
                      color: "#E58260",
                      fontSize: "10px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                    },
                  },
                },
                xAxis: {
                  categories: getXAxisCategories(),
                  type: "numeric",
                  title: {
                    enabled: false,
                  },
                  labels: {
                    style: {
                      color: "#E58260",
                      fontSize: "10px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                    },
                  },
                },
              },
            },
          ],
        },
      },
      preciseModeChart: {
        ...makeInitalGraphData(),
        series: [{
          name: 'Current Temperature',
          data: []
        }, {
          name: 'Target Temperature',
          data: new Array(6).fill(parseInt(this.getTargetTemperatureString()?.substring(0, this.getTargetTemperatureString()?.length - 3))) || []
        }],
      }
    };
  }

  isIQ2() {
    const { currentDevice } = this.props;
    if (currentDevice && currentDevice.model) {
      if (currentDevice.model.toUpperCase().includes("IQ2")) {
        return true;
      }
      return false;
    }
  }

  componentDidMount() {
    let { currentSessionData, currentDevice, currentDeviceState } = this.props;

    if (currentDeviceState?.state && (currentDeviceState?.state !== DeviceState.OFF && currentDeviceState?.state !== DeviceState.CHARGE)) {
      this.makeGraphForPreciseMode()
    }

    if (Object.keys(currentSessionData).length) {
      this.setState(currentSessionData, () => { });
      if (currentSessionData.timerStartTime) {
        let timeRemaining = moment(moment().format("YYYY-MM-DD hh:mm:ss")).diff(
          currentSessionData.timerStartTime,
          "seconds"
        );
        if (timeRemaining < 480) {
          timeRemaining = moment(this.state.duplicatetimeElapse, "mm:ss")
            .subtract(timeRemaining, "seconds")
            .format("mm:ss");
          this.setState(
            { timeElapse: timeRemaining, duplicatetimeElapse: timeRemaining },
            () => {
              this.createTimer();
            }
          );
        }
      }
    }

    if (this.state.isIQ2 != this.isIQ2()) {
      this.setState({
        isIQ2: this.isIQ2(),
      });
    }

    if (
      this.props.smarthPathIndex !== -1 &&
      this.props.smarthPathIndex !== undefined
    ) {
      this.setState({ smartPathIndex: this.props.smarthPathIndex });
      this.handleSmartPathSelection(this.props.smarthPathIndex);
      store.dispatch({
        type: SET_SMARTH_PATH_INDEX,
        payload: -1,
      });
    }
  }

  sanitiseGraphData(graphData, currentTime) {
    const { currentDeviceState } = this.props;

    if (!currentTime) {
      return graphData;
    }

    if (currentDeviceState.state == DeviceState.PRECISION_TEMP) {
      return getPlaceholderGraphData();
    }

    let max = 0;
    let nGraphData = graphData.filter((x) => {
      if (max < x.x) {
        max = x;
      }
      return x.x <= currentTime;
    });

    if (max < this.getMaxSessionDurationInMinutes()) {
      nGraphData.push({
        x: this.getMaxSessionDurationInMinutes(),
        y: 0,
      });
    }
    return nGraphData;
  }

  componentDidUpdate(prevProps, prevState) {
    let { currentDevice, currentDeviceState, currentSessionState } = this.props;
    if (
      this.props.currentSessionState.lastPuffTotalCBdConsumed !==
      prevProps.currentSessionState.lastPuffTotalCBdConsumed
    ) {
      this.setState({
        totalCBDConsumed:
          this.props.currentSessionState.lastPuffTotalCBdConsumed,
      });
    }

    if (
      this.props.currentSessionState.lastPuffTotalThcConsumed !==
      prevProps.currentSessionState.lastPuffTotalThcConsumed
    ) {
      this.setState({
        totalTHCConsumed:
          this.props.currentSessionState.lastPuffTotalThcConsumed,
      });
    }

    if (
      currentDevice &&
      currentDevice.model &&
      ((prevProps.currentDevice &&
        prevProps.currentDevice.model &&
        prevProps.currentDevice.model !== currentDevice.model) ||
        !prevProps.currentDevice.model)
    ) {
      if (
        currentDevice.model.toUpperCase().includes("IQ2") &&
        !this.state.isIQ2
      ) {
        this.setState({ isIQ2: true });
      }
      if (!currentDevice.model.toUpperCase().includes("IQ2")) {
        this.setState({ isIQ2: false });
      }
    }

    if (
      this.props.currentSessionState.sessionTotalPuffCount > 0 &&
      this.props.currentSessionState.sessionTotalPuffCount !==
      prevProps.currentSessionState.sessionTotalPuffCount &&
      (this.state.status === SessionStatus.Active ||
        [DeviceState.PRECISION_TEMP, DeviceState.SMART_PATH].includes(
          currentDeviceState.state
        )) &&
      this.state.isIQ2
    ) {
      this.setState({
        puffCountUpdate: true,
        puffStateDataView: {
          lastPuffThcConsumed:
            this.props.currentSessionState.lastPuffThcConsumed || 0,
          lastPuffCBdConsumed:
            this.props.currentSessionState.lastPuffCBdConsumed || 0,
          newPuffDuration: this.props.currentSessionState.newPuffDuration || 0,
        },
      });
    }

    if (
      currentDeviceState.state != prevProps.currentDeviceState.state &&
      [DeviceState.PRECISION_TEMP, DeviceState.SMART_PATH].includes(
        currentDeviceState.state
      ) &&
      this.state.status != SessionStatus.Active
    ) {
      this.setState({ status: SessionStatus.Active });
    } else if (
      currentDeviceState.state != prevProps.currentDeviceState.state &&
      [
        DeviceState.OFF,
        DeviceState.CHARGE,
        DeviceState.ON,
        DeviceState.STAND_BY_MODE,
        DeviceState.BOOST_MODE,
      ].includes(currentDeviceState.state) &&
      this.state.status == SessionStatus.Active
    ) {
      this.setState({ status: SessionStatus.InActive });
    }

    // on session finish
    if (
      this.props.currentSessionState.sessionInProgress !==
      prevProps.currentSessionState.sessionInProgress &&
      !this.props.currentSessionState.sessionInProgress
    ) {
      let terminateSession = async () => {
        await this.handleSessionTermination(true);
      };
      terminateSession();
    }
    // on target reached
    if (
      this.state.status === SessionStatus.Active &&
      this.props.currentSessionState.sessionInProgress &&
      ((this.state.targetTHC > 0 &&
        this.props.currentSessionState.lastPuffTotalThcConsumed &&
        this.props.currentSessionState.lastPuffTotalThcConsumed >
        this.state.targetTHC) ||
        (this.state.targetCBD > 0 &&
          this.props.currentSessionState.lastPuffTotalCBdConsumed &&
          this.props.currentSessionState.lastPuffTotalCBdConsumed >
          this.state.targetCBD))
    ) {
      let terminateSession = async () => {
        await this.handleSessionTermination(true);
      };
      terminateSession();
    }

    // this.setGraphData();

    let isPuffCountUpdated = this.state.puffCountUpdate;

    if (!currentDeviceState || !currentDeviceState.time) {
      return;
    }

    const prevDeviceState = prevProps.currentDeviceState;

    if (prevDeviceState.time === currentDeviceState.time) {
      return;
    }

    let graphData = this.sanitiseGraphData(
      [
        ...this.state.graphData,
        {
          x: currentDeviceState.time / 60 / 1000,
          y: parseInt(
            currentDeviceState.displayModeCelcius
              ? (currentDeviceState.currentTemp || 0) / 100
              : CToF((currentDeviceState.currentTemp || 0) / 100)
          ),
          marker: {
            enabled: isPuffCountUpdated,
            fillColor: "#E58260",
            radius: 6,
          },
          ...(isPuffCountUpdated && this.state.puffStateDataView),
        },
      ],
      currentDeviceState.time / 60 / 1000
    );

    if (isPuffCountUpdated) {
      // setTimeout(() => {
      this.setState(
        { puffCountUpdate: false, showLastDraw: true, showPuffStat: true },
        () => {
          if (this.state.statIntervalId) {
            clearInterval(this.state.statIntervalId);
          }
          let intervalId = setTimeout(() => {
            this.setState({
              showLastDraw: false,
              showPuffStat: false,
              puffStateDataView: null,
            });
          }, 8000);
          this.setState({ statIntervalId: intervalId });
        }
      );
      // }, 100);
    }

    let seriesData = [
      {
        type: "line",
        data: graphData,
      },
    ];

    if (
      currentDeviceState.state === DeviceState.SMART_PATH &&
      (!this.state.smartPathData.length ||
        this.state.smartPathIndex != currentDeviceState.smartPathIndex)
    ) {
      let data = this.props.currentDeviceSmartPathList[
        currentDeviceState.smartPathIndex
      ]?.points?.map((obj) => {
        return {
          x: parseInt((obj.time / 60).toFixed(2)),
          y: parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.temp || 0
              : CToF(obj.temp || 0)
          ),
        };
      });
      data = data?.filter((obj, i) => {
        return (
          i <=
          this.props.currentDeviceSmartPathList[
            currentDeviceState.smartPathIndex
          ].numberOfPoints -
          1
        );
      });
      data && this.setState({ smartPathData: data });
    } else if (
      currentDeviceState.state !== DeviceState.SMART_PATH &&
      this.state.smartPathData.length
    ) {
      this.setState({ smartPathData: [] });
    }

    if (this.state.smartPathData.length) {
      seriesData.push({
        name: "smart-path",
        type: "line",
        data: this.state.smartPathData,
        marker: {
          enabled: false,
        },
      });
    }

    if (this.props.currentDeviceConnectd) {
      // setTimeout(() => {
      this.setState({
        graphData: graphData,
        optionsMixedChart: {
          ...this.state.optionsMixedChart,
          series: seriesData,
        },
      });
      // }, 100);
    } else {
      // setTimeout(() => {
      this.setState({
        graphData: graphData,
      });
      // }, 100);
    }
  }

  componentWillUnmount() {
    let {
      optionsMixedChart,
      timeIntervalId,
      timerStartTime,
      smartPathData,
      smartPathIndex,
      graphData,
      status,
    } = this.state;
    let { series } = this.state.optionsMixedChart;
    store.dispatch({
      type: SET_SESSION_BEGIN,
      payload: {
        timerStartTime: timeIntervalId ? timerStartTime : null,
        series: timeIntervalId ? series : [],
        status: status ? status : SessionStatus.InActive,
        graphData: graphData ? graphData : [],
        smartPathData: timeIntervalId ? smartPathData : [],
        smartPathIndex: timeIntervalId ? smartPathIndex : -1,
      },
    });
    if (timeIntervalId) {
      clearInterval(timeIntervalId);
    }
  }

  IncrementItem = async () => {
    let { currentDeviceState } = this.props;
    if (DeviceHelper.deviceConnection) {
      if (currentDeviceState.state !== DeviceState.PRECISION_TEMP) {
        SwitchState(0x05);
      } else {
        await WriteControlKeyPressed(0x10, 1 << 1);
        setTimeout(async () => {
          await WriteControlKeyPressed(0x11, 1 << 1);
        }, 100);
      }
      if (!this.state.timeIntervalId) {
        this.startSessionWithOutDosage();
      }
    } else {
      toast.error(t("device_is_not_connected"));
    }
  };

  DecreaseItem = async () => {
    let { currentDeviceState } = this.props;
    if (DeviceHelper.deviceConnection) {
      if (currentDeviceState.state !== DeviceState.PRECISION_TEMP) {
        SwitchState(0x05);
      } else {
        await WriteControlKeyPressed(0x10, 1 << 2);
        setTimeout(async () => {
          await WriteControlKeyPressed(0x11, 1 << 2);
        }, 100);
      }
      if (!this.state.timeIntervalId) {
        this.startSessionWithOutDosage();
      }
    } else {
      toast.error(t("device_is_not_connected"));
    }
  };

  createTimer = () => {
    if (this.state.timeIntervalId) {
      clearInterval(this.state.timeIntervalId);
    }
    let intervalId = setInterval(async () => {
      let {
        currentDeviceState,
        currentSessionState,
        currentDeviceInformation,
      } = this.props;
      let time = moment(this.state.duplicatetimeElapse, "mm:ss")
        .subtract(1, "seconds")
        .format("mm.ss");
      let timeRemaining = moment(this.state.duplicatetimeElapse, "mm:ss")
        .subtract(1, "seconds")
        .format("mm:ss");

      if (parseFloat(time) <= 0) {
        if (this.state.status === SessionStatus.Active && this.state.isIQ2) {
          await this.handleSessionTermination(true);
        }
        if (this.state.status === SessionStatus.InActive || !this.state.isIQ2) {
          if (this.state.timeIntervalId) {
            clearInterval(this.state.timeIntervalId);
          }
          await this.handleCloseSession();
          this.setState({
            graphData: getPlaceholderGraphData(),
            smartPathData: [],
            optionsMixedChart: {
              ...this.state.optionsMixedChart,
              series: [
                {
                  type: "line",
                  data: getPlaceholderGraphData(),
                },
              ],
            },
          });
        }
        return;
      }
    }, 1000);
    this.setState({
      timeIntervalId: intervalId,
    });
  };

  handleSessionBegins = async (id, smartPathMode) => {
    let { currentDeviceConnectd, currentDeviceState } = this.props;
    if (!currentDeviceConnectd) {
      toast.error(t("device_not_connected"));
      return false;
    }
    const {
      strainName,
      strainType,
      targetTHC,
      THC,
      targetCBD,
      CBD,
      ovenSize,
      amountLoaded,
      sessionType,
      ovenSizeDeviceIndex,
      smartPathIndex,
      withOutDosage,
    } = this.state;

    if (!strainName || !amountLoaded || !ovenSizeDeviceIndex) {
      if (!this.state.withOutDosage) {
        toast.error(t("form_fields_are_required"));
        return false;
      }
    }
    this.setState({
      startTime: moment().format("HH:mm"),
      status: SessionStatus.Active,
    });
    $("body").removeClass("modal-open");
    $("body").css("padding-right", "0px");
    $(`#${id}`).modal().hide();
    $(".modal-backdrop").remove();

    await DeviceHelper.getSessionDataCharacteristic();
    let sessionByteArray = DeviceHelper.encodeSessionDataToByteArray({
      thcPercent: THC,
      cbdPercent: CBD,
      sessionType,
      ovenSizeDeviceIndex: withOutDosage
        ? SessionovenSize.DRY_HERB_100_FULL
        : ovenSizeDeviceIndex,
    });

    await SwitchState(0x02);
    if (currentDeviceState.state === DeviceState.SMART_PATH && !smartPathMode) {
      await SwitchState(0x07);
    } else {
      await SwitchState(0x07);
    }

    if (withOutDosage) {
      this.setState({
        ovenSize: withOutDosage
          ? SessionOvenSizeLabel[SessionovenSize.DRY_HERB_100_FULL]
          : ovenSize,
        ovenSizeDeviceIndex: withOutDosage
          ? SessionovenSize.DRY_HERB_100_FULL
          : ovenSizeDeviceIndex,
      });
    }
    await SetSession(sessionByteArray);

    setTimeout(async () => {
      await SetSession(sessionByteArray);
    }, 1000);

    setTimeout(async () => {
      await SetSession(sessionByteArray);
    }, 2000);

    setTimeout(async () => {
      await SetSession(sessionByteArray);
    }, 3000);

    this.createTimer();
    store.dispatch({
      type: SET_SESSION_BEGIN,
      payload: {
        strainName,
        strainType,
        targetCBD,
        targetTHC,
        THC,
        CBD,
        ovenSize: withOutDosage
          ? SessionOvenSizeLabel[SessionovenSize.DRY_HERB_100_FULL]
          : ovenSize,
        amountLoaded,
        sessionType,
        ovenSizeDeviceIndex: withOutDosage
          ? SessionovenSize.DRY_HERB_100_FULL
          : ovenSizeDeviceIndex,
        smartPathIndex,
      },
    });
    let timerStartTime = moment().format("YYYY-MM-DD hh:mm:ss");
    this.setState({ timerStartTime });
  };

  handleCloseSession = () => {
    $("body").removeClass("modal-open");
    $("body").css("padding-right", "0px");
    if (this.state.status === SessionStatus.Active) {
      return false;
    }
    this.setState({
      strainName: "",
      strainType: 0,
      targetTHC: 0,
      THC: "0.00",
      targetCBD: 0,
      CBD: "0.00",
      ovenSize: "",
      totalCBDConsumed: 0,
      totalTHCConsumed: 0,
      sessionType: 0,
      startTime: "00:00",
      endTime: "00:00",
      status: SessionStatus.InActive,
      rating: SessionRating.Good,
      amountLoaded: 0,
      ovenSizeDeviceIndex: 0,
      smartPathIndex: -1,
      timeElapse: "08:00",
      duplicatetimeElapse: "08:00",
    });
  };

  startSessionWithOutDosage = (smartPathMode) => {
    let { currentDeviceConnectd } = this.props;
    if (!currentDeviceConnectd) {
      toast.error(t("device_not_connected"));
      return false;
    }
    this.setState(
      {
        withOutDosage: true,
      },
      () => {
        this.handleSessionBegins(null, smartPathMode);
      }
    );
  };

  handleCancelSession = async () => {
    $("body").removeClass("modal-open");
    $("body").css("padding-right", "0px");
    this.setState({
      strainName: "",
      strainType: 0,
      targetTHC: 0,
      THC: "0.00",
      targetCBD: 0,
      CBD: "0.00",
      ovenSize: "",
      sessionType: 0,
      totalCBDConsumed: 0,
      totalTHCConsumed: 0,
      startTime: "00:00",
      endTime: "00:00",
      status: SessionStatus.InActive,
      rating: SessionRating.Good,
      withOutDosage: false,
      amountLoaded: 0,
      ovenSizeDeviceIndex: 0,
      smartPathIndex: -1,
      timeElapse: "08:00",
      duplicatetimeElapse: "08:00",
      graphData: [
        { x: 8.0, y: 0 },
        { x: 7.0, y: 0 },
        { x: 6.0, y: 0 },
        { x: 5.0, y: 0 },
        { x: 4.0, y: 0 },
        { x: 3.0, y: 0 },
        { x: 2.0, y: 0 },
        { x: 1.0, y: 0 },
        { x: 0.0, y: 0 },
      ],
      smartPathData: [],
      optionsMixedChart: {
        ...this.state.optionsMixedChart,
        series: [
          {
            type: "line",
            data: [
              { x: 8.0, y: 0 },
              { x: 7.0, y: 0 },
              { x: 6.0, y: 0 },
              { x: 5.0, y: 0 },
              { x: 4.0, y: 0 },
              { x: 3.0, y: 0 },
              { x: 2.0, y: 0 },
              { x: 1.0, y: 0 },
              { x: 0.0, y: 0 },
            ],
          },
        ],
      },
    });

    store.dispatch({
      type: CLEAR_SESSION_DATA,
    });
  };

  handleSaveSession = async () => {
    $("body").removeClass("modal-open");
    $("body").css("padding-right", "0px");
    const { currentDeviceState, currentUser } = this.props;
    let {
      strainName,
      strainType,
      targetTHC,
      THC,
      targetCBD,
      CBD,
      ovenSize,
      startTime,
      status,
      endTime,
      rating,
      amountLoaded,
      sessionType,
      ovenSizeDeviceIndex,
      smartPathIndex,
      graphData,
      totalTHCConsumed,
      totalCBDConsumed,
    } = this.state;
    if (!startTime || !endTime || !status) {
      if (!this.state.withOutDosage) {
        toast.error(t("form_fields_are_required"));
        return false;
      }
    }

    if (!strainName) {
      strainName = "Unknown";
    }
    let averageTemp = 0;

    graphData.map((obj) => {
      averageTemp = averageTemp + obj.y;
    });

    averageTemp = parseInt((averageTemp / graphData.length).toFixed(0));

    averageTemp = currentDeviceState.displayModeCelcius
      ? averageTemp
      : FToC(averageTemp);

    await setSessionData({
      strainName,
      strainType,
      targetTHC,
      THC,
      targetCBD,
      CBD,
      ovenSize,
      startTime,
      endTime,
      status,
      date: moment().format("MM/DD/YY"),
      rating,
      amountLoaded,
      sessionType,
      ovenSizeDeviceIndex,
      smartPathIndex,
      averageTemp,
      user_id: currentUser.id,
      totalTHCConsumed,
      totalCBDConsumed,
    });
    this.setState({
      strainName: "",
      strainType: 0,
      targetTHC: 0,
      THC: "0.00",
      targetCBD: 0,
      CBD: "0.00",
      ovenSize: "",
      sessionType: 0,
      startTime: "00:00",
      withOutDosage: false,
      endTime: "00:00",
      status: SessionStatus.InActive,
      rating: SessionRating.Good,
      amountLoaded: 0,
      ovenSizeDeviceIndex: 0,
      totalCBDConsumed: 0,
      totalTHCConsumed: 0,
      smartPathIndex: -1,
      timeElapse: "08:00",
      duplicatetimeElapse: "08:00",
      graphData: [
        { x: 8.0, y: 0 },
        { x: 7.0, y: 0 },
        { x: 6.0, y: 0 },
        { x: 5.0, y: 0 },
        { x: 4.0, y: 0 },
        { x: 3.0, y: 0 },
        { x: 2.0, y: 0 },
        { x: 1.0, y: 0 },
        { x: 0.0, y: 0 },
      ],
      smartPathData: [],
      optionsMixedChart: {
        ...this.state.optionsMixedChart,
        series: [
          {
            type: "line",
            data: [
              { x: 8.0, y: 0 },
              { x: 7.0, y: 0 },
              { x: 6.0, y: 0 },
              { x: 5.0, y: 0 },
              { x: 4.0, y: 0 },
              { x: 3.0, y: 0 },
              { x: 2.0, y: 0 },
              { x: 1.0, y: 0 },
              { x: 0.0, y: 0 },
            ],
          },
        ],
      },
    });

    store.dispatch({
      type: CLEAR_SESSION_DATA,
    });
  };

  handleSessionTermination = async (showCompletedModal) => {
    const {
      strainName,
      strainType,
      targetTHC,
      THC,
      targetCBD,
      CBD,
      ovenSize,
      startTime,
      status,
    } = this.state;

    if (this.state.timeIntervalId) {
      clearInterval(this.state.timeIntervalId);
    }

    // if (!strainName || !strainType || !ovenSize || !startTime || !status) {
    // if (!startTime) {
    //   if (!this.state.withOutDosage) {
    //     toast.error("Form fields are required");
    //     return false;
    //   }
    // }

    this.setState(
      {
        endTime: moment().format("HH:mm"),
        status: SessionStatus.Completed,
        timeIntervalId: null,
      },
      () => {
        if (showCompletedModal) {
          if (window.innerWidth <= 680) {
            $("#session-mobile-stats").modal().show();
          } else {
            $("#session-desktop-stats").modal().show();
          }
        }
      }
    );

    SwitchState(0x01);
  };

  handleOvenSizeChange = (e) => {
    this.setState({
      ovenSize: e.target.value,
      ovenSizeDeviceIndex: getKeyByValue(SessionOvenSizeLabel, e.target.value),
    });
  };

  handleStrainTypeChange = (e) => {
    this.setState({ strainType: parseInt(e.target.value) });
  };

  handleAmountLoadedChange = (e) => {
    this.setState({
      amountLoaded: parseInt(e.target.value),
      sessionType: parseInt(e.target.value),
    });
  };

  handleChange = (evt) => {
    if (
      ["targetTHC", "targetCBD", "CBD", "THC"].includes(evt.target.name) &&
      (parseInt(evt.target.value) > 100 || parseInt(evt.target.value) < 0)
    ) {
      return false;
    }
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleDosageData = (name, value) => {
    this.setState({ [name]: value });
  };

  handleStrainNameChange = (evt) => {
    const sessionData = getSessionData();
    const sessionDataKeys = Object.keys(sessionData);
    let sessionObject = {};
    sessionDataKeys.map((key) => {
      sessionData[key].map((item) => {
        if (item.strainName === evt.target.value) {
          sessionObject = item;
        }
      });
    });
    if (evt.target.value == "") {
      this.setState({
        strainName: "",
        strainType: 0,
        targetTHC: 0,
        THC: "0.00",
        targetCBD: 0,
        CBD: "0.00",
        ovenSize: "",
        sessionType: 0,
        totalCBDConsumed: 0,
        totalTHCConsumed: 0,
        startTime: "00:00",
        endTime: "00:00",
        status: SessionStatus.InActive,
        rating: SessionRating.Good,
        amountLoaded: 0,
        ovenSizeDeviceIndex: 0,
      });
    } else {
      this.setState({ ...sessionObject });
    }
  };

  handleRatingChange = (value) => {
    this.setState({ rating: value });
  };

  SetSmartPathList = (id) => {
    this.setState({ showSmartPathList: true, smartPathModalId: id });
  };

  SetIQSmartPathList = (id) => {
    this.setState({ showIQSmartPathList: true, smartPathModalId: id });
  };

  handleSmartPathSelection = async (index) => {
    let { currentDeviceSmartPathList, currentDeviceState } = this.props;
    this.setState({ smartPathIndex: index });
    if (this.state.smartPathModalId) {
      $(`#${this.state.smartPathModalId}`).modal().hide();
      $("body").removeClass("modal-open");
      $("body").css("padding-right", "0px");
      $(".modal-backdrop").remove();
    }
    if (index !== -1) {
      await StartSmartPathMode(index);
      if (!this.state.timeIntervalId) {
        this.startSessionWithOutDosage(true);
      }

      if (
        currentDeviceSmartPathList[index] &&
        currentDeviceSmartPathList[index].points
      ) {
        let data = currentDeviceSmartPathList[index].points.map((obj) => {
          return {
            x: parseInt((obj.time / 60).toFixed(2)),
            y: parseInt(
              currentDeviceState.displayModeCelcius
                ? obj.temp || 0
                : CToF(obj.temp || 0)
            ),
          };
        });
        data = data.filter((obj, i) => {
          return i <= currentDeviceSmartPathList[index].numberOfPoints - 1;
        });
        this.setState({
          smartPathData: data,
          optionsMixedChart: {
            ...this.state.optionsMixedChart,
            series: [
              {
                type: "line",
                data: this.state.graphData,
              },
              {
                data: data,
                type: "line",
                name: "smarth-path",
                enableMouseTracking: false,
                marker: {
                  enabled: false,
                },
              },
            ],
          },
        });
      }
    }
  };

  getRemainingTimeString() {
    const { currentDeviceState } = this.props;
    if (!currentDeviceState || !currentDeviceState.time) {
      return "-";
    }
    return getTimeFormatString(
      (this.getMaxSessionDurationInMinutes() * 60 * 1000 -
        currentDeviceState.time) /
      1000
    );
  }

  // getCurrentTemperatureString() {
  //   const { currentDeviceState } = this.props;
  //   if (!currentDeviceState || !currentDeviceState.currentTemp) {
  //     return "-";
  //   }

  //   let temp = currentDeviceState.currentTemp / 100;
  //   if (!currentDeviceState.displayModeCelcius) {
  //     temp = CToF(temp);
  //     temp = temp.toFixed(1);
  //     temp += " " + (currentDeviceState.displayModeCelcius ? "˚C" : "˚F");
  //   }
  //   return temp;
  // }

  getCurrentTemperatureString() {
    const { currentDeviceState } = this.props;
    if (!currentDeviceState || !currentDeviceState.currentTemp) {
      return "-";
    }
    return currentDeviceState.displayModeCelcius
      ? `${currentDeviceState.currentTemp / 100} ˚C`
      : `${CToF(currentDeviceState.currentTemp / 100)} ˚F`;
  }

  getTargetTemperatureString() {
    const { currentDeviceState } = this.props;
    if (!currentDeviceState || !currentDeviceState.targetTemp) {
      return "-";
    }
    return currentDeviceState.displayModeCelcius
      ? `${currentDeviceState.targetTemp / 100} ˚C`
      : `${CToF(currentDeviceState.targetTemp / 100)} ˚F`;
  }

  getMaxSessionDurationInMinutes() {
    return this.state.isIQ2 ? 8 : 11;
  }

  handleSessionOptions = () => {
    if (this.state.timeIntervalId) {
      this.setState({ pause: !this.state.pause });
    }
  };

  getUpdatedGraphData() {
    let { currentDeviceState } = this.props;

    if (currentDeviceState?.state && (currentDeviceState?.state !== DeviceState.OFF && currentDeviceState?.state !== DeviceState.CHARGE)) {
      if (DEVICE_STATE.SMART_PATH == currentDeviceState.state) {
        return { ...this.state.optionsMixedChart };
      }
      return { ...this.state.preciseModeChart }
    }
    return {
      ...makeInitalGraphData(),
      series: [{
        name: 'Current Temperature',
        data: []
      }, {
        name: 'Target Temperature',
        data: []
      }],
    }
  }

  getLastPuffTHCConsumption() {
    const { currentSessionState } = this.props;
    if (currentSessionState.sessionInProgress) {
      return this.state?.puffStateDataView?.lastPuffThcConsumed || 0;
    }
    return "-";
  }

  getLastPuffCBDConsumption() {
    const { currentSessionState } = this.props;
    if (currentSessionState.sessionInProgress) {
      return this.state?.puffStateDataView?.lastPuffCBdConsumed || 0;
    }
    return "-";
  }

  makeGraphForPreciseMode() {
    setInterval(() => {
      let currentTemp = this.getCurrentTemperatureString()
      let targetTemp = this.getTargetTemperatureString()

      currentTemp = parseInt(currentTemp?.substring(0, currentTemp.length - 3))
      targetTemp = parseInt(targetTemp?.substring(0, targetTemp.length - 3))
      let currentTemperatureArray = this.state.preciseModeChart.series[0].data
      let targetTemperatureArray = this.state.preciseModeChart.series[1].data

      currentTemperatureArray.push(currentTemp)
      targetTemperatureArray.push(targetTemp)

      const { currentDeviceState } = this.props
      if (currentDeviceState?.state && (currentDeviceState?.state !== DeviceState.OFF && currentDeviceState?.state !== DeviceState.CHARGE)) {
        console.log("setting graph data cause current device state is: ", currentDeviceState?.state)
        this.setState({
          preciseModeChart: {
            ...this.state.preciseModeChart,
            series: [{
              name: 'Current Temperature',
              data: truncateArray(currentTemperatureArray, 12)
            }, {
              name: 'Target Temperature',
              data: truncateArray(targetTemperatureArray, 12)
            }],
          }
        })
      }
      else {
        console.log("Truncating graph data cause current device state is: ", currentDeviceState?.state)
        this.setState({
          preciseModeChart: {
            ...this.state.preciseModeChart,
            series: [{
              name: 'Current Temperature',
              data: []
            }, {
              name: 'Target Temperature',
              data: []
            }],
          }
        })
      }
    }, 1000)
  }

  render() {
    const { currentDeviceState, currentSessionState, t } = this.props;

    return (
      <>
        <div className="row m-0 d-flex flex-column flex-lg-row justify-content-around justify-content-md-center">
          <div className="col-12 col-lg-8">
            <div id="chart" style={{ marginTop: 15 }}>
              {/* <Chart
                options={this.state.optionsMixedChart}
                series={this.state.seriesMixedChart}
                type="line"
                height={350}
              /> */}
              <HighchartsReact
                highcharts={Highcharts}
                // constructorType={"chart"}
                //  callback={this.afterChartCreated}
                // options={this.state.optionsMixedChart}
                // options={{
                //   ...this.state.preciseModeChart
                // }}
                options={this.getUpdatedGraphData()}
              />
            </div>
            {this.state.showPuffStat && this.state.isIQ2 && (
              <div className="draw-notif">
                {this.state.showLastDraw && (
                  <p style={{ color: "white" }}>Last Draw</p>
                )}
                <div className="d-flex justify-content-between">
                  <p style={{ color: "white" }}>Duration</p>
                  <p style={{ color: "#E58260" }}>
                    {this.state?.puffStateDataView?.newPuffDuration || 0} s
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p style={{ color: "white" }}>THC</p>
                  <p style={{ color: "#E58260" }}>
                    {" "}
                    {this.getLastPuffTHCConsumption()} mg
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p style={{ color: "white" }}>CBD</p>
                  <p style={{ color: "#E58260" }}>
                    {" "}
                    {this.getLastPuffCBDConsumption()} mg
                  </p>
                </div>
              </div>
            )}
            {this.state.isIQ2 && (
              <div className="d-flex flex-column-reverse flex-lg-column pt-3 pt-md-5 session-progress">
                <p className="d-flex justify-content-end m-0">
                  {t("consumedTHC")}:{" "}
                  {currentSessionState.lastPuffTotalThcConsumed || 0} mg
                </p>
                <div className="d-flex flex-column-reverse flex-md-column flex-lg-row  align-items-start align-items-lg-center">
                  <label htmlFor="file">
                    {t("targetTHC")}: {this.state.targetTHC || 0} mg
                  </label>
                  <progress
                    className="w-100"
                    id="file"
                    value={currentSessionState.lastPuffTotalThcConsumed || 0}
                    max="100"
                  >
                    {" "}
                  </progress>
                </div>
              </div>
            )}
            {this.state.isIQ2 && (
              <div className="d-flex flex-column-reverse flex-lg-column pt-2 session-progress">
                <p className="d-flex justify-content-end m-0">
                  {t("consumedCBD")}:{" "}
                  {currentSessionState.lastPuffTotalCBdConsumed || 0} mg
                </p>
                <div className="d-flex flex-column-reverse flex-md-column flex-lg-row align-items-start  align-items-lg-center">
                  <label htmlFor="file">
                    {t("targetCBD")}: {this.state.targetCBD || 0} mg
                  </label>
                  <div className="w-100">
                    <progress
                      className="w-100"
                      id="file"
                      value={currentSessionState.lastPuffTotalCBdConsumed || 0}
                      max="100"
                    >
                      {" "}
                    </progress>
                    <div className="d-flex justify-content-between">
                      <span>0mg</span>
                      <span>10</span>
                      <span>20</span>
                      <span>30</span>
                      <span>40</span>
                      <span>50</span>
                      <span>60</span>
                      <span>70</span>
                      <span>80</span>
                      <span>90</span>
                      <span>100</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.isIQ2 && (
              <div className="d-flex flex-lg-row align-items-center align-items-md-start justify-content-center justify-content-md-between py-md-5 py-3 setup-btn-wrap-hide-mob">
                {(!this.state.withOutDosage ||
                  SessionStatus.Active != this.state.status) && (
                    <div className="pb-0 pb-lg-0">
                      <SessionSetup
                        onHandleChange={this.handleChange}
                        onhandleDosageData={this.handleDosageData}
                        onHandleOvenSizeChange={this.handleOvenSizeChange}
                        onHandleStrainTypeChange={this.handleStrainTypeChange}
                        onHandleAmountLoadedChange={this.handleAmountLoadedChange}
                        onHandleSessionBegins={this.handleSessionBegins}
                        onHandleSessionTerminates={this.handleSessionTermination}
                        onHandleStrainNameChange={this.handleStrainNameChange}
                        onHandleSessionClose={this.handleCloseSession}
                        session={this.state}
                        id="session-desktop-view"
                        modalStyle="modal-lg"
                      />
                    </div>
                  )}
                <div>
                  {/* <button
                  onClick={(this, this.handleSessionOptions)}
                  className="btn btn-primary"
                >
                  {this.state.pause ? "Resume" : "Pause"}
                </button> */}
                  {this.state.status === SessionStatus.InActive && (
                    <button
                      onClick={this.startSessionWithOutDosage}
                      className="btn btn-dark ml-2 ml-md-3"
                    >
                      {t("begin")}
                    </button>
                  )}
                  <SessionStats
                    id="session-desktop-stats"
                    session={this.state}
                    onHandleRatingChange={this.handleRatingChange}
                    onSaveSession={this.handleSaveSession}
                    onCancelSession={this.handleCancelSession}
                    onHandleSessionTermination={this.handleSessionTermination}
                    currentSessionState={currentSessionState}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className={`d-flex justify-content-center justify-content-md-start col-12 col-lg-4 ${!this.state.isIQ2 ? "mobile-none" : ""
              }`}
          >
            <div className="session-progress__detail pt-0 pt-md-5 mt-md-5 pl-3">
              <div className="wrap">
                <h4>{t("device_state")}</h4>
                <h2 className="d-block">
                  {currentDeviceState.state !== undefined &&
                    currentDeviceState.state === DeviceState.SMART_PATH &&
                    currentDeviceState.smartPathIndex !== -1 &&
                    t(
                      this.props.currentDeviceSmarthPathTitle[
                      currentDeviceState.smartPathIndex
                      ]
                    )}

                  {currentDeviceState.state !== undefined &&
                    currentDeviceState.state !== DeviceState.SMART_PATH
                    ? t(DeviceStateLabel[currentDeviceState.state])
                    : ""}

                  {currentDeviceState.state === undefined && "N/A"}
                </h2>
              </div>
              {this.state.isIQ2 && (
                <>
                  <div className="wrap">
                    <h4>{t("timeRemaining")}</h4>
                    <h2 className="d-block">{this.getRemainingTimeString()}</h2>
                  </div>
                  <div className="wrap">
                    <h4>{t("ovenSize")}</h4>
                    <h2 className="d-block">
                      {this.state.ovenSizeDeviceIndex ||
                        currentSessionState.sessionOven_Size
                        ? SessionOvenSizePercentage[
                        parseInt(
                          this.state.ovenSizeDeviceIndex ||
                          currentSessionState.sessionOven_Size
                        )
                        ]
                        : "-"}
                    </h2>
                  </div>
                </>
              )}
              <div className="wrap">
                <h4>{t("current_temperature")} </h4>
                <h2 className="d-block">
                  {this.getCurrentTemperatureString()}
                </h2>
              </div>
              <div className="d-flex justify-content-between align-items-center session-progress__counter">
                <div>
                  <h4>{t("target_temperature")}</h4>
                  <h2 className="d-block">
                    {this.getTargetTemperatureString()}
                  </h2>
                </div>
                <div className="d-flex flex-column ml-3">
                  <button
                    onClick={this.IncrementItem}
                    className="button-count up"
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                  </button>
                  <button
                    onClick={this.DecreaseItem}
                    className="button-count down"
                  >
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                </div>
              </div>
              <button
                onClick={() => {
                  this.SetSmartPathList("smartPathList");
                }}
                className="btn btn-dark mt-5 select-smart-btn"
                data-toggle="modal"
                data-target="#smartPathList"
              >
                {t("select_smart_path")}
              </button>
              {this.state.isIQ2 && (
                <div className="d-flex flex-md-column flex-lg-row align-items-center align-items-md-start justify-content-center justify-content-md-between py-md-5 py-3 setup-btn-wrap-hide-large">
                  {(!this.state.withOutDosage ||
                    SessionStatus.Active != this.state.status) && (
                      <div className="pb-0 pb-lg-0">
                        <SessionSetup
                          onHandleChange={this.handleChange}
                          onhandleDosageData={this.handleDosageData}
                          onHandleOvenSizeChange={this.handleOvenSizeChange}
                          onHandleStrainTypeChange={this.handleStrainTypeChange}
                          onHandleAmountLoadedChange={
                            this.handleAmountLoadedChange
                          }
                          onHandleSessionBegins={this.handleSessionBegins}
                          onHandleSessionTerminates={
                            this.handleSessionTermination
                          }
                          onHandleStrainNameChange={this.handleStrainNameChange}
                          onHandleSessionClose={this.handleCloseSession}
                          session={this.state}
                          id="session-mobile-view"
                        />
                      </div>
                    )}
                  <div>
                    {/* <button
                  onClick={(this, this.handleSessionOptions)}
                  className="btn btn-primary"
                >
                  {this.state.pause ? "Resume" : "Pause"}
                </button> */}
                    {this.state.status === SessionStatus.InActive && (
                      <button
                        onClick={this.startSessionWithOutDosage}
                        className="btn btn-dark ml-2 ml-md-3"
                      >
                        {t("begin")}
                      </button>
                    )}
                    <SessionStats
                      session={this.state}
                      onHandleRatingChange={this.handleRatingChange}
                      onSaveSession={this.handleSaveSession}
                      onCancelSession={this.handleCancelSession}
                      onHandleSessionTermination={this.handleSessionTermination}
                      id="session-mobile-stats"
                      currentSessionState={currentSessionState}
                    />
                  </div>
                </div>
              )}
              {this.state.showSmartPathList && (
                <SmartPathList
                  id={this.state.smartPathModalId}
                  selectedIndex={this.state.smartPathIndex}
                  onSelectSmartPath={this.handleSmartPathSelection}
                />
              )}
            </div>
          </div>
          {!this.state.isIQ2 && (
            <div className="iq-two-mob-session-progress-detail">
              <div className="wrap d-flex align-items-center">
                <div className="minus-btn-wrap temp-controllers">
                  <button
                    className="btn shadow-none"
                    onClick={this.DecreaseItem}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                </div>
                <div className="temp-control w-100">
                  <div className="temp">
                    {t("temp")}{" "}
                    <span>
                      {currentDeviceState.displayModeCelcius
                        ? (currentDeviceState.currentTemp || 0) / 100
                        : CToF((currentDeviceState.currentTemp || 0) / 100) ||
                        0}{" "}
                      {currentDeviceState.displayModeCelcius ? "˚C" : "˚F"}
                    </span>
                  </div>
                  <div className="target">
                    {t("target")}{" "}
                    <span>
                      {" "}
                      {currentDeviceState.displayModeCelcius
                        ? (currentDeviceState.targetTemp || 0) / 100
                        : CToF((currentDeviceState.targetTemp || 0) / 100) ||
                        "N/A"}{" "}
                      {currentDeviceState.displayModeCelcius ? "˚C" : "˚F"}
                    </span>
                  </div>
                  <div className="standby">
                    {currentDeviceState.state !== undefined
                      ? DeviceStateLabel[currentDeviceState.state]
                      : "N/A"}

                    {currentDeviceState.state !== undefined &&
                      currentDeviceState.state === DeviceState.SMART_PATH &&
                      currentDeviceState.smartPathIndex !== -1 &&
                      ` ${currentDeviceState.smartPathIndex + 1}`}
                  </div>
                </div>
                <div className="plus-btn-wrap temp-controllers">
                  <button
                    className="btn shadow-none"
                    onClick={this.IncrementItem}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
              <div className="smart-path-wrap">
                <button
                  className="btn btn-dark w-100"
                  onClick={() => {
                    this.SetIQSmartPathList("iq-smartPathList");
                  }}
                  data-toggle="modal"
                  data-target="#iq-smartPathList"
                >
                  {/* <FontAwesomeIcon icon={faAngleUp} /> */}

                  {t("select_smart_path")}
                </button>
              </div>
            </div>
          )}
          {this.state.showIQSmartPathList && (
            <SmartPathList
              id={this.state.smartPathModalId}
              selectedIndex={this.state.smartPathIndex}
              onSelectSmartPath={this.handleSmartPathSelection}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentDeviceState: state.device.currentDeviceState,
  currentSessionState: state.device.currentSessionState,
  currentSessionData: state.device.currentSessionData,
  currentDeviceSmartPathList: state.device.currentDeviceSmartPathList,
  currentDeviceSmarthPathTitle: state.device.currentDeviceSmarthPathTitle,
  currentDeviceConnectd: state.device.currentDeviceConnectd,
  currentUser: state.userAuth.user,
  smarthPathIndex: state.device.smarthPathIndex,
  currentDeviceInformation: state.device.currentDeviceInformation,
  currentDevice: state.device.currentDevice,
});

// export default connect(mapStateToProps, null)(SessionScreen);
export default connect(mapStateToProps, null)(withTranslation()(SessionScreen));

// 00 00 e001 009d 009d 8caf ffc3 ffc3 0000 0000 0000 0000 0000 05 00
// 01 00 e001 00bc f0c7 ffc7 0000 0000 0000 0000 0000 0000 0000 03 00
// 02 00 e001 00c7 f0d2 ffd2 0000 0000 0000 0000 0000 0000 0000 03 00
// 03 00 e001 00d2 f0dd ffdd 0000 0000 0000 0000 0000 0000 0000 03 00
