import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import * as moment from "moment";
// import moment from "moment";

import "moment/locale/es";
import "moment/locale/fr";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../components/Sidebar/Sidebar";
import { store } from "../../store/index";
import "./Dashboard.scss";

import { DeviceHelper } from "../../helpers/device";
import { getLocale } from "../../helpers/helpers";
import {
  Account,
  Devices,
  Home,
  Information,
  Notification,
  PlantGuide,
  Session,
  setting,
  Shop,
  SmartPath,
  Study,
} from "../../screens/index";
import { SET_CURRENT_DEVICE } from "../../store/actions";
class Dashboard extends Component {
  state = {
    currentDeviceData: {},
    isConnecting: false,
    disconnect: false,
  };

  componentDidMount() {
    let { currentDevice } = this.props;
    moment.locale(getLocale());

    if (Object.keys(currentDevice).length) {
      this.setState({ currentDeviceData: currentDevice });
    }
  }

  componentDidUpdate(prevProps) {
    let { currentDevice, disconnect } = this.props;
    if (prevProps.currentDevice !== currentDevice) {
      if (Object.keys(currentDevice).length) {
        this.setState({ currentDeviceData: currentDevice });
      } else {
        this.setState({ currentDeviceData: {} });
      }
    }
    if (prevProps.disconnect !== disconnect) {
      this.setState({ disconnect: disconnect });
    }
  }

  connectDevice = async () => {
    let { currentDeviceData } = this.state;

    try {
      this.setState({
        isConnecting: true,
      });
      await DeviceHelper.EstablishDeviceConnection();
      toast.success(t("device_connected"));
      store.dispatch({
        type: SET_CURRENT_DEVICE,
        payload: { ...currentDeviceData },
      });
      this.setState({
        isConnecting: false,
      });
    } catch (e) {
      toast.error(t("device_can_not_connected"));
      this.setState({
        isConnecting: false,
      });
    }
  };

  render() {
    const { isAuthenticated, t } = this.props;

    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }

    return (
      <main>
        {this.state.disconnect && this.props.deviceList.length > 0 && (
          <div className="cst-overlay text-center position-fixed d-flex flex-column align-content-center justify-content-center w-100 h-100">
            <span
              onClick={() => this.setState({ disconnect: false })}
              className="cursor-hand close-btn"
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <div className="wrap px-3">
              <h2 className="text-uppercase text-white font-weight-normal">
                {t("lost_connection")}
              </h2>
              <p className="text-white">
                {t("daVinci_vaporizer_has_lost_the_connection_to_the_device")}
              </p>
              <button
                onClick={this.connectDevice}
                className="continue-btn btn shadow-none"
              >
                {this.state.isConnecting ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  t("connect")
                )}
              </button>
            </div>
          </div>
        )}
        <Sidebar />
        {/* <div className="main-content banner-primary"> */}
        <Switch>
          <Route exact path="/home" name="homescreen" component={Home} />
          <Route exact path="/session" name="session" component={Session} />
          <Route exact path="/setting" name="setting" component={setting} />
          <Route exact path="/account" name="account" component={Account} />
          <Route
            exact
            path="/info-support"
            name="information"
            component={Information}
          />
          <Route exact path="/device" name="device" component={Devices} />
          <Route
            exact
            path="/smart-path"
            name="smartpath"
            component={SmartPath}
          />
          <Route
            exact
            path="/plant-guide"
            name="plantguide"
            component={PlantGuide}
          />
          <Route exact path="/shop" name="shop" component={Shop} />
          <Route exact path="/study" name="study" component={Study} />
          <Route
            exact
            path="/notification"
            name="notification"
            component={Notification}
          />

          <Redirect to="/home" />
        </Switch>
        {/* </div> */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.userAuth.isAuthenticated,
  disconnect: state.device.disconnect,
  currentDevice: state.device.currentDevice,
  deviceList: state.device.list,
});

export default connect(mapStateToProps)(withTranslation()(Dashboard));
