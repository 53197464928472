const SESSION_OVEN_SIZE_LABEL = {
  0: "Select",
  8: "Dry Herb - 100% Full - .5g",
  7: "Dry Herb - 80% Full - .4g",
  6: "Dry Herb - 60% Full - .3g (or small DaVinci spacer inserted)",
  5: "Dry Herb - 40% Full - .2g (DaVinci Dosage Pod or large spacer inserted)",
  4: "Extract .100g - 2 Grains of Rice",
  3: "Extract .075g - 1.5 Grains of Rice",
  2: "Extract .050g - 1 Grain of Rice",
  1: "Extract .025g - 0.5 Grain of Rice",
};

export default SESSION_OVEN_SIZE_LABEL;
