import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import BlinkingDotText from '../../components/BlinkingDotText'
import * as moment from "moment";
import Config from "../../Config";
import useQuery from "../../hooks/useQuery";
import {fetchUtil} from "../../helpers/fetchUtil";
import {EDIT_DEVICE_SUCCESS} from "../../store/actions";

const ShopPage = () => {

    const user = useSelector((state) => state.userAuth?.user);
    const [isRedirected, setIsRedirected] = useState(false)
    const [multipassUrl, setMultipassUrl] = useState(user?.multipassUrl)
    const [multipassUrlExpiry, setMultipassUrlExpiry] = useState(user?.multipassUrlExpiry)
    const [clientIp, setClientIp] = useState(null)

    const futureTime = moment(multipassUrlExpiry);
    const currentTime = moment();
    const getIpfy = () => {
            fetch('https://api.ipify.org/?format=json')
              .then(response => response.json())
              .then(data => {
                console.log('SHop Your public IP address is: ' + data.ip);
                  setClientIp(data.ip);
              })
              .catch(error => {
                  console.log("Tracking IP error", error)
              });

        };

    useEffect(() => {
        if (isRedirected) return
        getIpfy();

        if (futureTime.isBefore(currentTime)) {
            fetchUtil({
                url: `/api/DaVinciUsers/regenerateMultipass`,
                method: "POST",
                body: JSON.stringify({email: user?.email, region: user?.region, ip: clientIp}),
                token: user?.token,
                externalApi: true,
            })
                .then((response) => {
                    setMultipassUrl(response.multipassUrl)
                    setMultipassUrlExpiry(response.multipassUrlExpiry)
                    window.open(response.multipassUrl, "_blank")
                    setIsRedirected(true)
                })
                .catch((error) => {
                    console.log("Multipass catch ", error)
                });
        } else {
            setTimeout(() => {
                window.open(multipassUrl, "_blank")
                setIsRedirected(true)
            }, 1000)
        }


    }, [])

    return (
        <div
            className="info"
            style={{
                backgroundColor: "transparent",
                textAlign: "center",
                display: "flex",
                height: "calc(100vh - 120px)",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <BlinkingDotText
                text={"Redirecting to our store"}
                isRedirected={isRedirected}
                renderPostRedirection={() => (
                    <p style={{color: "#fff"}}>
                        {" "}
                        <a
                            href={multipassUrl}
                            target="_blank"
                            style={{color: "#fff"}}
                        >
                            Click here
                        </a>{" "}
                        if you are not redirected.
                    </p>
                )}
            />
        </div>
    )
}

export default ShopPage