import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { logo, selectBg } from "../../assets/images";
import "../../components/Sidebar/Sidebar.scss";
import { Languages } from "../../constants";
import { getLocale } from "../../helpers/helpers";
import translationService from "../../helpers/translation.service";
import "./AgeVerification.scss";

class AgeVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleAgeConfirmation = () => {
    const { history } = this.props;
    localStorage.ageConfirmation = true;
    history.replace("/login");
  };

  render() {
    const { t } = this.props;

    if (localStorage.ageConfirmation) {
      return <Redirect to="/login" />;
    }
    return (
      <>
        <div className="d-flex justify-content-center align-items-center  age-verify banner-primary">
          <div className="age-verify__content ">
            <img src={logo} />
            <p className="pt-5 mt-5 pb-5">
              {/* By clicking "CONFIRM". You are confirming that you are currently
              21 years of age or older */}
              {t(
                "by_clicking_confirm_you_are_confirming_that_you_are_currently_21_years_of_age_or_older"
              )}
            </p>

            <button
              onClick={this.handleAgeConfirmation}
              className="d-flex btn btn-dark age-verify__content__button"
            >
              {t("confirm")}
            </button>

            <div className="navigation">
              <select
                id="device-list"
                onChange={(e) =>
                  translationService.updateLocale(e?.target?.value)
                }
                className="mt-4 fancy-scroll d-flex btn  age-verify__content__button orange-outlined-btn"
                value={getLocale()}
                style={{
                  background: `url(${selectBg}) no-repeat 95% center #201b23`,
                }}
              >
                {Languages.map((item) => {
                  return (
                    <option
                      key={item.id}
                      className={"highlight-device"}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <InputField
                            type="password"
                            placeholder="PASSWORD"
                            name="password"
                            secure={true}
                            value={this.state.password}
                        /> */}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(AgeVerification);
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withTranslation()(AgeVerification));
