import { store } from "../store";
import { AuthCode } from "../constants";
import { SIGN_OUT } from "../store/actions";
import { toast } from "react-toastify";
// import { Socket } from "../utils/socketUtils";

export const handleFetchError = async (res) => {
  if (
    res.status >= AuthCode.BAD_REQUEST &&
    res.status < AuthCode.CONNECTION_TIMED_OUT
  ) {
    const response = await res.clone().json();
    let errRes = {
      ...response,
      message: response?.error?.message,
      status: res.status,
    };
    if (res.status == AuthCode.UNAUTHORIZED) {
      // Socket.disconnect();
      store.dispatch({ type: SIGN_OUT });
      let ageConfirmation = localStorage.ageConfirmation;
      let sessionData = localStorage.sessionData;
      localStorage.clear();
      if (ageConfirmation) {
         localStorage.ageConfirmation = ageConfirmation;
      }
      if (sessionData) {
         localStorage.sessionData = sessionData;
      }
    }
    toast.error(errRes.message, { toastId: "taskfox-web" });
    throw errRes;
  }
  if (res.status == AuthCode.NO_CONTENT) {
    return true;
  }
  return res?.json();
};
