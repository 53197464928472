import React, { Component } from 'react';
import WelcomeSlider from '../../components/WelcomeSlider/WelcomeSlider';
class Welcome extends Component {
    state = {}
    render() {
        return (
            < div className="banner-primary">
                <WelcomeSlider />

            </div>
        );
    }
}

export default Welcome;