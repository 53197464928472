import React, { useState } from 'react'
import { logo } from "../../assets/images";
import { Link } from "react-router-dom";
import InputField from "../../components/InputField/InputField";
import { useTranslation } from 'react-i18next';
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword';

const ForgotPasswordScreen = () => {

    const { t } = useTranslation()

    const [loginData, setLoginData] = useState({
        email: "",
    })
    const [isLoading, setIsLoading] = useState(false)


    const handleChange = (evt) => {
        setLoginData({ ...loginData, [evt.target.name]: evt.target.value });
    };


    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center  login banner-primary">
                <div className="login__logo ">
                    <img src={logo} />
                </div>
                {/* <div className="login__field pt-5">

                    <InputField
                        type="text"
                        placeholder={"email"}
                        name="email"
                        secure={true}
                        onChange={handleChange}
                        value={loginData?.email}
                    />

                    <button
                        onClick={() => console.log('tes')}
                        className="btn btn-primary mt-4"
                    >
                        {isLoading ? (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            t("resetPassword")
                        )}
                    </button>

                    <Link to="/login" className="d-block text-center pt-3 btn-link">
                        {t("login")}
                    </Link>
                </div> */}
                <ForgotPassword />
            </div>
        </>
    )
}

export default ForgotPasswordScreen