import React from 'react';

const Context = React.createContext({
    _setState: (name, val) => { },
    state: {
        accountScreen: false,
        dashboardScreen: false,
    }
});

export default Context;