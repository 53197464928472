import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import $ from "jquery";
import { truncate } from "lodash";
import * as moment from "moment";
import "moment/locale/es";
import "moment/locale/fr";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { dashboardlogo, selectBg } from "../../assets/images/index";
import { Languages } from "../../constants";
import { DeviceHelper } from "../../helpers/device";
import { getLocale } from "../../helpers/helpers";
import translationService from "../../helpers/translation.service";
import { store } from "../../store";

import {
  CONNECTING,
  CONNECTION_ERROR,
  getDevices,
  SET_CURRENT_DEVICE,
  SIGN_OUT,
} from "../../store/actions";
import NavBlock from "../NavBlock/NavBlock";
import "./Sidebar.scss";
import { REGIONS_LABELS } from "../../utils/constants";
const width = window.innerWidth;

class Sidebar extends Component {
  state = {
    mobileview: width < 1100,
    toggle: true,
    devices: [],
    currentDevice: {},
    user: {}
  };

  toggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };
  toggleClose = () => {
    this.setState({ toggle: true });
  };

  handleLogout = () => {
    store.dispatch({
      type: SIGN_OUT,
    });
    // await DeviceHelper.disconnect();
    let ageConfirmation = localStorage.ageConfirmation;
    let sessionData = localStorage.sessionData;
    localStorage.clear();
    if (ageConfirmation) {
      localStorage.ageConfirmation = ageConfirmation;
    }
    if (sessionData) {
      localStorage.sessionData = sessionData;
    }
  };
  componentDidMount() {
    $(window).resize(() => {
      const width = window.innerWidth;
      const mobileview = width < 1100;
      this.setState({ mobileview: mobileview });
    });

    this.props.getDevices(this.props.user.userId).then((res) => {
      let filterDevices = res.filter((obj) => !obj.forgotten);
      this.setState({
        devices: filterDevices,
      });
      if (filterDevices.length) {
        store.dispatch({
          type: SET_CURRENT_DEVICE,
          payload: filterDevices[0],
        });
      }
    });
  }

  onChangeDevice = async (e) => {
    let deviceId = e.target.value;
    let device = this.state.devices.find((device) => device.address == deviceId);
    if (device) {
      this.setState({ currentDevice: device });
      store.dispatch({
        type: SET_CURRENT_DEVICE,
        payload: device,
      });
    }
    try {
      store.dispatch({
        type: CONNECTING,
      });
      await DeviceHelper.EstablishDeviceConnection();
      toast.success(t("device_connected"));
    } catch (e) {
      store.dispatch({
        type: CONNECTION_ERROR,
      });
      toast.error(t("device_can_not_connected"));
      console.log("Error", e);
    }
  };

  onClickChangeDevice = async (deviceObj) => {
    let device = deviceObj;
    if (device) {
      this.setState({ currentDevice: device });
      store.dispatch({
        type: SET_CURRENT_DEVICE,
        payload: device,
      });
    }
    try {
      store.dispatch({
        type: CONNECTING,
      });
      await DeviceHelper.EstablishDeviceConnection();
      toast.success(t("device_connected"));
    } catch (e) {
      store.dispatch({
        type: CONNECTION_ERROR,
      });
      toast.error(t("device_can_not_connected"));
      console.log("Error", e);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        {this.state.mobileview ? (
          <header className="mobile-header d-flex justify-content-between align-items-center">
            <button onClick={this.toggle} className="toggle-btn">
              <FontAwesomeIcon icon={faBars} />
            </button>

            <div className="dropdown">
              <button
                onClick={this.toggleClose}
                className="btn-secondary dropdown-toggle pr-4"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {this.props.connectingDevice && (
                  <div
                    className="spinner-border device-list-loader-lg spinner-border-sm text-white"
                    role="status"
                  >
                    {/* <span className="sr-only">Loading...</span> */}
                  </div>
                )}
                {/* <img src={pairDevice} alt="Pair Device" className="pair-device-icon" /> */}
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M18.2813 12.0313L11.9687 5.7187C11.4896 5.23964 10.6829 5.36557 10.3726 5.96785L6.75 13L11 17.25L18.0321 13.6274C18.6344 13.3171 18.7604 12.5104 18.2813 12.0313Z"
                  />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M4.75 19.25L8.5 15.5"
                  />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M13.75 7.25L16.25 4.75"
                  />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M16.75 10.25L19.25 7.75"
                  />
                </svg>
              </button>
              <div
                className="dropdown-menu fancy-scroll"
                aria-labelledby="dropdownMenuButton"
              >
                {this.props.devices.map((device) => {
                  device.name = device.name || "Not Available";
                  return (
                    <a
                      key={device.id}
                      onClick={() => this.onClickChangeDevice(device)}
                      className={
                        "dropdown-item" +
                        " " +
                        `${this.props.currentDevice.id === device.id &&
                          this.props.currentDeviceConnectd
                          ? "mobile-highlight-device"
                          : ""
                        }`
                      }
                    >
                      {device.name.slice(0, 13) +
                        "  " +
                        `${this.props.currentDevice.id === device.id &&
                          this.props.currentDeviceConnectd
                          ? "*"
                          : ""
                        }`}{" "}
                    </a>
                  );
                })}
                {!this.props.devices.length && (
                  <a className="dropdown-item">{t("no_device")}</a>
                )}
              </div>
            </div>
          </header>
        ) : null}
        {!this.state.toggle ? (
          <div onClick={this.toggle} className="sidebar-backdrop"></div>
        ) : null}
        <div
          className={this.state.toggle ? "sidebaropen" : "sidebaropen active"}
        >
          <Link to="/home" className="logo-block">
            <div>
              <img src={dashboardlogo} alt="logo" />
            </div>
          </Link>
          <div className="navigation">
            {!this.state.mobileview ? (
              <span className="device-list-wrp">
                {this.props.connectingDevice && (
                  <div
                    className="spinner-border device-list-loader spinner-border-sm text-white"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                <select
                  id="device-list"
                  onChange={(e) => {
                    translationService.updateLocale(e?.target?.value);
                    moment.locale(e?.target?.value);
                  }}
                  className="mt-4 mt-md-0 mb-2 fancy-scroll drop-down-width"
                  value={getLocale()}
                  style={{
                    background: `url(${selectBg}) no-repeat 95% center #201b23`,
                  }}
                >
                  {Languages.map((item) => {
                    return (
                      <option
                        key={item.id}
                        className={"highlight-device"}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <select
                  id="device-list"
                  onChange={this.onChangeDevice}
                  className="mt-4 mt-md-0 mb-2 fancy-scroll drop-down-width"
                  value={this.props.currentDevice.address}
                  style={{
                    background: `url(${selectBg}) no-repeat 95% center #201b23`,
                  }}
                >
                  {this.props.devices.map((device) => {
                    device.name = device.name || "Not Available";
                    return (
                      <option
                        key={device.id}
                        className={`${this.props.currentDevice.id === device.id &&
                          this.props.currentDeviceConnectd
                          ? "highlight-device"
                          : ""
                          }`}
                        value={device.address}
                      >
                        {device.name +
                          "  " +
                          `${this.props.currentDevice.id === device.id &&
                            this.props.currentDeviceConnectd
                            ? "*"
                            : ""
                          }`}{" "}
                      </option>
                    );
                  })}
                  {!this.props.devices.length && (
                    <option value={""}>{t("no_device")}</option>
                  )}
                </select>
              </span>
            ) : null}
            <NavBlock onClick={this.toggle} name={t("home")} link="/home" />
            <NavBlock
              onClick={this.toggle}
              name={
                `${this.props.currentDevice
                  ? truncate(this.props.currentDevice.name, { length: 8 })
                  : "IQ2"
                } ` + t("dashboard")
              }
              link="/session"
            />
            <NavBlock
              onClick={this.toggle}
              name={t("setting")}
              link="/setting"
            />
            <NavBlock
              onClick={this.toggle}
              name={t("my_account")}
              link="/account"
            />
            <NavBlock
              onClick={this.toggle}
              name={t("my_device")}
              link="/device"
            />
            <NavBlock
              onClick={this.toggle}
              name={t("smart_paths")}
              link="/smart-path"
            />
            <NavBlock
              onClick={this.toggle}
              name={t("info_support")}
              link="/info-support"
            />
            <NavBlock onClick={this.toggle} name={t("shop")} link="/shop" />
            {this.props.user.region === REGIONS_LABELS.US && (<NavBlock
              onClick={this.toggle}
              name={t("davinci_study")}
              link="/study"
            />)}
            <NavBlock
              name={t("logout")}
              link="/login"
              onClick={this.handleLogout}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userAuth.user,
  devices: state.device.list,
  currentDevice: state.device.currentDevice,
  currentDeviceConnectd: state.device.currentDeviceConnectd,
  connectingDevice: state.device.connectingDevice,
});

const mapDispatchToProps = (dispatch) => ({
  getDevices: (user_id) => dispatch(getDevices(user_id)),
});

// export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Sidebar));
