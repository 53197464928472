import React, { Component } from "react";
import { withHeader } from "../../hoc";
class Notification extends Component {
  state = {};
  render() {
    return (
      <>
        <h2 className="primary-heading">Notifications</h2>
      </>
    );
  }
}

export default withHeader(Notification);
