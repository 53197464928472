import { t } from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { profile } from "../../assets/images/index";
import EditPassword from "../../components/EditPassword/EditPassword";
import EditProfile from "../../components/EditProfile/EditProfile";
import { withHeader } from "../../hoc";

import "./Account.scss";
class Account extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { user } = this.props;
    return (
      <>
        <h2 className="primary-heading text-center text-md-left">
          {t("my_account")}
        </h2>
        <div className="d-flex flex-column justify-content-center align-items-center pt-5">
          <img className="profile" src={profile} />
          <p className="fancy-text py-4">{user.email}</p>
          {/* <button className="btn btn-primary my-3">edit profile</button> */}

          <EditProfile />

          <EditPassword />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.userAuth.user,
});

// export default connect(mapStateToProps, null)(withHeader(Account));
const enhance = compose(
  withTranslation(),
  withHeader,
  connect(mapStateToProps, null)
);
export default enhance(Account);
