import { fetchUtil } from "../../helpers/fetchUtil";

export const SIGN_OUT = "SIGN_OUT";

export const SET_TOKEN = "SET_TOKEN";

// Login actions
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

// Signup actions
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

// get profile action
export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_ERROR = "ME_ERROR";

// forget password actions
export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";

export const SET_BIG_COMMERCE_URL = "SET_BIG_COMMERCE_URL"

export const login = (email, password) => (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  const body = JSON.stringify({
    email: email,
    password: password,
  });
  return fetchUtil({
    url: `/login`,
    method: "POST",
    body,
  })
    .then(async (res) => {
      dispatch({ type: SET_TOKEN, token: res.id });
      dispatch({ type: LOGIN_SUCCESS, payload: res, token: res.id });
      await dispatch(getMe(res.id, res.userId));
      localStorage.isAuthenticated = true;
      return Promise.resolve(res);
    })
    .catch((err) => {
      //   logger(err);
      dispatch({
        type: LOGIN_FAIL,
        payload: err,
      });
      return Promise.reject(err?.message || "login failed");
    });
};

export const getMe = (token, userId) => (dispatch, getState) => {
  dispatch({ type: ME_REQUEST });
  return fetchUtil({
    url: `/${userId}`,
    token: token || getState().userAuth.user.token,
  })
    .then((res) => {
      dispatch({ type: ME_SUCCESS, payload: res });
      localStorage.isAuthenticated = true;
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: ME_ERROR });
      return Promise.reject(err);
    });
};

export const signup = (email, password) => (dispatch) => {
  dispatch({ type: SIGNUP_REQUEST });
  const body = JSON.stringify({
    email: email,
    password: password,
  });
  return fetchUtil({
    url: `/`,
    method: "POST",
    body,
  })
    .then((res) => {
      dispatch({ type: SIGNUP_SUCCESS, payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      //   logger(err);
      dispatch({
        type: SIGNUP_FAIL,
        payload: err,
      });
      return Promise.reject(err?.message || "signup failed");
    });
};

export const forgotPassword = (email) => (dispatch, getState) => {
  let body = JSON.stringify({
    email,
  });
  dispatch({ type: FORGET_PASSWORD_REQUEST });
  return fetchUtil({
    url: "/reset",
    method: "POST",
    body,
  })
    .then((res) => {
      dispatch({ type: FORGET_PASSWORD_SUCCESS, payload: {} });
      return Promise.resolve({});
    })
    .catch((err) => {
      dispatch({
        type: FORGET_PASSWORD_FAIL,
        payload: err,
      });
      return Promise.reject(err?.message || "Forget password failed");
    });
};

export const editProfile = (id, bodyData) => (dispatch, getState) => {
  const token = getState().userAuth.user.token;
  return fetchUtil({
    url: `/${id}`,
    method: "PUT",
    body: JSON.stringify(bodyData),
    token,
  })
    .then((res) => {
      dispatch({ type: EDIT_PROFILE_SUCCESS, payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const changePassword = (id, bodyData) => (dispatch, getState) => {
  const token = getState().userAuth.user.token;

  return fetchUtil({
    url: `/${id}`,
    method: "PUT",
    body: JSON.stringify(bodyData),
    token,
  })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};


export const getMultipassUrl = () => (dispatch, getState) => {
  const token = getState().userAuth.user.token;

  return fetchUtil({
    url: `/api/DaVinciUsers/regenerateMultipass`,
    method: "POST",
    body: {},
    token,
  })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
