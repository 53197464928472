const SESSION_OVEN_SIZE_PERCENTAGE = {
  0: "0%",
  8: "100%",
  7: "80%",
  6: "60%",
  5: "40%",
  4: "20%",
  3: "15%",
  2: "10%",
  1: "5%",
};

export default SESSION_OVEN_SIZE_PERCENTAGE;
