import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { DeviceHelper } from "../../helpers/device";
import { doGet, doGetBinary } from "../../network/config";
import "./UpdateFirmware.scss";

class UpdateFirmware extends Component {
  FW_CONFIG_URL =
    "https://davincis3.s3.us-west-1.amazonaws.com/config.json";
  FW_BIN_URL =
    "https://s3-us-west-1.amazonaws.com/s3.davincivaporizer.net/mobile-app/dav_iq2_fw.bin";
  constructor(props) {
    super(props);
    this.state = {
      checking: false,
      updating: false,
      succeeded: false,
      availableFirmware: undefined,
      progress: 0,
    };
  }

  checkForFirware = async () => {
    let result = await doGet(this.FW_CONFIG_URL, "", true);
    console.log(result);
    this.setState({
      availableFirmware: result?.data?.latest_fw_version,
    });
  };

  isUpdateable = () => {
    const { availableFirmware } = this.state;
    const {
      currentDevice: { firmwareVersion },
    } = this.props;

    // const availableFirmware = "v_1.0.4.27"
    // const firmwareVersion = "v_1.0.4.26"

    if (!availableFirmware) {
      return false;
    }
    try {
      const current = firmwareVersion
        .substr(firmwareVersion.indexOf("_") + 1)
        .split(".");
      const latest = availableFirmware
        .substr(availableFirmware.indexOf("_") + 1)
        .split(".");

      while (current.length > 0 || latest.length > 0) {
        let c = current.shift();
        let l = latest.shift();

        if (c === undefined && l !== undefined) {
          return true;
        }

        if (c < l) {
          return true;
        }
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  updateFirware = async () => {
    let response = await doGetBinary(this.FW_BIN_URL);
    let fwbin = response.data;

    console.log(this.prepareFirmwarePayload(fwbin));
    console.log(this.props.state);
    this.setState({
      updating: true,
    });
    try {
      await DeviceHelper.updateFirmware(
        this.prepareFirmwarePayload(fwbin),
        (status, progress) => {
          console.log(status, progress);
          this.setState({
            progress: progress,
          });
        }
      );
      this.setState({
        updating: false,
        succeeded: true,
      });
    } catch (e) {
      this.setState({
        updating: false,
      });
      throw e;
    }
  };

  getPayloadDebugStr(data) {
    let dbg = "";
    for (let j = 0; j < data.length; j++) {
      dbg += "[0x" + (data[j] & 0xff) + "]";
    }
    return dbg;
  }

  render() {
    const { isAuthenticated, currentDevice, currentDeviceConnectd, t } =
      this.props;
    const { checking, updating, availableFirmware, succeeded, progress } =
      this.state;

    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <div className="d-flex justify-content-center align-items-center  age-verify banner-primary">
          <div className="">
            <div className="age-verify__content ">
              {(!currentDevice?.id || !currentDeviceConnectd) && (
                <>
                  <p className="pt-5 mt-5 pb-5">{t("noDeviceSelected")}</p>
                  <button className="d-flex btn btn-dark age-verify__content__button">
                    <Link to="/dashboard">{t("dashboard")}</Link>
                  </button>
                </>
              )}
              {
                <>
                  <p className="pt-5 mt-5 pb-5">
                    {t("device")}: {currentDevice.model}
                    <br />
                    {t("currentFirmware")}: {currentDevice.firmwareVersion}
                    <br />
                    {t("availableFirmware")}:{" "}
                    {!checking && !updating
                      ? availableFirmware || "-"
                      : checking
                        ? "Checking"
                        : updating
                          ? `Updating to ${availableFirmware}`
                          : `N/A`}
                  </p>
                  {!availableFirmware && (
                    <button
                      onClick={this.checkForFirware}
                      className="d-flex btn btn-dark age-verify__content__button"
                    >
                      {t("check")}
                    </button>
                  )}
                  {this.isUpdateable() && !succeeded && !updating && (
                    <>
                      <p className="pt-5 mt-5 pb-5">
                        Please do not turn off and keep the device near by while
                        updating
                      </p>
                      <button
                        onClick={this.updateFirware}
                        className="d-flex btn btn-dark age-verify__content__button"
                      >
                        Update
                      </button>
                    </>
                  )}
                  {updating && (
                    <div className="fw-bar-wrapper">
                      <div className="fw-progress-bar">
                        <span
                          className="fw-progress-bar-fill"
                          style={{
                            width: `${progress}%`,
                          }}
                        ></span>
                      </div>
                    </div>
                  )}
                  {((!this.isUpdateable() && availableFirmware) ||
                    succeeded) && (
                      <>
                        {succeeded && (
                          <p className="pt-5 mt-5 pb-5">Firmware Updated</p>
                        )}
                        <button className="d-flex btn btn-dark age-verify__content__button">
                          <Link to="/home">Dashboard</Link>
                        </button>
                      </>
                    )}
                </>
              }
            </div>
          </div>
        </div>
      </>
    );
  }

  prepareFirmwarePayload(fwbin) {
    let byteContent = new Int8Array(fwbin);
    let cnt = 0;
    let lastAddress = byteContent.length - 1;
    console.log("FW_LEN", "|" + lastAddress + "|");
    console.log(byteContent);

    let start = new Int8Array(20);

    //COMMAND
    start[0] = 0x80;
    start[1] = 0x00;
    start[2] = 0x00;
    start[3] = 0x00;
    start[4] = (lastAddress >> 24) & 0xff;
    start[5] = (lastAddress >> 16) & 0xff;
    start[6] = lastAddress >> 8;
    start[7] = lastAddress & 0xff;

    //DATA (is this just all zeros for the start packet?)
    for (let i = 8; i < 20; i++) {
      start[i] = 0x00;
    }
    console.log("FW_STR", this.getPayloadDebugStr(start));

    let payloadArr = [start];

    //Now send the actual data from the BIN file
    let data = new Int8Array(20);

    //COMMAND
    data[0] = 0x00;

    //START ADDRESS
    data[1] = 0x00;
    data[2] = 0x00;
    data[3] = 0x00;

    //DATA
    let offset = 4;
    for (let i = 0; i < byteContent.length; i++) {
      data[offset] = byteContent[i];
      offset++;
      if (offset == 20) {
        offset = 4;

        if (cnt < 5) {
          console.log("FW_DAT", this.getPayloadDebugStr(data));
          cnt++;
        }

        payloadArr.push(data);

        data = new Int8Array(20);

        data[1] = ((i + 1) >> 16) & 0xff;
        data[2] = ((i + 1) >> 8) & 0xff;
        data[3] = (i + 1) & 0xff;
      }
    }

    //handle padding any remainder and send
    if (offset < 20) {
      for (let i = offset; i < 20; i++) {
        data[i] = 0x00;
      }
      console.log("FW_DAT", this.getPayloadDebugStr(data));
      payloadArr.push(data);
    }

    let end = new Int8Array(20);

    //COMMAND
    end[0] = 0xf0;
    end[1] = 0x00;
    end[2] = 0x00;
    end[3] = 0x00;
    end[4] = 0x00; //TODO: this seems to be a firmware doc bug where there is one extra place holder byte

    let checksum = 0; //calculated just by summing
    for (let i = 0; i < byteContent.length; i++) {
      checksum += byteContent[i] & 0xff;
    }
    checksum = checksum & 0x00000000ffffffff;
    console.log(
      "FW_CHK",
      "chk[" + checksum + "] len[" + byteContent.length + "]"
    );

    end[5] = (checksum >> 24) & 0xff;
    end[6] = (checksum >> 16) & 0xff;
    end[7] = (checksum >> 8) & 0xff;
    end[8] = checksum & 0xff;

    //DATA (is this just all zeros for the end packet?)

    for (let i = 9; i < 20; i++) {
      end[i] = 0x00;
    }
    console.log("FW_END", this.getPayloadDebugStr(end));

    payloadArr.push(end);

    return payloadArr;
  }
}

const mapStateToProps = (state) => ({
  currentDeviceConnectd: state.device.currentDeviceConnectd,
  isAuthenticated: state.userAuth.isAuthenticated,
  disconnect: state.device.disconnect,
  currentDevice: state.device.currentDevice,
  deviceList: state.device.list,
});

// export default connect(mapStateToProps)(UpdateFirmware);;
export default connect(mapStateToProps)(withTranslation()(UpdateFirmware));
