import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { graphicon, leaficon, phoneicon, shopicon } from "../../assets/images";
import Setup from "../Setup/Setup";
import "./WelcomeSlider.scss";

class WelcomeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
    };
  }

  render() {
    const firstDeviceSetup = localStorage.firstDeviceSetup || false;
    const settings = {
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      infinite: false,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
    };

    const { isAuthenticated, t } = this.props;

    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="welcome-slider">
        <div className="">
          {!firstDeviceSetup ? (
            <Slider {...settings}>
              <div className="welcome-1">
                <h3 className="primary-heading pb-0 pb-md-0  mb-3">
                  {t("welcome_to")}
                  <span className="text-capatilize">{t("daVinci")}</span>
                </h3>
                <p className="pb-3 pt-5">
                  {/* DaVinci enriches your Loose-leaf vaporizing experience through
                  thoughtful design, meticulous engineering and innovative
                  technology */}
                  {t("daVinci_welcome_info_msg")}
                </p>
                <p>{t("daVinci_welcome_functionality_msg")}</p>
              </div>
              <div className="welcome-2">
                <h3 className="primary-heading pb-5 mb-3">
                  {t("welcome_to")}
                  <span className="text-capatilize">{t("daVinci")}</span>
                </h3>
                <div className="d-flex flex-column flex-md-row justify-content-center pl-3 pl-md-0">
                  <div className="d-flex align-items-center pr-0 pr-md-4">
                    <img src={phoneicon} />
                    <p className="pl-3 pl-md-0">
                      {t("daVinci_welcome_phone_icon_msg")}
                    </p>
                  </div>
                  <div className="d-flex align-items-center pt-3 pt-md-0 pl-0 pl-md-4">
                    <img src={leaficon} />
                    <p>{t("daVinci_welcome_leaf_icon_msg")}</p>
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-center pt-3 mt-0 pt-md-5 mt-md-5 pl-3 pl-md-0">
                  <div className="d-flex align-items-center pr-0 pr-md-4">
                    <img src={graphicon} />
                    <p>{t("daVinci_welcome_graph_icon_msg")}</p>
                  </div>
                  <div className="d-flex align-items-center pt-3 pt-md-0 pl-0 pl-md-4">
                    <img src={shopicon} />
                    <p>{t("daVinci_welcome_shop_icon_msg")}</p>
                  </div>
                </div>
              </div>
              <div className="welcome-1">
                <h3 className="primary-heading pb-5 mb-3">
                  {t("welcome_to")}
                  <span className="text-capatilize">{t("daVinci")}</span>
                </h3>
                <p className="pb-3 pt-5">{t("welcome_msg")}</p>
                <p className="pb-3">{t("pairing_msg")}</p>
                <button
                  type="button"
                  className="btn btn-primary mt-5"
                  data-toggle="modal"
                  data-target="#setup"
                >
                  {t("setup")}
                </button>
              </div>
            </Slider>
          ) : (
            <div className="welcome-1">
              <h3 className="primary-heading pb-5 mb-3">
                {t("welcome_to")}
                <span className="text-capatilize">{t("daVinci")}</span>
              </h3>
              <p className="pb-3 pt-5">{t("welcome_msg")}</p>
              <p className="pb-3">{t("pairing_msg")}</p>
            </div>
          )}
          <div className="setup-btn">
            {this.state.activeSlide === 2 || firstDeviceSetup ? (
              <Setup
                buttonHide={this.state.activeSlide === 2 ? true : false}
                name="setup"
                modalStyle="modal-lg"
              />
            ) : null}
          </div>
        </div>
        <div className="welcome-slider__skip d-flex justify-content-center mt-5 pb-3 ">
          <Link to="/home" className="btn btn-primary">
            {t("skip")}
          </Link>

          {/* <Setup name="skip" /> */}
          {/* {this.state.activeSlide === 2 || firstDeviceSetup ? (
            <Link
              to="/dashboard/home"
              className="btn btn-primary mt-5"
              name="Home"
            >
              Home
            </Link>
          ) : (
              <Setup name="skip" />
            )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.userAuth.isAuthenticated,
});

export default connect(mapStateToProps)(withTranslation()(WelcomeSlider));
