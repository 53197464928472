import { t } from "i18next";

export const makeInitalGraphData = () => {
    return {
        title: {
            text: undefined
        },
        colors: ["#FFF", "#E58260"],
        chart: {
            id: "basic-bar",
            backgroundColor: "#322c3a",
            toolbar: {
                show: false,
            },
            height: "50%",
        },
        yAxis: {
            title: {
                text: t("temperature")
            },
            tickAmount: 6,
            min: 200,
            max: 450,
            labels: {
                style: {
                    color: "#E58260",
                    fontSize: "18px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },

        xAxis: {
            labels: {
                enabled: false
            }
        },

        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
            animation: false,
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                label: {
                    connectorAllowed: false
                },
                pointStart: 0
            }
        },

        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        chart: {
                            height: "100%",
                        },
                    },
                },
            ],
        },
    }
}