import * as moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "../../store/index";
import InputField from "../InputField/InputField";
// import SocialIcon from "../SocialIcon/SocialIcon";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { withTranslation } from "react-i18next";
import {
  //  Link,
  Redirect,
} from "react-router-dom";
import { toast } from "react-toastify";
import {
  // ConnectDevice,
  DeviceHelper,
} from "../../helpers/device";
import {
  addDevice,
  editDevice,
  getDevices,
  SETUP_DEVICE,
  SET_CURRENT_DEVICE,
} from "../../store/actions";
import "./Setup.scss";

class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isContinue: false,
      isConnected: false,
      isLoading: false,
      deviceConnected: false,
      skipSetupScreen: false,
      devices: [],
      currentDevice: {
        address: "",
        created: moment().utc().format(),
        firmwareVersion: "v_5.0.3.0",
        forgotten: false,
        model: "DAVINCI IQ2",
        name: "",
        ownerId: props.user.userId,
        serialNumber: "DAVINCI IQ2",
        smartPaths: [],
        additionalProperties: {},
      },
    };
  }

  componentDidMount() {
    let { currentDevice, currentDeviceInformation } = this.props;
    if (currentDevice) {
      this.setState((prevState) => ({
        currentDevice: {
          ...prevState.currentDevice,
          ...currentDevice,
          ...currentDeviceInformation,
          name: currentDevice.name,
        },
      }));
    }

    // this.props.getDevices(this.props.user.userId).then((res) => {
    //   this.setState({
    //     devices: res,
    //   });
    // });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentDevice.id !== prevProps.currentDevice.id) {
      this.setState({
        currentDevice: this.props.currentDevice,
      });
    }
  }

  editDevice = (deviceObj, name) => {
    this.props
      .editDevice(deviceObj.id, { ...deviceObj, name }, true)
      .then(() => {
        toast.success(this.props.t("success"));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        return Promise.resolve();
      });
  };

  closeHandler = async () => {
    let { skipSetup, device, currentDeviceInformation, t } = this.props;
    let { currentDevice } = this.state;
    if (currentDevice && !currentDevice.name) {
      toast.error(t("device_name_is_required"));
      return true;
    }
    let deviceName = currentDevice.name;
    if (skipSetup) {
      $(`#${this.props.id}`).modal().hide();
    } else {
      $(`#${this.props.name}`).modal().hide();
    }
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();

    localStorage.firstDeviceSetup = true;
    store.dispatch({ type: SETUP_DEVICE });
    this.setState({ isContinue: true, isConnected: false });

    let existingDevice;

    if (
      DeviceHelper.gattInstance &&
      DeviceHelper.gattInstance.device &&
      DeviceHelper.gattInstance.device.id
    ) {
      existingDevice = device.find(
        (device) => device.address === DeviceHelper.gattInstance.device.id
      );
    }

    if (existingDevice) {
      store.dispatch({
        type: SET_CURRENT_DEVICE,
        payload: { ...existingDevice, name: currentDevice.name },
      });
      await this.editDevice(existingDevice, currentDevice.name);
      return;
    }

    if (!skipSetup) {
      if (
        DeviceHelper.gattInstance &&
        DeviceHelper.gattInstance.device &&
        DeviceHelper.gattInstance.device.id
      ) {
        this.setState(
          (prevState) => {
            return {
              ...prevState,
              currentDevice: {
                ...prevState.currentDevice,
                address: DeviceHelper.gattInstance.device.id,
                ...currentDeviceInformation,
              },
            };
          },
          async () => {
            await this.props.addDevice(
              this.props.user.userId,
              this.state.currentDevice
            );
          }
        );
      }
    } else {
      if (
        DeviceHelper.gattInstance &&
        DeviceHelper.gattInstance.device &&
        DeviceHelper.gattInstance.device.id
      ) {
        this.setState(
          {
            currentDevice: {
              address: DeviceHelper.gattInstance.device.id,
              created: moment().utc().format(),
              firmwareVersion: "v_5.0.3.0",
              forgotten: false,
              model: "DAVINCI IQ2",
              name: deviceName,
              ownerId: this.props.user.userId,
              serialNumber: "DAVINCI IQ2",
              smartPaths: [],
              additionalProperties: {},
              ...currentDeviceInformation,
            },
          },
          async () => {
            await this.props.addDevice(
              this.props.user.userId,
              this.state.currentDevice
            );
          }
        );
      }
    }
  };

  connectDevice = async () => {
    let { t } = this.props;
    try {
      this.setState({
        isLoading: true,
      });
      await DeviceHelper.EstablishDeviceConnection();
      toast.success(t("device_connected"));

      //  DeviceHelper.encodeSmartPathData();
      // await WriteSmartPaths();
      this.setState({
        isConnected: true,
        isLoading: false,
      });

      this.closeHandler();
    } catch (e) {
      toast.error(t("device_can_not_connected"));
      console.log("Error", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  renameDevice = async () => {
    let { onEditDeviceName, currentDeviceData, isEdit } = this.props;
    const modalId = this.props.id || this.props.name;
    try {
      this.setState({
        isLoading: true,
      });
      await onEditDeviceName(
        isEdit ? currentDeviceData.name : this?.state?.currentDevice?.name,
        modalId
      );
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeDeviceName = (e) => {
    this.setState((prevState) => ({
      currentDevice: { ...prevState.currentDevice, name: e.target.value },
    }));
  };

  render() {
    let {
      isEdit,
      // onEditDeviceName,
      skipSetup,
      currentDeviceData,
      onChangeDeviceName,
      modalStyle,
      t,
    } = this.props;
    if (!skipSetup && this.state.isContinue)
      return <Redirect to="/setup"></Redirect>;
    const modalId = this.props.id || this.props.name;
    let nameInputValue =
      (isEdit ? currentDeviceData.name : this.state?.currentDevice?.name) || "";
    return (
      <>
        {!this.props.buttonHide ? (
          <button
            type="button"
            className="btn btn-primary mt-md-5"
            data-toggle="modal"
            data-target={`#${modalId}`}
          >
            {this.props.name}
          </button>
        ) : null}
        <div
          className="setup-box modal fade"
          id={modalId}
          tabIndex="-1"
          role="dialog"
          aria-labelledby={`${this.props.name}Label`}
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered ${modalStyle}`}
            role="document"
          >
            <div className="modal-content fancy-scroll">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={faAngleLeft}
                    className="social-icon"
                  />
                </button>

                <div className="form-group setup-box__input text-center pt-5">
                  <h3 className="primary-heading">
                    {isEdit ? t("rename_device") : t("setup_device")}
                  </h3>
                  <p className="pb-2 ">{t("name_your_device")}</p>
                  <InputField
                    type="text"
                    placeholder={"device_name"}
                    name="Device Name"
                    secure={false}
                    onChange={
                      isEdit ? onChangeDeviceName : this.onChangeDeviceName
                    }
                    value={nameInputValue}
                  />

                  {isEdit ? (
                    <button
                      onClick={() => this.renameDevice()}
                      className="btn btn-primary mt-md-5 mt-3"
                    >
                      {this.state.isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        t("edit_name")
                      )}
                    </button>
                  ) : !this.state.isConnected ? (
                    <button
                      onClick={this.connectDevice}
                      className="btn btn-primary mt-md-5 mt-3"
                    >
                      {this.state.isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        t("connect")
                      )}
                    </button>
                  ) : (
                    <button
                      onClick={this.closeHandler}
                      className="btn btn-primary mt-md-5 mt-3"
                    >
                      {t("continue")}
                    </button>
                  )}
                  {/* <p className=" ">Choose your Device</p>
                  {this.state.devices.map((device) => {
                    return <p className="py-2a">{device.name}</p>;
                  })}
                  <button
                    onClick={this.closeHandler}
                    className="btn btn-primary mt-5"
                  >
                    continue
                  </button>
                  <p className="pb-2 pt-4 setup-box__description ">
                    If your device is not listed, ensure that it is turned on
                    and within reach
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userAuth.user,
  device: state.device.list,
  currentDevice: state.device.currentDevice,
  currentDeviceInformation: state.device.currentDeviceInformation,
});

const mapDispatchToProps = (dispatch) => ({
  addDevice: (user_id, data) => dispatch(addDevice(user_id, data)),
  getDevices: (user_id) => dispatch(getDevices(user_id)),
  editDevice: (id, data) => dispatch(editDevice(id, data)),
  // updateDevice: (user_id, data) => dispatch(updateDevice(user_id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Setup));
