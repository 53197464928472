import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getLocale } from "../helpers/helpers";
import English from "./locales/English";
import French from "./locales/French";
import Spanish from "./locales/Spanish";

const resources = {
  en: {
    translation: English,
  },
  es: {
    translation: Spanish,
  },
  fr: {
    translation: French,
  },
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: getLocale(),

    fallbackLng: "en",
    resources,
    debug: process.env.NODE_ENV === "development",

    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
