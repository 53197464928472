import { t } from "i18next";
import React, { Component } from "react";

import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { profile } from "../../assets/images/index";
import { closeModal } from "../../helpers/helpers";
import { editProfile } from "../../store/actions/index";
import InputField from "../InputField/InputField";
import "./EditProfile.scss";
class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.user.firstName || "",
      lastName: props.user.lastName || "",
      email: props.user.email || "",
      isLoading: false,
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    let { isLoading, ...BodyData } = this.state;
    this.props
      .editProfile(this.props.user.id, BodyData)
      .then(() => {
        toast.success(t("profileUpdateSuccessfully"));
        closeModal("editProfile");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary my-3"
          data-toggle="modal"
          data-target="#editProfile"
        >
          {t("edit_profile")}
        </button>
        <div
          className="edit-profile-modal profile-box modal fade"
          id="editProfile"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editProfileLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content fancy-scroll ox-hidden">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={faAngleLeft}
                    className="social-icon"
                  />
                </button>
                <div className="profile-box__detail text-center">
                  <h3 className="main-heading pb-3 pt-3 mb-0">
                    {t("edit_profile")}
                  </h3>
                  <div className="profile-box__upload">
                    <input
                      accept="image/x-png,image/gif,image/jpeg"
                      type="file"
                    ></input>
                    <img className="w-50" src={profile} />
                  </div>
                  <p className="text-white py-4 mb-md-3 mb-0">
                    {t("upload_profile_picture")}
                  </p>

                  <InputField
                    type="email"
                    placeholder={"email_address"}
                    name="email"
                    secure={false}
                    onChange={this.handleChange}
                    value={this.state.email}
                  />
                  <InputField
                    type="text"
                    placeholder={"first_name"}
                    name="firstName"
                    secure={false}
                    onChange={this.handleChange}
                    value={this.state.firstName}
                  />
                  <InputField
                    type="text"
                    placeholder={"last_name"}
                    name="lastName"
                    secure={false}
                    onChange={this.handleChange}
                    value={this.state.lastName}
                  />
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary mt-md-3"
                  >
                    {this.state.isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      t("save")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.userAuth.user,
});

const mapDispatchToProps = (dispatch) => ({
  editProfile: (id, data) => dispatch(editProfile(id, data)),
});

// export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditProfile));
