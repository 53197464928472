import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { homeslide1 } from "../../assets/images";
import "./HomeSlider.scss";

class HomeSlider extends Component {
  state = {};
  render() {
    const { t } = this.props;

    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      infinite: true,
    };
    return (
      <>
        <Slider {...settings}>
          <div>
            <img src={homeslide1} />
            <h3 className="slide-text text-center pt-2">
              {t("home_slider_text")}
            </h3>
          </div>
          <div>
            <img src={homeslide1} />
            <h3 className="slide-text text-center pt-2">
              {t("home_slider_text")}
            </h3>
          </div>
          <div>
            <img src={homeslide1} />
            <h3 className="slide-text text-center pt-2">
              {t("home_slider_text")}
            </h3>
          </div>
        </Slider>
      </>
    );
  }
}

export default withTranslation()(HomeSlider);
