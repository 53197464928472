import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { forgotPassword } from "../../store/actions";
import InputField from "../InputField/InputField";
import SocialIcon from "../SocialIcon/SocialIcon";
import "./ForgotPassword.scss";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isLoading: false,
      reset: false,
    };
  }

  changeScreen = () => {
    this.setState({ reset: true });
  };

  handleSubmit = (e) => {
    const { t } = this.props;
    e.preventDefault();
    const { email } = this.state;
    if (!email) {
      toast.error(t("email_is_required"), {
        toastId: "emailRequired"
      });
      return false;
    }
    this.setState({ isLoading: true });
    this.props
      .forgotPassword(email)
      .then(() => {
        console.log("success");
        toast.success(t("success"));
        this.changeScreen();
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleModalOpen = () => {
    this.setState({ reset: false, email: "" });
  };

  render() {
    const { email, isLoading } = this.state;
    const { t } = this.props;

    return (
      <>
        <a
          onClick={this.handleModalOpen}
          className="fancy-text"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          {t("forgot_password")}
        </a>
        <div
          className="forgot-box modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={faAngleLeft}
                    className="social-icon"
                  />
                </button>
                {!this.state.reset ? (
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group forgot-box__input text-center pt-5">
                      <h3 className="primary-heading">{t("resetPassword")}</h3>
                      <p className="pb-5 mb-3">
                        {t("enter_login_email_to_recover_your_password")}
                      </p>
                      <InputField
                        type="email"
                        placeholder={"email"}
                        name="email"
                        secure={false}
                        value={email}
                        onChange={this.handleChange}
                      />
                      <button
                        type="submit"
                        // onClick={this.handleSubmit}
                        className="btn btn-primary mt-3"
                      >
                        {isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          t("submit")
                        )}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="form-group forgot-box__input text-center pt-5">
                    <h3 className="primary-heading">Forgot Password</h3>
                    <p className="pb-5 ">
                      Please check email sent to
                      <br />
                      {this.state.email}
                    </p>

                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Login
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.email
})


const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(forgotPassword(email)),
  //   forgotPassword: (email) => dispatch(forgotPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ForgotPassword));
