import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import "./NavBlock.scss";

const NavBlock = (props) => {
  return (
    <NavLink
      activeClassName="active-navlink"
      to={props.link ? props.link : null}
      onClick={props.onClick}
    >
      <div className={props.active ? "nav-block active" : "nav-block"}>
        <div>
          {/* <img src={props.img} className="nav-icon" /> */}
          {props.icon && <FontAwesomeIcon icon={props.icon} className="nav-icon" />}
        </div>
        <div className="overflow-hidden position-relative">
          <p className="nav-link m-0">{props.name}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default NavBlock;
