import {
  faAngleLeft,
  faClock,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CToF } from "../../helpers/helpers";
import "./SmartPathList.scss";

class SmartPathList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathStats: [],
    };
  }

  componentDidMount() {
    let { currentDeviceSmartPathList, currentDeviceState } = this.props;
    if (currentDeviceSmartPathList && currentDeviceSmartPathList.length) {
      this.setState({
        pathStats: currentDeviceSmartPathList.map((obj, index) => {
          let timeStats = "";
          let minTemp = parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.points[0].temp || 0
              : CToF(obj.points[0].temp || 0)
          );
          let maxTemp = parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.points[2].temp || 0
              : CToF(obj.points[2].temp || 0)
          );
          if (obj.numberOfPoints && obj.numberOfPoints > 0) {
            timeStats = `${minTemp}˚ - ${maxTemp}˚`;
          }
          let smarthPathData = JSON.parse(
            localStorage.getItem("smartPathData") || "{}"
          );
          return {
            obj,
            title: smarthPathData[index] || `Smart Path ${index + 1}`,
            pathIndex: index,
            time: obj.duration / 60,
            stats: timeStats,
            minTemp: minTemp,
            maxTemp: maxTemp,
          };
        }),
      });
    }
  }

  componentDidUpdate(prevProps) {
    let { currentDeviceSmartPathList, currentDeviceState } = this.props;
    if (
      currentDeviceSmartPathList &&
      currentDeviceSmartPathList.length &&
      prevProps.currentDeviceSmartPathList !== currentDeviceSmartPathList
    ) {
      this.setState({
        pathStats: currentDeviceSmartPathList.map((obj, index) => {
          let timeStats = "";
          let minTemp = parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.points[0].temp || 0
              : CToF(obj.points[0].temp || 0)
          );
          let maxTemp = parseInt(
            currentDeviceState.displayModeCelcius
              ? obj.points[2].temp || 0
              : CToF(obj.points[2].temp || 0)
          );
          if (obj.numberOfPoints && obj.numberOfPoints > 0) {
            timeStats = `${minTemp}˚ - ${maxTemp}˚`;
          }
          let smarthPathData = JSON.parse(
            localStorage.getItem("smartPathData") || "{}"
          );
          return {
            obj,
            title: smarthPathData[index] || `Smart Path ${index + 1}`,
            pathIndex: index,
            time: obj.duration / 60,
            stats: timeStats,
            minTemp: minTemp,
            maxTemp: maxTemp,
          };
        }),
      });
    }
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    let { onSelectSmartPath, selectedIndex, id } = this.props;
    return (
      <>
        <div
          className="profile-box modal fade"
          id={`${id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editPasswordLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content fancy-scroll">
              <div className="modal-body">
                <button
                  type="button"
                  className="close smart-path-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={faAngleLeft}
                    className="social-icon"
                  />
                </button>
                <div className="smart-path__stats">
                  {this.state.pathStats.length === 0 && (
                    <p className="m-0 pl-3 text-center">{t("noSmartPath")}</p>
                  )}
                  <div className="row m-0">
                    {!!this.state.pathStats.length &&
                      this.state.pathStats.map((obj) => (
                        <div className="col-12 col-md-6 pt-md-4 pt-2">
                          <div
                            className="smart-path__box"
                            onClick={() => onSelectSmartPath(obj.pathIndex)}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              {/* <h3>{obj.title}</h3> */}
                              <h3>{t(obj.title)}</h3>
                              {/* <button
                                className="smart-btn"
                                onClick={() => onSelectSmartPath(obj.pathIndex)}
                              >
                                {" "}
                                <FontAwesomeIcon
                                  icon={
                                    selectedIndex === obj.pathIndex
                                      ? faCheck
                                      : faAngleRight
                                  }
                                />
                              </button> */}
                              {/* <PathGraph name="pathgraph" title={obj.title} /> */}
                            </div>
                            <div className="d-flex pt-md-5 pb-md-2 cursor-hand">
                              <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                  className="smart-icon"
                                  icon={faClock}
                                />
                                <p className="m-0 pl-3">{Number(obj.time).toFixed(2)} min</p>
                              </div>

                              <div className="d-flex align-items-center pl-3">
                                <FontAwesomeIcon
                                  className="smart-icon"
                                  icon={faSyncAlt}
                                />
                                <p className="m-0 pl-3">{obj.stats}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentDeviceSmartPathList: state.device.currentDeviceSmartPathList,
  currentDeviceState: state.device.currentDeviceState,
});

export default connect(mapStateToProps, null)(withTranslation()(SmartPathList));
