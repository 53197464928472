import { t } from "i18next";
import React, { Component } from "react";

import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { deviceImage, deviceInfo } from "../../assets/images/index";
import Setup from "../../components/Setup/Setup";
import { DeviceHelper } from "../../helpers/device";
import {
  ConvertToTwoDecimalPlaces,
  CToF,
  getSessionData,
} from "../../helpers/helpers";
import { withHeader } from "../../hoc";
import {
  editDevice,
  forgetDevice,
  SET_CURRENT_DEVICE,
} from "../../store/actions";
import { store } from "../../store/index";
import "./Devices.scss";
class Devices extends Component {
  state = {
    device: false,
    isLoading: false,
    isConnecting: false,
    currentDeviceData: {},
  };

  componentDidMount() {
    let { currentDevice } = this.props;
    if (Object.keys(currentDevice).length) {
      this.setState({ currentDeviceData: currentDevice });
    }
  }

  componentDidUpdate(prevProps) {
    let { currentDevice } = this.props;
    if (prevProps.currentDevice !== currentDevice) {
      if (Object.keys(currentDevice).length) {
        this.setState({ currentDeviceData: currentDevice });
      } else {
        this.setState({ currentDeviceData: {} });
      }
    }
  }

  forgetDevice = () => {
    // const { currentDevice, currentDeviceConnectd } = this.props;
    const { currentDeviceData } = this.state;
    // if (!currentDeviceConnectd || currentDevice.id !== currentDeviceData.id) {
    //   toast.error("Device already disconnected");
    //   return false;
    // }
    this.setState({ isLoading: true });
    this.props
      .forgetDevice(currentDeviceData.id, currentDeviceData)
      .then(async () => {
        toast.success(t("successfullyForgetDevice"));
        await DeviceHelper.disconnect();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  editDevice = (name, modalId) => {
    const { currentDeviceData } = this.state;
    this.props
      .editDevice(currentDeviceData.id, { ...currentDeviceData, name })
      .then(() => {
        toast.success(t("successfullyEditDeviceName"));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        $(`#${modalId}`).modal().hide();
        $(`#mobile-editmodal`).modal().hide();
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        return Promise.resolve();
      });
  };

  connectDevice = async () => {
    let { currentDeviceData } = this.state;

    try {
      this.setState({
        isConnecting: true,
      });
      await DeviceHelper.EstablishDeviceConnection();
      toast.success(t("device_connected"));
      store.dispatch({
        type: SET_CURRENT_DEVICE,
        payload: { ...currentDeviceData },
      });
      this.setState({
        isConnecting: false,
      });
    } catch (e) {
      toast.error(t("device_can_not_connected"));
      this.setState({
        isConnecting: false,
      });
    }
  };

  disconnectDevice = async () => {
    try {
      this.setState({
        isConnecting: true,
      });
      await DeviceHelper.disconnect();
      toast.success(t("device_disconnected"));
      this.setState({
        isConnecting: false,
      });
    } catch (e) {
      toast.error(t("device_can_not_disconnected"));
      this.setState({
        isConnecting: false,
      });
    }
  };

  handleSliderChange = (index) => {
    let { deviceList } = this.props;
    let currentDeviceData = deviceList[index];
    this.setState({ currentDeviceData });
  };

  handleEditDeviceName = (e) => {
    this.setState((prevState) => ({
      currentDeviceData: {
        ...prevState.currentDeviceData,
        name: e.target.value,
      },
    }));
  };

  render() {
    const device = {
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 1000,
      autoplaySpeed: 2000,
      infinite: true,
      // prevArrow: <FontAwesomeIcon className="text-danger" icon={faAngleRight} />,
      // nextArrow: <FontAwesomeIcon icon={faAngleLeft} />
    };
    const { currentDeviceData } = this.state;

    let {
      currentDevice,
      currentDeviceConnectd,
      currentDeviceStatistics,
      currentDeviceState,
      deviceList,
      fetching,
    } = this.props;
    const { isLoading, isConnecting } = this.state;
    const sessionData = getSessionData();
    const sessionDataKeys = Object.keys(sessionData);
    let totalTHC = 0;
    let totalCBD = 0;
    sessionDataKeys.map((key) => {
      return sessionData[key].map((item) => {
        totalTHC = totalTHC + parseFloat(item.totalTHCConsumed);
        totalCBD = totalCBD + parseFloat(item.totalCBDConsumed);
        return item;
      });
    });


    return (
      <>
        <div className="row align-items-center no-gutters">
          <div className="col-6">
            <h2 className="primary-heading mb-0">{t("my_device")}</h2>
          </div>
          <div className="col-6 d-flex device-top-btns d-flex justify-content-end align-items-center">
            <div className="device-data-table-mob">
              <button
                type="button"
                className="device-data-mob-btn bg-transparent border-0"
                data-toggle="modal"
                data-target="#device-data-mob"
              >
                <img src={deviceInfo} className="img-fluid" alt="device-1" />
              </button>
              <div
                className="device-data-mob-modal device-screen__detail modal fade"
                id="device-data-mob"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="device-data-mobLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content fancy-scroll">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <FontAwesomeIcon
                          aria-hidden="true"
                          icon={faAngleLeft}
                          className="social-icon"
                        />
                      </button>
                      <div className="d-flex align-items-center justify-content-between device-screen__box  mb-3 pb-3">
                        <h6>{t("model")}</h6>
                        <p className="mb-0">
                          {currentDeviceData.model || "Not Available"}
                        </p>
                      </div>

                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("name")}</h6>

                        <p className="mb-0">
                          {currentDeviceData.name || "Not Available"}
                        </p>
                      </div>

                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("address")}</h6>

                        <p className="mb-0">
                          {(currentDeviceData.address
                            ? currentDeviceData.address.slice(0, 30)
                            : "Not Available") || "Not Available"}
                        </p>
                      </div>

                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("serial_number")}</h6>

                        <p className="mb-0">
                          {currentDeviceData.serialNumber || "Not Available"}
                        </p>
                      </div>

                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("firmware_version")}</h6>
                        <p className="mb-0">
                          {currentDeviceData.firmwareVersion || "Not Available"}
                        </p>
                      </div>

                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("total_usage")}</h6>
                        <p className="mb-0">
                          {currentDeviceConnectd
                            ? (currentDeviceStatistics.totalTime || 0) + " hrs"
                            : "N/A"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("average_session")}</h6>
                        <p className="mb-0">
                          {currentDeviceConnectd
                            ? (currentDeviceStatistics.averageSession || 0) +
                            " min"
                            : "N/A"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("average_temperature")}</h6>

                        <p className="mb-0">
                          {currentDeviceConnectd &&
                            currentDeviceStatistics.averageTemp
                            ? (currentDeviceState.displayModeCelcius
                              ? ConvertToTwoDecimalPlaces(
                                currentDeviceStatistics.averageTemp || 0
                              )
                              : CToF(
                                ConvertToTwoDecimalPlaces(
                                  currentDeviceStatistics.averageTemp || 0
                                )
                              ) || 0) +
                            " " +
                            (currentDeviceState.displayModeCelcius
                              ? "˚C"
                              : "˚F")
                            : "N/A"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("favorite_path")}</h6>
                        <p className="mb-0">
                          {currentDeviceConnectd
                            ? "Smart Path " +
                            (currentDeviceStatistics.favoritePath || 1)
                            : "N/A"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("total_THC_consumed")}</h6>
                        <p className="mb-0">
                          {" "}
                          {currentDeviceConnectd ? totalTHC + " mg" : "N/A"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("total_CBD_consumed")}</h6>
                        <p className="mb-0">
                          {currentDeviceConnectd ? totalCBD + " mg" : "N/A"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                        <h6>{t("total_draws")}</h6>

                        <p className="mb-0">
                          {currentDeviceConnectd
                            ? currentDeviceStatistics.totalPuffCountHistory || 0
                            : "N/A"}
                        </p>
                      </div>
                      {/* changedByShehzad */}
                      {currentDeviceData.model?.toLowerCase().includes("iq2") && (
                        <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                          <h6 className="" data-dismiss="modal" onClick={() => this.props.history.push("/check-firmware-update")}>
                            {/* <Link to="/check-firmware-update" > */}
                            {t("check_for_firmware_update")}
                            {/* </Link> */}
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="add-device-wrap ml-2">
              <Setup
                // onEditDeviceName={(name) => this.editDevice(name)}
                // isEdit={true}
                name="Add Device"
                id="addmodal"
                modalStyle="modal-lg"
                skipSetup={true}
              />
            </div>
          </div>
        </div>
        {fetching ? (
          <div style={{ textAlign: "center" }}>
            <span
              className="spinner-border spinner-border-sm shop-loader"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        ) : !Object.keys(currentDeviceData).length ? (
          <div className="d-flex flex-column justify-content-center align-items-center device-screen">
            <h2 className="primary-heading">{t("no_devices_found_short")}</h2>
            <p className="pt-3">{t("no_devices_found_msg")}</p>
            <Setup
              modalStyle="modal-lg"
              id="setupDevice"
              name={t("setup_device")}
              skipSetup={true}
            ></Setup>
          </div>
        ) : (
          <div className="row mx-0 pt-4 pb-3">
            <div className="device-slider col-12 col-md-6">
              <Slider
                {...device}
                afterChange={this.handleSliderChange}
              // onSwipe={(index) => {
              // }}
              >
                {deviceList.map((device) => {
                  return (
                    <div
                      className="device-item"
                      key={Math.random().toString().split(".")[1]}
                    >
                      <img
                        src={deviceImage}
                        className="img-fluid mx-auto"
                        alt="device"
                      />
                    </div>
                  );
                })}
              </Slider>
              <div className="d-flex align-items-center justify-content-between device-btns-mob-hidden">
                <Setup
                  onEditDeviceName={(name, modalId) =>
                    this.editDevice(name, modalId)
                  }
                  isEdit={true}
                  name={t("edit_name")}
                  id="mobile-editmodal"
                  modalStyle="modal-lg"
                  currentDeviceData={currentDeviceData}
                  onChangeDeviceName={this.handleEditDeviceName}
                />

                <button
                  onClick={this.forgetDevice}
                  className="btn btn-primary mt-md-5 mt-3 device-btn"
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    t("forget")
                  )}
                </button>
                {
                  <button
                    onClick={
                      !currentDeviceConnectd ||
                        currentDeviceData.id !== currentDevice.id
                        ? this.connectDevice
                        : this.disconnectDevice
                    }
                    className="btn btn-primary mt-md-5 mt-3 device-btn"
                  >
                    {isConnecting ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : !currentDeviceConnectd ||
                      currentDeviceData.id !== currentDevice.id ? (
                      t("connect")
                    ) : (
                      t("disconnect")
                    )}
                  </button>
                }
              </div>
            </div>
            {/* <div className="col-12 col-md-6 device-img d-flex justify-content-center device-slider mt-md-0 mt-3">
              <img src={deviceImage} className="img-fluid" />
            </div> */}
            <div className="col-12 col-md-6 device-screen__detail">
              <h4 className="mb-0 mt-md-0 mt-3 d-inline-block mr-1">
                {currentDeviceData.name}
              </h4>
              <span
                style={{
                  color: "#E58260",
                  fontSize: 15,
                  display: "inline-block",
                }}
              >
                {" "}
                ({" "}
                {!currentDeviceConnectd ||
                  currentDeviceData.id !== currentDevice.id
                  ? t("disconnected")
                  : t("connected")}{" "}
                )
              </span>
              <div className="device-data-table-hide-mob">
                <div className="d-flex align-items-center justify-content-between device-screen__box  mb-3 pb-3">
                  <h6>{t("model")}</h6>
                  <p className="mb-0">
                    {currentDeviceData.model || "Not Available"}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("name")}</h6>
                  <p className="mb-0">
                    {currentDeviceData.name || "Not Available"}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("address")}</h6>
                  <p className="mb-0">
                    {(currentDeviceData.address
                      ? currentDeviceData.address.slice(0, 30)
                      : "Not Available") || "Not Available"}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("serial_number")}</h6>
                  <p className="mb-0">
                    {currentDeviceData.serialNumber || "Not Available"}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("firmware_version")}</h6>
                  <p className="mb-0">
                    {currentDeviceData.firmwareVersion || "Not Available"}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("total_usage")}</h6>
                  <p className="mb-0">
                    {currentDeviceConnectd
                      ? (currentDeviceStatistics.totalTime || 0) + " hrs"
                      : "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("average_session")}</h6>
                  <p className="mb-0">
                    {currentDeviceConnectd
                      ? (currentDeviceStatistics.averageSession || 0) + " min"
                      : "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("average_temperature")}</h6>
                  <p className="mb-0">
                    {currentDeviceConnectd &&
                      currentDeviceStatistics.averageTemp
                      ? (currentDeviceState.displayModeCelcius
                        ? ConvertToTwoDecimalPlaces(
                          currentDeviceStatistics.averageTemp || 0
                        )
                        : CToF(
                          ConvertToTwoDecimalPlaces(
                            currentDeviceStatistics.averageTemp || 0
                          )
                        ) || 0) +
                      " " +
                      (currentDeviceState.displayModeCelcius ? "˚C" : "˚F")
                      : "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("favorite_path")}</h6>
                  <p className="mb-0">
                    {currentDeviceConnectd
                      ? "Smart Path " +
                      (currentDeviceStatistics.favoritePath || 1)
                      : "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("total_THC_consumed")}</h6>
                  <p className="mb-0">
                    {currentDeviceConnectd ? totalTHC + " mg" : "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("total_CBD_consumed")}</h6>
                  <p className="mb-0">
                    {currentDeviceConnectd ? totalCBD + " mg" : "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                  <h6>{t("total_draws")}</h6>
                  <p className="mb-0">
                    {currentDeviceConnectd
                      ? currentDeviceStatistics.totalPuffCountHistory || 0
                      : "N/A"}
                  </p>
                </div>
                {currentDeviceData.model?.toLowerCase().includes("iq2") && (
                  <div className="d-flex align-items-center justify-content-between device-screen__box mb-3 pb-3">
                    <h6 className="">
                      <Link to="/check-firmware-update">
                        {t("check_for_firmware_update")}
                      </Link>
                    </h6>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between device-btns-large-hidden mt-3">
                <Setup
                  onEditDeviceName={(name, modalId) =>
                    this.editDevice(name, modalId)
                  }
                  isEdit={true}
                  name="edit name"
                  id="editmodal"
                  currentDeviceData={currentDeviceData}
                  onChangeDeviceName={this.handleEditDeviceName}
                />

                <button
                  onClick={this.forgetDevice}
                  className="btn btn-primary mt-md-5 device-btn"
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    t("forget")
                  )}
                </button>
                {
                  <button
                    onClick={
                      !currentDeviceConnectd ||
                        currentDeviceData.id !== currentDevice.id
                        ? this.connectDevice
                        : this.disconnectDevice
                    }
                    className="btn btn-primary mt-md-5 device-btn"
                  >
                    {isConnecting ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : !currentDeviceConnectd ||
                      currentDeviceData.id !== currentDevice.id ? (
                      t("connect")
                    ) : (
                      t("disconnect")
                    )}
                  </button>
                }
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentDevice: state.device.currentDevice,
  currentDeviceState: state.device.currentDeviceState,
  currentDeviceConnectd: state.device.currentDeviceConnectd,
  currentDeviceStatistics: state.device.currentDeviceStatistics,
  deviceList: state.device.list,
  fetching: state.device.fetching,
});

const mapDispatchToProps = (dispatch) => ({
  editDevice: (id, data) => dispatch(editDevice(id, data)),
  forgetDevice: (id, data) => dispatch(forgetDevice(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withHeader(Devices)));
