import {
  DEVICE_LIST_FAIL,
  DEVICE_LIST_REQUEST,
  DEVICE_LIST_SUCCESS,
  SETUP_DEVICE,
  SET_CURRENT_STATE_DEVICE,
  SET_CURRENT_DEVICE,
  SET_SESSION_DATA,
  EDIT_DEVICE_SUCCESS,
  SET_SESSION_BEGIN,
  CLEAR_SESSION_DATA,
  SET_SMART_PATH_DATA,
  SET_INITIAL_SMART_PATH_DATA,
  SET_DEVICE_INFORMARION,
  CLEAR_DEVICE_STATE,
  DEVICE_CONNECTED,
  DEVICE_DISCONNECTED,
  SET_CURRENT_DEVICE_STATISTIC,
  ADD_DEVICE_SUCCESS,
  FORGET_DEVICE_SUCCESS,
  CONNECTING,
  CONNECTION_ERROR,
  SET_SMARTH_PATH_TITLE,
  SET_SMARTH_PATH_INDEX,
} from "../actions";
import { cloneDeep } from "lodash";

const initialState = {
  firstDeviceSetup: false,
  list: [],
  fetching: false,
  currentDeviceConnectd: false,
  currentDevice: {},
  currentDeviceState: {},
  currentSessionState: {},
  currentSessionData: {},
  currentDeviceSmartPathList: [],
  originalDeviceSmartPathList: [],
  currentDeviceInformation: {},
  currentDeviceStatistics: {},
  connectingDevice: false,
  currentDeviceSmarthPathTitle: {},
  disconnect: false,
  smarthPathIndex: -1,
};

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_DEVICE:
      return {
        ...state,
        firstDeviceSetup: true,
      };
    case DEVICE_LIST_SUCCESS:
      let filterDevices = action.payload.filter((obj) => {
        return !obj.forgotten;
      });
      return {
        ...state,
        list: filterDevices,
        fetching: false,
      };
    case ADD_DEVICE_SUCCESS:
      return {
        ...state,
        currentDevice: action.payload,
        list: [action.payload, ...state.list],
      };
    case DEVICE_LIST_FAIL:
      return {
        ...state,
        list: [],
        fetching: false,
      };
    case DEVICE_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case SET_CURRENT_STATE_DEVICE:
      return {
        ...state,
        currentDeviceState: action.payload,
      };
    case SET_CURRENT_DEVICE:
      return {
        ...state,
        currentDevice: action.payload,
      };
    case SET_SESSION_DATA:
      return {
        ...state,
        currentSessionState: action.payload,
      };
    case EDIT_DEVICE_SUCCESS:
      return {
        ...state,
        currentDevice: action.overide ? action.payload : state.currentDevice,
        list: state.list.map((obj) => {
          if (obj.id === action.payload.id) {
            obj.name = action.payload.name;
          }
          return obj;
        }),
      };
    case SET_SESSION_BEGIN:
      return {
        ...state,
        currentSessionData: {
          ...state.currentSessionData,
          ...action.payload,
        },
      };
    case CLEAR_SESSION_DATA:
      return {
        ...state,
        currentSessionData: {},
      };
    case SET_SMART_PATH_DATA:
      return {
        ...state,
        currentDeviceSmartPathList: cloneDeep([...action.payload]),
      };
    case SET_INITIAL_SMART_PATH_DATA:
      return {
        ...state,
        currentDeviceSmartPathList: cloneDeep([...action.payload]),
        originalDeviceSmartPathList: cloneDeep([...action.payload]),
      };
    case SET_DEVICE_INFORMARION:
      return {
        ...state,
        currentDeviceInformation: {
          ...state.currentDeviceInformation,
          ...action.payload,
        },
      };
    case CLEAR_DEVICE_STATE:
      return {
        ...state,
        // currentDevice: {},
        currentDeviceState: {},
        currentSessionState: {},
        currentSessionData: {},
        currentDeviceSmartPathList: [],
        originalDeviceSmartPathList: [],
        currentDeviceInformation: {},
        currentDeviceConnectd: false,
        currentDeviceStatistics: {},
      };
    case DEVICE_CONNECTED:
      return {
        ...state,
        currentDeviceConnectd: true,
        connectingDevice: false,
        disconnect: false,
      };
    case CONNECTING:
      return {
        ...state,
        connectingDevice: true,
      };
    case CONNECTION_ERROR: {
      return {
        ...state,
        connectingDevice: false,
      };
    }
    case DEVICE_DISCONNECTED:
      return {
        ...state,
        currentDeviceConnectd: false,
        disconnect: true,
      };
    case SET_CURRENT_DEVICE_STATISTIC:
      return {
        ...state,
        currentDeviceStatistics: action.payload,
      };
    case FORGET_DEVICE_SUCCESS:
      let filterTotalDevices = state.list.filter((obj) => {
        return action.payload.id !== obj.id;
      });
      return {
        ...state,
        currentDevice: filterTotalDevices.length ? filterTotalDevices[0] : {},
        list: filterTotalDevices,
      };
    case SET_SMARTH_PATH_TITLE:
      return {
        ...state,
        currentDeviceSmarthPathTitle: action.payload,
      };
    case SET_SMARTH_PATH_INDEX:
      return {
        ...state,
        smarthPathIndex: action.payload,
      };
    default:
      return state;
  }
};

export default deviceReducer;
