import React, { Fragment } from "react";
import ContextWrapper from "./Context/ContextWrapper";
import Routes from "./routes";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import Header from "./components/Header/Header";

const App = () => {
  return (
    <Fragment>
      {/* <Header></Header> */}
      <Routes />
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar
        closeOnClick
        draggable
        pauseOnHover
        closeButton={false}
      />
    </Fragment>
  );
};

export default App;
