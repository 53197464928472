import i18n from "../Internationalization/i18n";

const translationService = {
  updateLocale,
};

function updateLocale(locale) {
  if (locale) {
    i18n.changeLanguage(locale);

    localStorage.setItem("locale", locale);
  }
}

export default translationService;
