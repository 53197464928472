import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { closeModal } from "../../helpers/helpers";
import { changePassword } from "../../store/actions/index";
import InputField from "../InputField/InputField";
import "./EditPassword.scss";

class EditPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      isLoading: false,
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = () => {
    let { password, confirmPassword } = this.state;

    this.setState({ isLoading: true });
    if (!password || !confirmPassword) {
      toast.error(t("please_enter_a_password"));
      this.setState({ isLoading: false });
      return;
    }
    if (password.trim() !== confirmPassword.trim()) {
      toast.error(t("new_password_and_confirm_password_do_not_match"));
      this.setState({ isLoading: false });
      return;
    }
    let { isLoading, ...BodyData } = this.state;
    this.props
      .changePassword(this.props.user.id, BodyData)
      .then(() => {
        toast.success(t("password_Changed_Successfully"));
        closeModal("editPassword");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <>
        <button
          type="button"
          className="fancy-text pt-5"
          data-toggle="modal"
          data-target="#editPassword"
        >
          {t("change_password")}
        </button>
        <div
          className="profile-box modal fade"
          id="editPassword"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editPasswordLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content fancy-scroll">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={faAngleLeft}
                    className="social-icon"
                  />
                </button>
                <div className="profile-box__detail pt-4  text-center">
                  <h3 className="main-heading pb-3 pt-3">
                    {t("change_password")}
                  </h3>

                  {/* <InputField
                                        type="email"
                                        placeholder="Old Password"
                                        name="oldPassword"
                                        secure={false}
                                        value={this.state.oldPassword}
                                    /> */}
                  <InputField
                    type="password"
                    placeholder={"new_password"}
                    name="password"
                    secure={true}
                    onChange={this.handleChange}
                    value={this.state.newPassword}
                  />
                  <InputField
                    type="password"
                    placeholder={"confirm_password"}
                    name="confirmPassword"
                    secure={true}
                    onChange={this.handleChange}
                    value={this.state.confirmPassword}
                  />
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary mt-3"
                  >
                    {this.state.isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      t("submitSmall")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.userAuth.user,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (id, data) => dispatch(changePassword(id, data)),
});

// export default connect(mapStateToProps, mapDispatchToProps)(EditPassword);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditPassword));
