import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import $ from "jquery";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SessionStatus } from "../../constants";
import { getSessionData } from "../../helpers/helpers";
import InputField from "../InputField/InputField";
import Setup from "../Setup/Setup";
import "./SessionSetup.scss";

class SessionSetup extends Component {
  state = {
    error: false,
    device: false,
    devicefound: false,
  };

  errorCheck = () => {
    this.setState({ error: true });
  };
  deviceCheck = () => {
    this.setState({ device: false });
  };
  milidevice = () => {
    this.setState({ devicefound: true });
  };
  closeHandler = () => {
    let { id } = this.props;
    $(`#${id}`).modal().hide();
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
  };

  renderSessionSetup() {
    let {
      onHandleOvenSizeChange,
      onHandleAmountLoadedChange,
      onHandleStrainTypeChange,
      onHandleChange,
      onHandleSessionBegins,
      onHandleStrainNameChange,
      onHandleSessionClose,
      onhandleDosageData,
      id,
    } = this.props;

    let {
      strainName,
      ovenSize,
      amountLoaded,
      strainType,
      targetTHC,
      THC,
      targetCBD,
      CBD,
      status,
    } = this.props.session;

    const sessionData = getSessionData();
    const sessionDataKeys = Object.keys(sessionData);
    sessionDataKeys.map((key) => {
      return (sessionData[key] = sessionData[key].filter((item, index) => {
        return item.strainName.length;
      }));
    });

    const onFocus = (name) => {
      try {
        let input = $(`input[name=${name}]`);
        if (input.val() === 0 || input.val() === "0") {
          input.val("");
          onhandleDosageData(name, "");
        }
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <form>
        <div className="form-group  session-box__input  pt-2">
          <h3 className="main-heading text-center">{t("sessionSetup")}</h3>
          <p className="pb-3 mb-3 text-center">
            {t("please_enter_your_session_information")}
          </p>
          <div className="row">
            <div className="col-12 col-md-6">
              <label>{t("strainName")}</label>
              {/* <InputField
                type="text"
                name="strainName"
                secure={false}
                onChange={onHandleChange}
                value={strainName}
              /> */}
              <div
                style={{
                  position: "relative",
                  width: 200,
                  height: 40,
                  border: 0,
                  padding: 0,
                  margin: 0,
                  marginBottom: 10,
                }}
              >
                <select
                  id={this.props.id + "strainList"}
                  className="strainList"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 213,
                    height: 40,
                    lineHeight: 20,
                    margin: 0,
                    padding: 0,
                    backgroundColor: "#212529",
                  }}
                  onChange={(e) => {
                    onHandleStrainNameChange(e);
                  }}
                  value={strainName}
                >
                  <option value="">{t("createNew")}</option>
                  {sessionDataKeys.map((key) => {
                    return sessionData[key].map((item, index) => {
                      return (
                        <option value={item.strainName} key={index}>
                          {item.strainName}
                        </option>
                      );
                    });
                  })}
                </select>
                <InputField
                  type="text"
                  name="strainName"
                  id="strainName"
                  placeholder={"enterNewStrain"}
                  onChange={(e) => {
                    onHandleChange(e);
                  }}
                  value={strainName}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 185,
                    height: 40,
                    padding: 4,
                    border: "1px solid #FFFF",
                    backgroundColor: "#212529",
                    color: "white",
                  }}
                />
                {/* <input name="idValue" id="idValue" type="hidden"></input> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              <label>{t("targetTHC")}</label>

              <InputField
                type="number"
                name="targetTHC"
                secure={false}
                max={"100"}
                min={"0"}
                onFocus={(e) => onFocus("targetTHC")}
                onChange={onHandleChange}
                value={targetTHC}
              />
            </div>
            <div className="col-12 col-md-3">
              <label>{t("THC")}</label>
              <InputField
                type="number"
                name="THC"
                secure={false}
                max={"100"}
                min={"0"}
                onChange={onHandleChange}
                onFocus={(e) => onFocus("THC")}
                value={THC}
              />
            </div>
            <div className="col-12 col-md-3">
              <label>{t("targetCBD")}</label>
              <InputField
                type="number"
                name="targetCBD"
                secure={false}
                max={"100"}
                min={"0"}
                onFocus={(e) => onFocus("targetCBD")}
                onChange={onHandleChange}
                value={targetCBD}
              />
            </div>
            <div className="col-12 col-md-3">
              <label>{t("CBD")}</label>
              <InputField
                type="number"
                name="CBD"
                secure={false}
                max={"100"}
                onFocus={(e) => onFocus("CBD")}
                min={"0"}
                onChange={onHandleChange}
                value={CBD}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <label>{t("ovenContent")}</label>
              <select
                onChange={onHandleAmountLoadedChange}
                value={amountLoaded}
              >
                <option value="0">{t("select")}</option>
                <option value="1">{t("newBowl")}</option>
                <option value="2">{t("secondUse")}</option>
                <option value="3">{t("thirdUse")}</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <label>{t("strainType")}</label>
              <select onChange={onHandleStrainTypeChange} value={strainType}>
                <option value="0">{t("indica")}</option>
                <option value="1">{t("sativa")}</option>
                <option value="2">{t("indicaDominantHybrid")}</option>
                <option value="3">{t("sativaDominantHybrid")}</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <label>{t("ovenSize")}</label>
              <select onChange={onHandleOvenSizeChange} value={ovenSize}>
                <option value="select">{t("select")}</option>
                <option value="Dry Herb - 100% Full - .5g">
                  {`${t("dryHerb")} - 100% ${t("full")} - .5g`}
                </option>
                <option value="Dry Herb - 80% Full - .4g">
                  {`${t("dryHerb")} - 80% ${t("full")} - .4g`}
                </option>
                <option value="Dry Herb - 60% Full - .3g (or small DaVinci spacer inserted)">
                  {`${t("dryHerb")} - 60% ${t("full")} - .3g (${t(
                    "or_small_daVinci_spacer_inserted"
                  )})`}
                </option>
                <option value="Dry Herb - 40% Full - .2g (DaVinci Dosage Pod or large spacer inserted)">
                  {` ${t("dryHerb")} - 40% ${t("full")} - .2g (${t(
                    "daVinci_dosage_pod_or_large_spacer_inserted"
                  )})`}
                </option>
                <option value="Extract .100g - 2 Grains of Rice">
                  {`${t("extract")} .100g - 2 ${t("grainOfRice")}`}
                </option>
                <option value="Extract .075g - 1.5 Grains of Rice">
                  {`${t("extract")} .075g - 1.5 ${t("grainOfRice")}`}
                </option>
                <option value="Extract .050g - 1 Grain of Rice">
                  {`${t("extract")} .050g - 1 ${t("grainOfRice")}`}
                </option>
                <option value="Extract .025g - 0.5 Grain of Rice">
                  {`${t("extract")} .025g - 0.5 ${t("grainOfRice")}`}
                </option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            {status !== SessionStatus.Active && (
              <button
                type="button"
                onClick={() => onHandleSessionBegins(id)}
                className="btn btn-dark"
              >
                {t("begin")}
              </button>
            )}
            <button
              type="button"
              //   onClick={this.handleSessionTermination}
              data-dismiss="modal"
              aria-label="Close"
              //   onClick={onHandleSessionTerminates}
              className="btn btn-primary ml-3"
              onClick={onHandleSessionClose}
            >
              {t("close")}
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderValidation() {
    return (
      <div className="form-group session-box__input  pt-2">
        <h3 className="main-heading text-center pb-3">{t("emptyFields")}</h3>
        <p className="pb-3 mb-3 text-center">
          {t(
            "your_have_blank_fields_here_this_will_prevent_your_IQ2_from_tracking_and_displaying_your_session_information_accurately"
          )}
        </p>
        <p className="text-center">{t("would_you_like_to_proceed_anyways")}</p>
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            className="btn btn-primary"
          >
            {t("Yes")}
          </button>
          <button type="button" className="btn btn-dark ml-3">
            {t("No")}
          </button>
        </div>
      </div>
    );
  }

  renderNotFound() {
    return (
      <div className="form-group session-box__input  pt-2">
        <h3 className="main-heading text-center pb-3">
          {t("no_devices_found_short")}
        </h3>
        <p className="pb-3 mb-3 text-center">{t("no_devices_found_msg")}</p>
        <div className="d-flex justify-content-center mt-4">
          {/* <button type="button" className="btn btn-primary mt-5" data-toggle="modal" data-target="#DeviceSetup" >Device Setup</button> */}
          <button
            type="button"
            onClick={this.milidevice}
            className="btn btn-primary mt-5"
          >
            {t("deviceSetup")}
          </button>
        </div>
      </div>
    );
  }

  renderAddDevice() {
    return (
      <div className="modal-body">
        <div className="form-group device-box__info d-flex justify-content-center align-items-start  pt-2">
          <div className="device-content">
            <h3 className="main-heading text-center">
              {t("no_devices_found_short")}
            </h3>
            <p className="text-center">
              {t("click_on_the_plus_icon_to_begin_the_paring_process")}
            </p>
          </div>
          <button
            onClick={this.closeHandler}
            data-toggle="modal"
            data-target="#setup"
            className="add-btn "
          >
            <FontAwesomeIcon
              aria-hidden="true"
              icon={faPlus}
              className="social-icon"
            />
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center device-box__choice py-5">
          <hr></hr>
          <h5 className="px-3">or</h5>
          <hr></hr>
        </div>
        <div className="device-box__shop text-center">
          <p className="pb-4">
            Shop and explore all DaVinci products via our online store. We're
            dedicated to bringing the best portable loose-leaf vaporizers to the
            market
          </p>
          <Link
            onClick={this.closeHandler}
            to="/shop"
            className="btn btn-primary mb-4"
          >
            shop
          </Link>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.closeHandler();
  }

  render() {
    let { id, modalStyle = "" } = this.props;
    return (
      <>
        <button
          type="button"
          className="btn btn-primary "
          data-toggle="modal"
          data-target={`#${id}`}
        >
          {t("setup")}
        </button>
        <div
          className="session-box  modal fade"
          id={id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered ${modalStyle}`}
            role="document"
          >
            <div className="modal-content fancy-scroll">
              <div className="modal-body">
                {!this.state.device
                  ? !this.state.error
                    ? this.renderSessionSetup()
                    : this.renderValidation()
                  : !this.state.devicefound
                  ? this.renderNotFound()
                  : this.renderAddDevice()}
              </div>
            </div>
          </div>
        </div>
        <Setup buttonHide={true} name="setup" />
      </>
    );
  }
}

export default SessionSetup;
