const BATTERY_LEVEL = {
  NONE: 0,
  TWENTY_PERCENT: 20,
  FORTY_PERCENT: 40,
  SIXTY_PERCENT: 60,
  EIGHTY_PERCENT: 80,
  FULLY_CHARGED: 100,
  UNKNOWN: 0,
};

export default BATTERY_LEVEL;
