import { t } from "i18next";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { videobg } from "../../assets/images/index";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import { withHeader } from "../../hoc";
import "./Home.scss";
import { useSelector } from "react-redux";
import { getHost } from "../../helpers/hostHelper";

const Home = () => {
  const settings = {
    dots: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    infinite: false,
  };
  const [isOpen, setOpen] = useState(false);

  const { region } = useSelector(state => state.userAuth?.user)

  const host = getHost(region)

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center home-screen">
        <h2 className="primary-heading"> {t("welcome")}</h2>
        {/* <input type="search" placeholder="Quick Search"></input> */}
      </div>
      <div className="home-screen__video d-flex flex-column flex-lg-row pt-5">
        <div className="home-screen__video-img col-12 col-lg-8 p-0 ">
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="pveICwFvhdY"
            onClose={() => setOpen(false)}
          />
          <img src={videobg} onClick={() => setOpen(true)} />
        </div>
        <div className="home-screen__video-slider p-0  mt-5 mt-lg-0 ml-0 ml-lg-3 col-12 col-lg-4">
          <HomeSlider />
        </div>
      </div>
      <div className="home-screen__content d-flex flex-column flex-lg-row pt-5 pb-5">
        <div className="home-screen__content-img col-12 col-lg-8 p-0 d-flex text-center ">
          <div className="home-screen__content-overlay d-flex flex-column justify-content-center">
            <h2 className="primary-heading text-white">
              {t("everything_else")}
            </h2>
            <p>{t("accessories_for_all_devices")}</p>
            <a
              rel="no-follow"
              href={`${host}/vaporizer-accessories/`}
              target="_blank"
              className="btn btn-dark"
            >
              {t("shop_now")}
            </a>
          </div>
        </div>

        <div className="home-screen__content-description col-12 col-lg-4 mt-lg-0 mt-5 p-0 pl-md-3">
          <h2 className="primary-heading">{t("davinci_study")}</h2>
          <p>{t("davinci_study_msg")}</p>
          <button className="btn btn-primary">
            <a
              href={`${host}/davinci-study/`}
              target="_blank"
            >
              {t("learn_more")}
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default withHeader(Home);
