import React, { Component } from 'react';
import './SocialIcon.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faAt, faMicrophone, faGlobe, faGlobeAfrica, faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";

class SocialIcon extends Component {
    state = {}
    render() {
        return (
            <div className="pt-5 text-center">
                <a href="mailto:customerservice@davincivaporizer.com"><FontAwesomeIcon icon={faAt} className="social-icon mr-5" /></a>
                <a href="tel:18003367224"><FontAwesomeIcon icon={faPhoneSquareAlt} className="social-icon mr-5" /></a>
                <a href="https://www.davincivaporizer.com/" target='_blank'><FontAwesomeIcon icon={faGlobe} className="social-icon" /></a>
            </div>
        );
    }
}

export default SocialIcon;

