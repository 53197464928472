import React, { Component } from "react";
import { connect } from "react-redux";
import "./Shop.scss";
import {
  createCustomer,
  getCustomer,
  getCustomerLoginUrl,
} from "../../helpers/bigCommerce";
import { withHeader } from "../../hoc";
import ShopPage from "./ShopPage";
class Shop extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="shop-container">
          <ShopPage />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userAuth.user,
  multipassUrl: state.userAuth.user.multipassUrl,
  multipassUrlExpiry: state.userAuth.user.multipassUrlExpiry
});

export default connect(mapStateToProps, null)(withHeader(Shop));
