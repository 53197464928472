import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap-tabs";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { battery, batteryFull, flash } from "../../assets/images/index";
import History from "../../components/History/History";
import SessionScreen from "../../components/SessionScreen/SessionScreen";
import { BatteryLevel } from "../../constants";
import { withHeader } from "../../hoc";
import "./Session.scss";

class Session extends Component {
  state = {
    batteryLevel: [
      BatteryLevel.FULLY_CHARGED,
      BatteryLevel.EIGHTY_PERCENT,
      BatteryLevel.SIXTY_PERCENT,
      BatteryLevel.FORTY_PERCENT,
      BatteryLevel.TWENTY_PERCENT,
    ],
  };
  render() {
    let { currentDeviceState, currentDevice } = this.props;
    let batteryLevel =
      currentDeviceState.batteryPercentage || BatteryLevel.NONE;
    const { t } = this.props;

    return (
      <div className="session-screen">
        <div className="d-flex  flex-row justify-content-between align-items-center battery-bar-mob-none">
          <h2 className="primary-heading">{`${
            currentDevice.name ? currentDevice.name.slice(0, 20) : ""
          } ${t("dashboard")}`}</h2>
          <div className="d-flex align-items-center">
            <img
              style={{ marginRight: 5.5 }}
              src={flash}
              alt="charge"
              className="img-fuid"
            />
            {this.state.batteryLevel.map((level) => {
              return (
                <span
                  key={level}
                  className={`dot ${level <= batteryLevel ? "" : "charged"}`}
                ></span>
              );
            })}
          </div>
        </div>
        <div className="battery-icon-mob">
          <img
            src={currentDeviceState.isCharging ? battery : batteryFull}
            alt="Battery Icon"
            className="img-fluid"
          />
          {/* 
          change image after full charge
          <img src={batteryFull} alt="Battery Icon" className="img-fluid" /> */}
          <span className="text-white"> {batteryLevel}%</span>
        </div>
        <Tabs className="pt-3">
          <Tab label={t("session")}>
            <SessionScreen />
          </Tab>
          <Tab label={t("history")}>
            <History />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentDeviceState: state.device.currentDeviceState,
  currentDevice: state.device.currentDevice,
});

// export default connect(mapStateToProps, null)(withHeader(Session));
const enhance = compose(
  withTranslation(),
  withHeader,
  connect(mapStateToProps, null)
);
export default enhance(Session);
