import Config from "../Config";

/* 
   add your app constants here
 */

export const REGIONS = [
  {
    label: "United States",
    value: "us",
    host: "https://davincivaporizer.com"
  },
  {
    label: "Canada",
    value: "ca",
    host: "https://www.davincivaporizer.ca"
  },
  {
    label: "Europe",
    value: "eu",
    host: "https://davincivaporizers.eu"
  },
]

export const REGIONS_LABELS = {
  US: "us",
  CA: "ca",
  EU: "eu",
}

export const HOSTS = {
  us: "https://davincivaporizer.com",
  ca: "https://www.davincivaporizer.ca",
  eu: "https://davincivaporizers.eu"
}
export const CURRENT_CUSTOMER_BIGCOMMERCE = `https://moreo.mybigcommerce.com/customer/current.jwt?app_client_id=${Config.env().BIGCOMMERCE_DEV_PUBKEY}`
export const STORE_URL = "https://moreo.mybigcommerce.com/"
