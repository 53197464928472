import React, { Component } from 'react';
import Context from './AppContext';
const width = window.innerWidth;

class ContextWrapper extends Component {
    state = {

        sidebarOpen: width > 900,
    }

    _setState = (name, val) => {
        this.setState({
            [name]: [val]
        })
    }

    toggleSidebar = (isOpen) => {
        this.setState((prev) => ({
            sidebarOpen: isOpen,
        }));
    };

    appToggle = (name) => {
        const stateValue = this.state[name];
        this.setState({
            [name]: !stateValue,
        });
    };

    render() {
        return (
            <Context.Provider
                value={{
                    ...this.state,
                    appToggle: this.appToggle,
                    toggleSidebar: this.toggleSidebar,
                    sidebarOpen: this.state.sidebarOpen,

                }}>

                {this.props.children}
            </Context.Provider>
        );
    }
}

export default ContextWrapper;