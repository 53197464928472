import { combineReducers } from "redux";
import { SIGN_OUT } from "../actions";

import authReducer from "./authReducer";
import deviceReducer from "./deviceReducer";

const appReducer = combineReducers({
  userAuth: authReducer,
  device: deviceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
