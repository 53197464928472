import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.scss";
import "./Internationalization/i18n";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import "./App.css"

Bugsnag.start({
  apiKey: "9cfd7d2f610b7c466d3ab33a3f1d9562",
  plugins: [new BugsnagPluginReact()],
});

Sentry.init({
  dsn: "https://224da56b7d1841b6bdc1e0695cd94bb1@o1058671.ingest.sentry.io/6046486",
  integrations: [new Integrations.BrowserTracing()],
  release: "davinci-web-app@1.0.0",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
