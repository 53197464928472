const English = {
  __BUTTON_TEXT__: "ALL BUTTON TEXT",
  back_to_Login: "Back to Login",
  register: "REGISTER",
  privacy: "Privacy",
  confirm: "CONFIRM",
  login: "LOGIN",
  resetPassword: "Reset Password",
  enter_new_password: "Enter New Password",
  confirm_your_password: "Confirm Your Password",
  passwords_do_not_match: "Passwords do not match",
  forgot_password: "Forgot Password",
  submit: "SUBMIT",
  submitSmall: "Submit",

  setup: "SETUP",
  skip: "SKIP",
  connect: "Connect",
  disconnect: "Disconnect",

  forget: "Forget",
  continue: "continue",
  edit_profile: "Edit Profile",
  resetAll: "Reset All",
  begin: "BEGIN",
  close: "CLOSE",
  deviceSetup: "Device Setup",

  __TEXT__: "ALL TEXT",
  create_Account: "Create Account",
  by_clicking_confirm_you_are_confirming_that_you_are_currently_21_years_of_age_or_older:
    'By clicking "CONFIRM". You are confirming that you are currently 21 years of age or older',
  welcome_to: "Welcome to ",
  daVinci: "DaVinci",
  daVinci_welcome_info_msg:
    "DaVinci enriches your Loose-leaf vaporizing experience through thoughtful design, meticulous engineering and innovative technology",
  daVinci_welcome_functionality_msg:
    "This app enables you to connect to your IQ or IQ2 via your mobile device and customize your vaporizer settings",
  daVinci_welcome_phone_icon_msg:
    "Turn on and control your DaVinci device through any screen",
  daVinci_welcome_leaf_icon_msg:
    "Visit the aromatherapy section to learn more about different herb benefits and ideal temperatures for each one",
  daVinci_welcome_graph_icon_msg:
    " Use Smart Path technology to customize different temperatures of unique variations and flavors",
  daVinci_welcome_shop_icon_msg:
    "Shop all DaVinci products and accessories right from here",
  welcome_msg: "Thank you for using the DaVinci online app",
  pairing_msg:
    "If you have your DaVinci device with you, click below to start the pairing process",
  name_your_device: "Name your Device",
  setup_device: "SETUP DEVICE",
  rename_device: "Rename device",
  edit_name: "Edit Name",
  home: "HOME",
  dashboard: "DASHBOARD",
  setting: "SETTINGS",
  my_account: "MY ACCOUNT",
  my_device: "MY DEVICES",
  smart_paths: "SMART PATHS",
  smart_path: "Smart Path",

  info_support: "INFO & SUPPORT",
  davinci_study: "Davinci's Study",
  shop: "SHOP",
  logout: "LOGOUT",
  welcome: "Welcome",
  everything_else: "EVERYTHING ELSE",
  accessories_for_all_devices: "ACCESSORIES FOR ALL DEVICES",
  upload_profile_picture: "Upload Profile Picture",
  change_password: "Change Password",
  no_device: "No device",
  no_devices_found: "NO DEVICES FOUND",
  no_devices_found_short: "No Devices Found",
  no_devices_found_msg:
    "Pair a DaVinci device to start customizing your experience",
  model: "Model",
  name: "Name",
  address: "Address",
  serial_number: "Serial Number",
  firmware_version: "Firmware Version",
  total_usage: "Total Usage",
  average_session: "Average Session",
  average_temperature: "Average Temperature",
  favorite_path: "Favorite Path",
  total_THC_consumed: "Total THC Consumed",
  total_CBD_consumed: "Total CBD Consumed",
  total_draws: "Total Draws",
  address: "Address",
  disconnected: "Disconnected",
  connected: "Connected",
  check_for_firmware_update: "CHECK FOR FIRMWARE UPDATE",
  end: "End",
  session_completed: "Session Completed",
  time_expired: "Time Expired",
  strain: "Strain",
  start_time: "Start Time",
  end_time: "End Time",
  THC_consumed: "THC Consumed",
  CBD_consumed: "CBD Consumed",
  rate_your_session: "Rate Your Session",
  would_you_like_to_save_your_session: "Would you like to save your session?",
  yes: "yes",
  no: "no",
  Yes: "Yes",
  No: "No",
  star: "Stars",
  stars: "Stars",
  stop_time: "Stop Time",
  avg_temp: "Avg. Temp",
  strainType: "Strain Type",
  ovenContent: "Oven Content",
  rating: "Rating",
  amountLoaded: "Amount Loaded",
  min: " min",
  noSmartPath: " No SmartPath",
  extract: "Extract",
  grainOfRice: "Grain of Rice",
  dryHerb: "Dry Herb",
  full: "Full",
  daVinci_dosage_pod_or_large_spacer_inserted:
    "DaVinci Dosage Pod or large spacer inserted",
  or_small_daVinci_spacer_inserted: "or small DaVinci spacer inserted",
  emptyFields: "Empty Fields",
  your_have_blank_fields_here_this_will_prevent_your_IQ2_from_tracking_and_displaying_your_session_information_accurately:
    "Your have Blank fields here, this will prevent your IQ2 from tracking and displaying your session information accurately",
  would_you_like_to_proceed_anyways: "Would you like to proceed anyways?",
  click_on_the_plus_icon_to_begin_the_paring_process:
    "Click on the plus icon to begin the paring process",

  __PLACEHOLDER__: "ALL PLACEHOLDER",
  email: "Email",
  email_address: "Email Address",
  first_name: "First Name",
  last_name: "Last Name",
  save: "SAVE",
  password: "Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  device_name: "Device Name",
  shop_now: "SHOP NOW",
  davinci_study_msg:
    "Learn about all things vaporization and cannabis related. Want to know how temperature control, the entourage effect or terpenes affect your session?  Dive into the study during your next session and expand your mind. ",
  learn_more: "Learn More",
  home_slider_text: "DAVINCI IQ2 VAPORIZER",
  lost_connection: "Lost Connection",
  daVinci_vaporizer_has_lost_the_connection_to_the_device:
    "DaVinci Vaporizer has lost the connection to the device.",
  device_state: "Device State",
  n_a: "N/A",
  current_temperature: "Current Temperature",
  target_temperature: "Target Temperature",
  select_smart_path: "SELECT SMART PATH",
  session: "Session",
  history: "History",
  time: "Time",
  temperature: "Temperature",
  no_data_available: "No Data Available",
  no_smart_path: "No SmartPath",
  vibration_alert: "VIBRATION ALERTS",
  stealth_mode: "STEALTH MODE",
  temperature_unit: "TEMPERATURE UNIT",
  off: "off",
  on: "on",
  target: "TARGET",
  temp: "TEMP",
  ovenSize: "Oven Size",
  timeRemaining: "Time Remaining",
  target: "TARGET",
  targetCBD: "Target CBD",
  consumedCBD: "Consumed CBD",
  targetTHC: "Target THC",
  enterNewStrain: "Enter New Strain",
  consumedTHC: "Consumed THC",
  sessionSetup: "Session Setup",
  please_enter_your_session_information:
    "Please enter your session Information",
  strainName: "Strain Name",
  THC: "THC%",
  CBD: "CBD%",
  select: "Select",
  newBowl: "New Bowl",
  secondUse: "2nd Use",
  thirdUse: "3rd Use",
  indica: "Indica",
  sativa: "Sativa",
  indicaDominantHybrid: "Indica Dominant Hybrid",
  sativaDominantHybrid: "Sativa Dominant Hybrid",
  "Smart Path 1": "SMART PATH 1",
  "Smart Path 2": "SMART PATH 2",
  "Smart Path 3": "SMART PATH 3",
  "Smart Path 4": "SMART PATH 4",

  __TOAST_MESSAGES__: "TOAST_MESSAGES",
  form_fields_are_required: "Form fields are required",
  login_success: "Login Success",
  enter_login_email_to_recover_your_password:
    "Enter login email to recover your password",
  success: "Success",
  email_is_required: "Email is required",
  device_name_is_required: "Device name is required",
  device_connected: "Device connected",
  device_can_not_connected: "Device can not connected",
  please_enter_a_password: "Please Enter A Password",
  successfully_reset_changes: "Successfully reset changes",
  new_password_and_confirm_password_do_not_match:
    "New password and confirm password do not match",
  password_Changed_Successfully: "Password Changed Successfully",
  profileUpdateSuccessfully: "Profile Update Successfully",
  successfully_saved_changes: "Successfully saved changes",
  successfully_reset_smartpath: "Successfully reset smartpath",
  device_is_not_connected: "Device is not connected",
  device_not_connected: "Device not connected",
  successfullyForgetDevice: "Successfully Forget Device",
  successfullyEditDeviceName: "Successfully Edit Device Name",
  device_disconnected: "Device disconnected",
  signupSuccess: "Signup Success",

  __CONSTANTS__: "CONSTANTS",
  NewBowl: "NewBowl",
  Second_Use: "Second_Use",
  Third_Use: "Third_Use",
  UNKNOWN: "UNKNOWN",
  INDICA: "INDICA",
  SATIVA: "SATIVA",
  INDICA_HYBRID: "INDICA_HYBRID",
  SATIVA_HYBRID: "SATIVA_HYBRID",
  Off: "Off",
  On: "On",
  Charging: "Charging",
  "SmartPath Mode": "SmartPath Mode",
  "Precise Mode": "Precise Mode",
  "Selecting SmartPath": "Selecting SmartPath",
  "Temperature Show SP": "Temperature Show SP",
  "Boost Mode": "Boost Mode",
  "StandBy Mode": "StandBy Mode",
  "Selecting SmartPath": "Selecting SmartPath",
  noDeviceSelected: "No device selected",
  createNew: "Create New",
  region:"Region",
  device: "Device",
  currentFirmware: "Current firmware",
  availableFirmware: "Available firmware",
  check: "Check",
  date: "Date",
};

export default English;
