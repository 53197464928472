import { t } from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { settingImg } from "../../assets/images/index";
import {
  SetTemperatureUnit,
  ToggleStealthMode,
  ToggleVibrationAlert,
} from "../../helpers/device";
import { withHeader } from "../../hoc";
import { store } from "../../store";
import { SET_CURRENT_STATE_DEVICE } from "../../store/actions";
import "./Setting.scss";

class Setting extends Component {
  state = {
    vibration: false,
    shealth: false,
    tempCelciusUnit: false,
  };

  componentDidMount() {
    this.setState({
      vibration: this.props.currentDeviceState.isVibrationEnabled || false,
      shealth: this.props.currentDeviceState.isStealthModelEnabled || false,
      tempCelciusUnit:
        this.props.currentDeviceState.displayModeCelcius || false,
    });
  }

  vibrateToggle = () => {
    let { currentDeviceConnectd } = this.props;
    if (!currentDeviceConnectd) {
      toast.error(t("device_not_connected"));
      return false;
    }
    ToggleVibrationAlert(!this.state.vibration === true ? 0x01 : 0x00);
    store.dispatch({
      type: SET_CURRENT_STATE_DEVICE,
      payload: {
        ...this.props.currentDeviceState,
        isVibrationEnabled: !this.state.vibration,
      },
    });
    this.setState({ vibration: !this.state.vibration });
  };
  shealthToggle = () => {
    let { currentDeviceConnectd } = this.props;
    if (!currentDeviceConnectd) {
      toast.error(t("device_not_connected"));
      return false;
    }
    ToggleStealthMode(!this.state.shealth === true ? 0x04 : 0xff);
    store.dispatch({
      type: SET_CURRENT_STATE_DEVICE,
      payload: {
        ...this.props.currentDeviceState,
        isStealthModelEnabled: !this.state.shealth,
      },
    });
    this.setState({ shealth: !this.state.shealth });
  };
  tempUnitToggle = () => {
    let { currentDeviceConnectd } = this.props;
    if (!currentDeviceConnectd) {
      toast.error(t("device_not_connected"));
      return false;
    }
    SetTemperatureUnit(!this.state.tempCelciusUnit === true ? 0x01 : 0x00);
    store.dispatch({
      type: SET_CURRENT_STATE_DEVICE,
      payload: {
        ...this.props.currentDeviceState,
        displayModeCelcius: !this.state.tempCelciusUnit,
      },
    });
    this.setState({ tempCelciusUnit: !this.state.tempCelciusUnit });
  };
  render() {
    return (
      <>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center setting-screen">
          <h2 className="primary-heading">{t("setting")}</h2>
          {/* <select className="mt-4 mt-md-0">
            <option value="DAVINCI IQ1 VAPORIZER">DAVINCI IQ1 VAPORIZER</option>
            <option value="DAVINCI IQ2 VAPORIZER">DAVINCI IQ2 VAPORIZER</option>
          </select> */}
        </div>
        <div className="row setting-screen__content mt-5 pt-0 pt-md-5 ml-0">
          <div className="col-12 col-md-6 p-0">
            <div className="d-flex justify-content-between align-items-center setting-screen__content-box">
              <p className="m-0">{t("vibration_alert")}</p>
              <div className="notification-toggle d-flex pt-2">
                <h5 className="pr-2">{this.state.vibration ? "On" : "Off"}</h5>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={this.state.vibration}
                    onChange={this.vibrateToggle}
                  ></input>
                  <span
                    className={
                      this.state.vibration
                        ? "slider round"
                        : " slider slider-off round"
                    }
                  ></span>
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center setting-screen__content-box">
              <p className=" m-0">{t("stealth_mode")}</p>
              <div className="notification-toggle d-flex pt-2">
                <h5 className="pr-2">{this.state.shealth ? "On" : "Off"}</h5>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={this.state.shealth}
                    onChange={this.shealthToggle}
                  ></input>
                  <span
                    className={
                      this.state.shealth
                        ? "slider round"
                        : " slider slider-off round"
                    }
                  ></span>
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center setting-screen__content-box">
              <p className="m-0">{t("temperature_unit")}</p>
              <div className="notification-toggle d-flex pt-2">
                <h5 className="pr-2">F˚</h5>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={this.state.tempCelciusUnit}
                    onChange={this.tempUnitToggle}
                  ></input>
                  <span className="slider round"></span>
                </label>
                <h5 className="pl-2">C˚</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center setting-screen__content-img">
            <img src={settingImg} />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentDeviceState: state.device.currentDeviceState,
  currentDeviceConnectd: state.device.currentDeviceConnectd,
});

export default connect(mapStateToProps, null)(withHeader(Setting));
