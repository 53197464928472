import {
  ME_SUCCESS,
  SET_TOKEN,
  LOGIN_SUCCESS,
  EDIT_PROFILE_SUCCESS,
  SET_BIG_COMMERCE_URL
} from "../actions";

const initialState = {
  locale: "en",
  isAuthenticated: false,
  user: {},
  bigCommerceUrl: ""
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          ...action.payload,
          token: action.token,
        },
      };

    case SET_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.token,
        },
      };
    case ME_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case SET_BIG_COMMERCE_URL:
      return {
        ...state,
        user: {
          ...state.user,
          bigCommerceUrl: action.payload,
        }
      }
    default:
      return state;
  }
};

export default authReducer;
