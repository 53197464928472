import { handleFetchError } from "./errorHandler";
import Config from "../Config";

export const fetchUtil = (data) => {
  const {
    url,
    method = "GET",
    body = null,
    token = null,
    image = false,
    abortSignal = null,
    credentials = "same-origin",
    externalApi = false,
  } = data;
  let headers = {};
  if (!image) {
    headers = { "Content-Type": "application/json" };
  }

  if (token) {
    headers = { ...headers, Authorization: token };
  }

  if (!externalApi) {
    return fetch(`${Config.env().API_URL}${url}`, {
      method,
      headers,
      body,
      credentials,
      ...(abortSignal && { signal: abortSignal }),
    }).then(handleFetchError);
  } else {
    return fetch(`${Config.env().API}${url}`, {
      method,
      headers,
      body,
      credentials,
      ...(abortSignal && { signal: abortSignal }),
    }).then(handleFetchError);
  }
};
