import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const BlinkingDotText = ({ text, isRedirected, renderPostRedirection }) => {
    const [dotCount, setDotCount] = useState(1)

    useEffect(() => {
        const interval = setInterval(() => {
            if (dotCount < 3) {
                setDotCount(dotCount + 1)
            } else {
                setDotCount(1)
            }
        }, 500)
        return () => clearInterval(interval)
    })

    return (
        <p style={{ color: "#fff" }}>
            {!isRedirected && text}
            {!isRedirected && new Array(dotCount).fill(".").map((item) => item)}
            {(isRedirected && renderPostRedirection) && renderPostRedirection()}
        </p>
    )
}

export default BlinkingDotText