/* 
   add your app helper functions here
 */
import $ from "jquery";
import * as moment from "moment";
export function openModal(id) {
  $(`#${id}`).modal("show");
}
export function closeModal(id) {
  $(`#${id}`).modal("hide");
}

export function setSessionData(data) {
  let currentMonth = moment().format("YYYY-MM");
  let sessionData = JSON.parse(localStorage.getItem("sessionData") || "{}");
  if (!sessionData[currentMonth]) {
    sessionData[currentMonth] = [];
  }
  const sessionDataKeys = Object.keys(sessionData);
  let sessionObject = {};
  let sessionIndex = -1;
  let lastSessionDate = null;
  sessionDataKeys.map((key) => {
    sessionData[key].map((item, index) => {
      if (item.strainName === data.strainName && item.strainName.length) {
        sessionObject = item;
        sessionIndex = index;
        lastSessionDate = key;
      }
    });
  });

  if (
    sessionIndex !== -1 &&
    Object.keys(sessionObject).length &&
    lastSessionDate
  ) {
    sessionData[lastSessionDate][sessionIndex] = { ...sessionObject, ...data };
  } else {
    sessionData[currentMonth].push(data);
  }
  localStorage.setItem("sessionData", JSON.stringify(sessionData));
  return true;
}

export function getSessionData() {
  let sessionData = JSON.parse(localStorage.getItem("sessionData") || "{}");
  return sessionData;
}

export function getKeyByValue(object, value) {
  for (var prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (object[prop] === value) return prop;
    }
  }
}

export function ConvertToHexa(number) {
  return "0x" + number.toString(16);
}

export function CToF(celsius) {
  var cTemp = celsius;
  var cToFahr = (cTemp * 9) / 5 + 32;
  return cToFahr.toFixed(0);
}

export function FToC(fahrenheit) {
  var fTemp = fahrenheit;
  var fToCel = ((fTemp - 32) * 5) / 9;
  return fToCel.toFixed(0);
}

export function ConvertToTwoDecimalPlaces(number) {
  return Math.round(number * 100) / 100;
}

export function RoundOff(number) {
  return Math.round(number);
}

export function ConvertToOneDecimalPlaces(number) {
  return Math.round(number * 10) / 10;
}

export function getTimeFormatString(seconds) {
  seconds = parseInt(seconds);
  return moment().startOf("day").set("seconds", seconds).format("mm:ss");
}

export function getPlaceholderGraphData(isIQ2 = true) {
  const data = [];
  for (let i = isIQ2 ? 8.0 : 11.0; i >= 0.0; i--) {
    data.push({
      x: i,
      y: 0,
    });
  }
  return data;
}
export function getXAxisCategories(isIQ2 = true) {
  const data = [];
  for (let i = isIQ2 ? 8 : 11; i < 0; i--) {
    data.unshift(i.toString());
  }

  return data;
}

export function getLocale() {
  return localStorage.getItem("locale") || "en";
}

export const truncateArray = (array, count = 8) => {
  if (!Array.isArray(array)) {
    throw new Error("Input is not an array.");
  }

  if (array.length <= count) {
    return array.slice(); // No need to modify the array
  }

  return array.slice(Math.abs(count) * -1);
}