import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import './SetupSlider2.scss'
import { setup1, setup2, setup3, setup4, setupgraph } from '../../assets/images'


class SetupSlider2 extends Component {
    state = {}
    render() {
        const settings = {
            dots: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            infinite: false,
        };
        return (
            <div className="setup-slider pt-0 pt-md-5">
                <Slider {...settings}>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <p className="pb-3 pt-2 pt-md-5"><span>1. </span>Click the control button five times and the IQ2 will start in Smart Path mode. Click once to switch heating modes</p>
                            <p> <span>2. </span>Use up/down buttons to change Smart Paths or temperature, depending on your desired mode</p>
                        </div>
                        <div className="setup-slider__image">
                            <img src={setup1} />
                        </div>
                    </div>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <img src={setupgraph} />
                            <p className="pb-3 pt-2 pt-md-5"><span>3. </span>Click the control button five times and the IQ2 will start in Smart Path mode. Click once to switch heating modes</p>

                        </div>
                        <div className="setup-slider__image">
                            <img src={setup2} />
                        </div>
                    </div>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <p className="pb-3 pt-2 pt-md-5"><span>4. </span>Precision mode heats up your material to a precise temperature. Click the control button to switch modes</p>
                            <p> <span>5. </span>Adjust the temperature by pressing the up or down arrow button. The IQ2 will blink once and vibrate</p>
                        </div>
                        <div className="setup-slider__image">
                            <img src={setup3} />
                        </div>
                    </div>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <p className="pb-3 pt-2 pt-md-5"><span>6. </span>Adjust Your Air Dial Turn the dial on bottom lid counterclockwise to increase air flow and to decrease draw resistance until your desired level is reached. To increase vapor density, decrease air flow and increase draw resistance, turn the air dial clockwise</p>

                        </div>
                        <div className="setup-slider__image">
                            <img src={setup4} />
                        </div>
                    </div>


                </Slider>
            </div>
        );
    }
}

export default SetupSlider2;