import { t } from "i18next";
import React, { Component } from "react";
import { SessionStatus } from "../../constants";
import "./SessionStats.scss";

class SessionStats extends Component {
  state = {};

  render() {
    let {
      strainName,
      // strainType,
      // targetTHC,
      // THC,
      startTime,
      endTime,
      // targetCBD,
      // CBD,
      status,
      // rating,
      totalTHCConsumed,
      totalCBDConsumed,
    } = this.props.session;
    let { onHandleRatingChange, id, currentSessionState } = this.props;
    return (
      <>
        {status === SessionStatus.Active && (
          <button
            type="button"
            className="btn btn-dark ml-3"
            onClick={() => {
              this.props.onHandleSessionTermination(
                currentSessionState.sessionInProgress
              );
            }}
          >
            {t("end")}
          </button>
        )}
        <div
          className="stats-box modal fade"
          id={id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="sessionResultLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content fancy-scroll">
              <div className="modal-body">
                <div className="form-group stats-box__title text-center pt-1">
                  <h3 className="primary-heading">{t("session_completed")}</h3>
                  <p className="pb-2">{t("time_expired")}</p>
                </div>
                <div className="d-flex flex-column stats-box__info">
                  <div className="d-flex justify-content-between">
                    <p>{t("strain")}</p>

                    <h6>{strainName}</h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>{t("start_time")}</p>

                    <h6>{startTime}</h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>{t("end_time")}</p>

                    <h6>{endTime}</h6>
                  </div>
                  {/* <div className="d-flex justify-content-between">
                    <p>Temprature</p>
                    <h6>300˚</h6>
                  </div> */}
                  <div className="d-flex justify-content-between">
                    <p>{t("THC_consumed")}</p>

                    <h6>{totalTHCConsumed} mg</h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>{t("CBD_consumed")}</p>

                    <h6>{totalCBDConsumed} mg</h6>
                  </div>
                </div>
                <div className="stats-box__progress text-center">
                  <h5 className="text-white">{t("rate_your_session")}</h5>
                  <div className="stats-box__progress-stats pt-3 pb-4 d-flex justify-content-center">
                    <div className="stats-star-rating">
                      <input
                        type="radio"
                        id="star5"
                        name="rate"
                        value="5"
                        onClick={() => onHandleRatingChange(5)}
                      />
                      <label htmlFor="star5" title="text">
                        5 {t("stars")}
                      </label>
                      <input
                        type="radio"
                        id="star4"
                        name="rate"
                        value="4"
                        onClick={() => onHandleRatingChange(4)}
                      />
                      <label htmlFor="star4" title="text">
                        4 {t("stars")}
                      </label>
                      <input
                        type="radio"
                        id="star3"
                        name="rate"
                        value="3"
                        onClick={() => onHandleRatingChange(3)}
                      />
                      <label htmlFor="star3" title="text">
                        3 {t("stars")}
                      </label>
                      <input
                        type="radio"
                        id="star2"
                        name="rate"
                        value="2"
                        onClick={() => onHandleRatingChange(2)}
                      />
                      <label htmlFor="star2" title="text">
                        2 {t("stars")}
                      </label>
                      <input
                        type="radio"
                        id="star1"
                        name="rate"
                        value="1"
                        onClick={() => onHandleRatingChange(1)}
                      />
                      <label htmlFor="star1" title="text">
                        1 {t("stars")}
                      </label>
                    </div>
                    {/* <progress
                      className="w-100"
                      id="file"
                      value={rating}
                      max="100"
                    >
                      {" "}
                    </progress>
                    <div className="d-flex justify-content-between">
                      <button
                        className="rating-progress-bar"
                        onClick={() => onHandleRatingChange(0)}
                      >
                        Bad
                      </button>
                      <button
                        className="rating-progress-bar"
                        onClick={() => onHandleRatingChange(30)}
                      >
                        Good
                      </button>
                      <button
                        className="rating-progress-bar"
                        onClick={() => onHandleRatingChange(60)}
                      >
                        Great
                      </button>
                      <button
                        className="rating-progress-bar"
                        onClick={() => onHandleRatingChange(100)}
                      >
                        Amazing
                      </button>
                    </div> */}
                  </div>
                  <h5 className="text-white pt-3">
                    {t("would_you_like_to_save_your_session")}
                  </h5>
                  <div className="d-flex justify-content-center mt-4 mb-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-dark"
                      onClick={this.props.onSaveSession}
                    >
                      {t("yes")}
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary ml-3"
                      onClick={this.props.onCancelSession}
                    >
                      {t("no")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SessionStats;
