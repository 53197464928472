import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import './SetupSlider.scss'
import { screen1, screen2, screen3, setupgraph } from '../../assets/images'
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { withTranslation } from 'react-i18next';


class SetupSlider extends Component {
    state = {
        activeSlide: 0,
    }
    render() {
        const settings = {
            dots: true,

            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            infinite: false,
            afterChange: current => this.setState({ activeSlide: current })
        };

        let { t } = this.props

        return (
            <div className="setup-slider pt-0 pt-md-5">
                <Slider {...settings}>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <p className="pb-0 pb-md-3 pt-1 pt-md-5"><span>1. </span>Click the control button five times and the IQ2 will start in Smart Path mode. Click once to switch heating modes</p>
                            <p> <span>2. </span>Use up/down buttons to change Smart Paths or temperature, depending on your desired mode</p>
                        </div>
                        <div className="setup-slider__content-img">
                            <img src={screen1} />
                        </div>
                    </div>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <img src={setupgraph} />
                            <p className="pb-0 pb-md-3 pt-2 pt-md-5"><span>3. </span>Click the control button five times and the IQ2 will start in Smart Path mode. Click once to switch heating modes</p>

                        </div>
                        <div className="setup-slider__content-img">
                            <img src={screen2} />
                        </div>
                    </div>
                    <div className="setup-slider__1 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center">
                        <div className="setup-slider__content">
                            <p className="pb-3 pt-2 pt-md-5"><span>4. </span>Precision mode heats up your material to a precise temperature. Click the control button to switch modes</p>
                            <p> <span>5. </span>Adjust the temperature by pressing the up or down arrow button. The IQ2 will blink once and vibrate</p>
                        </div>
                        <div className="setup-slider__content-img">
                            <img src={screen3} />
                        </div>
                    </div>
                </Slider>
                <button className="btn btn-primary mt-md-2 mt-1 " style={{ color: "#fff" }}>
                    <Link className="btn-link" to="/home">{t("continue")}</Link>
                </button>
            </div>
        );
    }
}

export default (withTranslation()(SetupSlider))